/** @format */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonCus, InputCus, TextCus } from 'components';
import { fontSize, mainColor } from 'theme';
import { Box, CircularProgress } from '@mui/material';
import styles from './styles';
import { BoxLayout } from 'components/Layouts';
import { RecentCreations } from 'components/RecentCreations';
import { TabHome } from 'pages/main/TabHome';
import { EnumInputVariant, EnumPrompt, KEY_CONTEXT } from 'utils';
import { useGenerate } from 'hooks/useGenerate';
import { ETaskTracking, TParams } from 'types';
import RecentEmty from 'components/RecentCreations/RecentEmty';
import BoxUpload from './BoxUpload';
import { IGenerateType, IImages2VideoParams } from 'types/generate';
import { Icons } from 'assets';
import { useKey, useLayout, useProfile } from 'hooks';
import ModalProcessFail from 'components/ModalGlobal/ModalProcessFail';
import ModalNotBalance from 'components/ModalGlobal/ModalNotBalance';
import ModalLoading from 'components/ModalGlobal/ModalLoading';
import BoxPromt from './BoxPromt';
import RecentSkeleton from 'components/RecentCreations/RecentSkeleton';

const dataTab = [{ title: 'Recent', type: IGenerateType.IMAGE2VIDEO }, { title: 'History', type: "ALL" }]

const formInterface = { prompt: "" }

function ImageToVideo() {

  /*STATE*/
  const [formData, setFormData] = useState(formInterface);
  const [errors, setErrors] = useState({});
  const [errorImg, setErrorImg] = useState(false);
  const [validateImage, setValidateImage] = useState('Start frame');
  const [isTitle, setIsTitle] = useState('Recent')
  const [typeParam, setTypeParam] = useState<string>(IGenerateType.IMAGE2VIDEO)
  const [imageStart, setImageStart] = useState<any>("")
  const [imageEnd, setImageEnd] = useState<any>("")
  const [valueInput, setValueInput] = useState<any>("")
  const [paramsGenerate, setParamsGenerate] = useState<TParams>({
    page: 1,
    limit: 10,
    type: typeParam,
  });
  const [openModalFail, setOpenModalFail] = useState(false);
  const [openModalNotCredit, setOpenModalNotCredit] = useState(false);
  const [isLoadingGlobal, setisLoadingGlobal] = useState(false);
  const [openBrowse, setOpenBrowse] = useState(false);

  /*HOOKS*/
  const { useGetRecent, images2VideoMutation, taskTrackingMutation, useGetPrompt } = useGenerate();
  const { recents, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useGetRecent(paramsGenerate)
  const { prompts } = useGetPrompt({
    page: 1,
    limit: 6,
  })
  const { idTrackingImage, setIdTrackingImage, loadingImage2Video, setLoadingImage2Video,
    promptInputGlobal, setPromtInputGlobal } = useLayout();
  const intervalRef = useRef<any>(null)
  const { getProfile } = useProfile();
  const { setKey, getKey, removeKey } = useKey();

  /*CHECK VALUE PROMP GLOBAL*/
  useEffect(() => {
    if (promptInputGlobal) {
      if (promptInputGlobal === EnumPrompt.DEFAULT_PROMPT) {
        setOpenBrowse(true)
        setValueInput('')
        setFormData({ prompt: '' })
        return
      } else {
        setOpenBrowse(true)
        setValueInput(promptInputGlobal)
        setFormData({ prompt: promptInputGlobal })
        return
      }
    }
  }, [promptInputGlobal])

  /*CHECK ID TRACKING*/
  const idTracking = getKey(KEY_CONTEXT.ID_TRACKING_IMAGE)
  useEffect(() => {
    if (idTracking && recents[0]?.trackId !== idTracking) {
      setIdTrackingImage(idTracking)
      setLoadingImage2Video(true)
    }
  }, []);

  useEffect(() => {
    setParamsGenerate({
      ...paramsGenerate,
      type: typeParam,
    });
  }, [typeParam]);

  /*FUNTION TAB*/
  const handleClickTab = (title: string, type: string) => {
    setIsTitle(title)
    setTypeParam(type)
  }
  /*FUNTION Click Prompt*/
  const handleClickPrompt = (prompt: string) => {
    setValueInput(prompt)
    setFormData({ prompt: prompt })
    setTimeout(() => {
      setOpenBrowse(false)
      setPromtInputGlobal('')
    }, 3000)
  }
  /*FUNTION HANDLE SUBMIT*/
  const handleSubmit = async () => {
    try {
      if (!imageStart) {
        setValidateImage('*Please select an image')
        setErrorImg(true)
        return
      } else {
        setValidateImage('Drag an image here or')
        setErrorImg(false)
      }
      // setFormData({ prompt: "camera push in" })
      // await inputToolSchema.validate(formData, { abortEarly: false })

      const formDataImage2Video = new FormData();
      formDataImage2Video.append('prompt', formData.prompt ? formData?.prompt : "camera push in");
      formDataImage2Video.append('files', imageStart as File)
      formDataImage2Video.append('files', imageEnd as File)
      setisLoadingGlobal(true)
      images2VideoMutation.mutate(formDataImage2Video as IImages2VideoParams, {
        onSuccess: (data: any) => {
          if (data?.statusCode === 400) {
            if (data?.message === "BALANCE_NOT_ENOUGH") {
              setOpenModalNotCredit(true);
            } else {
              setOpenModalFail(true);
            }
          } else {
            setIdTrackingImage(data?.data?.id)
            setKey(KEY_CONTEXT.ID_TRACKING_IMAGE, data?.data?.id)
            setLoadingImage2Video(true)
            setisLoadingGlobal(false)
            getProfile();
            setFormData(formInterface)
            setValueInput("")
          }
        }
      })
      handleEvenScroll()
      return

    } catch (error: any) {
      const validationErrors = {};
      if (error?.inner) {
        error?.inner.forEach((err: any) => {
          validationErrors[err?.path] = err.message;
        });
      }
      setErrors(validationErrors);
    }
  }
  /*HANDLE TRACKING*/
  useEffect(() => {
    if (intervalRef.current) {
      return () => {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
    if (idTrackingImage && loadingImage2Video) {
      intervalRef.current = setInterval(() => {
        taskTrackingMutation.mutate(idTrackingImage, {
          onSuccess: (data) => {
            const state = data?.data?.state
            if (state === ETaskTracking.COMPLETED || state === ETaskTracking.FAILED) {
              console.log('Task==================clear',)
              setLoadingImage2Video(false)
              setIdTrackingImage('')
              removeKey(KEY_CONTEXT.ID_TRACKING_IMAGE)
              refetch()
              return () => {
                clearInterval(intervalRef.current)
                intervalRef.current = null
              }
            }
          }
        })
      }, 10000)
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null
      }
    }
  }, [idTrackingImage, loadingImage2Video])

  /*HANDLE Event Scroll*/
  const handleEvenScroll = () => {
    setTimeout(() => {
      const targetElement = document.getElementById("tab-id");
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 1000)
  };

  const renderPrompt = useCallback(() => {
    return prompts.map((item) => (
      <BoxPromt item={item} handleClickPrompt={handleClickPrompt} key={item.trackId} />
    ))
  }, [prompts])

  return (
    <>
      <Box
        sx={styles.boxContainer}
        marginTop={'120px'}
        flexDirection={'column'}
        justifyContent={'center'}
        width={'100%'}
        alignItems={'center'}
      >
        <Box
          marginY={'50px'}
          p={'25px'}
          // width={'70%'}
          height={'auto'}
          display='flex'
          flexDirection='row'
          alignItems={'center'}
          component={'div'}
          bgcolor={mainColor.primary}
          sx={{
            border: `2px dashed ${mainColor.borderColor3}`,
            borderRadius: '12px',
            boxShadow: ` 0 4px 20px rgba(135,207,254,0.7)`,
          }}
        >
          <BoxUpload
            openBrowse={openBrowse}
            onFileSelect={(file: File) => {
              setImageStart(file);
            }}
            onClear={() => setImageStart("")}
            isLoading={loadingImage2Video}
            title={validateImage}
            error={errorImg}
            subTitle={`(Obligatory)`}
          />

          <TextCus
            color={mainColor.textMain}
            fontSize={`${fontSize.xl}`}
            px={'45px'}
          >
            +
          </TextCus>
          <BoxUpload
            onFileSelect={(file: File) => {
              setImageEnd(file);
            }}
            onClear={() => setImageEnd("")}
            isLoading={loadingImage2Video}
            title={`End frame`}
            subTitle={`(Optional)`}
          />
        </Box>

        <BoxLayout>
          <Box
            display={'flex'}
            justifyContent={'end'}
            alignItems={'end'}
            component='form'
          // onSubmit={}
          >
            <InputCus
              // type="input"
              name="prompt"
              inputVariant={EnumInputVariant.textarea}
              placeholder="Image to video"
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
              value={valueInput}
              setValueInput={setValueInput}
            />
            <ButtonCus
              disabled={loadingImage2Video}
              onClick={handleSubmit}
              sx={{
                padding: "0px !important",
                marginBottom: `5px`,
                borderRadius: '12px',
                width: '157px',
                height: '44px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: "linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)"
              }}

            >
              {Icons.StarBtn()}
              <TextCus
                color={mainColor.textMain}
                fontSize={`${fontSize.sm}`}
                paddingX={1}
              >
                Generate
              </TextCus>
            </ButtonCus>
          </Box>
        </BoxLayout>

        <Box
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'center'}
          width={'100%'}
        >
          {renderPrompt()}
        </Box>

        <Box
          mt={'70px'}
          display={'flex'}
          width={'100%'}
          minHeight={'510px'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          |<Box
            width={'100%'}
            display={'flex'}
            justifyContent={'start'}
          >
            <TextCus
              color={mainColor.textMain}
              fontSize={`${fontSize.xl}`}
              ml={'15px'}
              textAlign={'left'}
            >
              Your Videos
            </TextCus>
          </Box>

          <Box
            display={'flex'}
            width={'100%'}
            mt={'20px'}
          >
            {dataTab.map((item, index) => (
              <Box id="tab-id" key={index} onClick={() => { handleClickTab(item?.title, item?.type) }}>
                <TabHome title={item?.title} isTitle={isTitle} />
              </Box>
            ))}
          </Box>
          {isLoading
            ? (<RecentSkeleton />)
            : ((recents.length > 0 || loadingImage2Video)
              ? (<RecentCreations refetch={refetch} dataRecents={recents} isLoading={loadingImage2Video} />)
              : (<RecentEmty />))
          }

          {hasNextPage && (
            <Box
              marginBottom={'70px'}
              component={"div"}
              onClick={() => fetchNextPage()}
              sx={{
                cursor: 'pointer',
                borderRadius: '12px',
                '&:hover': { backgroundColor: `${mainColor.bgButtonHover}` }
              }}
              bgcolor={mainColor.hoverIcon}
              p={'8px'}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"15px"}
            >
              <TextCus color={mainColor.textMain} fontSize={fontSize.s}>{"See more"}</TextCus>
              {isFetchingNextPage ? (
                <CircularProgress size={15} />
              ) : (
                <Icons.ArrowSelect />
              )}
            </Box>
          )}

          {openModalFail && (
            <ModalProcessFail
              open={openModalFail}
              onClose={() => setOpenModalFail(false)}
            />
          )}
          {openModalNotCredit && (
            <ModalNotBalance
              open={openModalNotCredit}
              onClose={() => setOpenModalNotCredit(false)}
            />
          )}
          {isLoadingGlobal && <ModalLoading open={isLoadingGlobal} />}
        </Box>


      </Box >
    </>
  );
}

export default ImageToVideo;
