/** @format */

export enum ENotification {
  MAINTAIN = 'MAINTAIN',
}

export interface IMaintain {
  isMaintain: boolean;
  startTime: string;
  endTime: string;
}
