/** @format */

import React, { useEffect, useState } from 'react';

import { iconBack, iconSelectDown, iconSelectUp, iconText2Video, iconImage2Video, iconChangingBG, Icons } from 'assets';
import { BoxCredit, TextCus } from 'components';
// import { useUser, } from 'hooks';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate } from 'react-router-dom';
import { fontSize, mainColor } from 'theme/default';
import { Box, Grid } from '@mui/material';
import ModalMenuSelect from 'components/ModalGlobal/ModalMenuSelect';
import { APP_ROOT } from 'utils/routes';


const dataSelect = [
  { icon: iconText2Video, label: 'Text to Video', link: '/text-to-video' },
  { icon: iconImage2Video, label: 'Image to Video', link: '/image-to-video' },
  { icon: iconChangingBG, label: 'Background Change', link: '/changing-background' },
]

export const HeaderSub: React.FC = () => {
  // const { user } = useUser();
  const navigate = useNavigate();
  // const location = useLocation();
  // const [active, setActive] = useState('/');
  const [value, setValue] = useState(dataSelect[0]);
  const [isUpDown, setIsUpDown] = useState(false);

  useEffect(() => {
    dataSelect.find((item) => {
      if (location.pathname === item.link)
        return (setValue(item))
    })
    // setActive(location.pathname)
  }, [location.pathname])


  // =========== OPEN MODAL MENU =============
  const [openMenuSelect, setOpenMenuSelect] = React.useState(false);

  const handleClickSelect = () => {
    setIsUpDown(!isUpDown)
    setOpenMenuSelect(!openMenuSelect)
  }


  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      component={'nav'}
    // sx={{ backgroundColor: "#1b1b1bb0", borderRadius: 3 }}
    >
      <Grid container sx={{ height: { xs: '60px', md: '64px' } }} width={'95%'}>
        <Grid
          item
          xs={6}
          md={5}
          xl={4}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >

          <Box
            display={'flex'}
            alignItems={'center'}
            width={'331px'}
            height={'48px'}
          >

            <Link to={'/'}>
              <Box

                sx={{
                  borderRadius: '12px',
                  width: '64px ',
                  height: '48px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: "#1b1b1bb0",
                  '&:hover': {
                    background: "linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)"
                  },
                  border: "0px solid "
                }}
              >
                <LazyLoadImage src={iconBack} alt='icon' />
              </Box>
            </Link>

            <Box
              onClick={handleClickSelect}
              ml={'15px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              width={'251px'}
              height={'48px'}
              borderRadius={'12px'}
              sx={{
                background: "linear-gradient(180deg, rgba(135,207,254,1) 0%, rgba(0,151,245,1) 100%, rgba(0,0,0,0) 1000%)"
              }}
            >
              <Box
                sx={{ filter: `grayscale(100%) brightness(0)` }}
              >
                <LazyLoadImage src={value?.icon} alt='icon' />
              </Box>
              <TextCus
                color={mainColor.blackDark}
                fontSize={`${fontSize.sm}`}
                fontWeight={600}
                paddingX={'10px'}
              >
                {value?.label}
              </TextCus>
              <Box >
                {!isUpDown
                  ? (<LazyLoadImage src={iconSelectDown} alt='icon' />)
                  : (<LazyLoadImage src={iconSelectUp} alt='icon' />)
                }
              </Box>
            </Box>

          </Box>

        </Grid>
        <Grid
          item
          xs={6}
          md={7}
          xl={8}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-end'}
        >
          <Box
            component={'button'}
            display={'flex'}
            alignItems={'center'}
            bgcolor={'transparent'}
            border={'none'}
            sx={{ cursor: 'pointer', gap: { xs: '10px', md: '20px' } }}
          // onClick={handleClick}
          >

            <BoxCredit />


            <Box
              sx={{ cursor: 'pointer', border: `1px solid ${mainColor.borderColor2}`, borderRadius: '12px' }}
              bgcolor={"#333333"}
              display={'flex'}
              p={'8px'}
              onClick={() => navigate(APP_ROOT.PLAN)}
            >
              {Icons.UpgradePlan()}
              <TextCus
                color={mainColor.textMain}
                fontSize={`${fontSize.sm}`}
                paddingX={'15px'}
              >
                Upgrade Plan
              </TextCus>
            </Box>
          </Box>

        </Grid>
      </Grid>
      <ModalMenuSelect open={openMenuSelect} onClose={() => setOpenMenuSelect(!openMenuSelect)} />
    </Box >
  );
};
