/** @format */

import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'utils';
import { notificationService } from 'services/notificationService';

export const useNotification = () => {
  const useMaintain = () => {
    const { data, isLoading, isError, error } = useQuery({
      queryKey: [QueryKeys.MAINTAIN],
      queryFn: () => notificationService.getMaintain(),
    });

    return {
      maintain: data,
      isLoading,
      isError,
      error,
    };
  };

  return { useMaintain };
};
