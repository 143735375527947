import React, { forwardRef } from 'react'
import { mainColor } from 'theme'
import { Icons } from 'assets';
import { IInputTools } from 'types';

const InputTool = forwardRef<HTMLInputElement, IInputTools>(function InputTool({
  setFormData, formData, name, type, placeholder, setErrors, errors, value, setValueInput }, ref) {

  const handleInputChange = (e: any) => {
    setValueInput(null)
    e.preventDefault();
    // const { value } = e.target;
    setFormData({ ...formData, [name]: value ?? e.target.value });
    setErrors({ ...errors, [name]: "" });
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginLeft: '10px',
      marginRight: '25px',
    }}>

      <div
        style={{
          position: 'relative',
          width: '100%',
          marginTop: '15px',
        }}
      >
        <input
          ref={ref}
          onChange={handleInputChange}
          value={value ? value : formData?.[name] || ""}
          type={type}
          placeholder={placeholder}
          autoComplete="off"
          style={{
            border: `2px solid ${mainColor.borderColor1}`,
            borderRadius: '12px',
            width: '100%',
            height: '44px',
            backgroundColor: `transparent`,
            padding: `10px 10px 10px 40px`,
            outline: 'none',
            color: mainColor.textMain,
            fontSize: '16px',
            fontWeight: '500',
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '10px',
            transform: 'translateY(-50%)',
            height: '24px',
            width: '24px',

          }}
        >
          {Icons.Camera()}
        </div>

      </div>
      {errors
        ? (<div
          style={{
            color: '#e03636',
            fontSize: '14px',
            marginTop: '5px',
            marginLeft: '10px',
            marginBottom: '10px',
            fontWeight: '600',
          }}
        >{errors[name]}</div>) : ("")}
    </div>
  )
})

export default InputTool