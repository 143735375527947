/** @format */

import React, { useCallback, useState } from 'react';
import { logo, iconMenu, iconClose, Icons } from 'assets';
import { AvatarCus, BoxCredit, ButtonCus, ModalProfile, ModalUpdatePassword } from 'components';
import { TextCus } from 'components';
import { useAuthentication, useUser, useWindowDimensions } from 'hooks';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate } from 'react-router-dom';
import { fontSize, mainColor } from 'theme/default';
import { KeyboardArrowDown, MenuOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Grid, IconButton, Popover } from '@mui/material';
import ModalMenuRight from 'components/ModalGlobal/ModalMenuRight';
import { APP_ROOT, PUBLIC_URL } from 'utils/routes';
import ItemMenu from './ItemMenu';

export const Header: React.FC = () => {
  const { user, isLogged } = useUser();
  const navigate = useNavigate();

  const { width } = useWindowDimensions();
  const [openModalProfile, setOpenModalProfile] = useState(false);
  const [openUpdatePass, setOpenUpdatePass] = useState(false);

  const handleNavMenu = useCallback((link: string) => {
    navigate(link);
  }, []);

  const { logoutMutation } = useAuthentication();

  // =========== OPEN MODAL INFO =============
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // =========== OPEN MODAL MENU =============
  const [openMenu, setOpenMenu] = React.useState<null | HTMLElement>(null);
  const isOpenMenu = Boolean(openMenu);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenMenu(event.currentTarget);
  };

  // =========== OPEN MENU SIDEBAR=============
  const [openMenuAppBar, setOpenMenuAppBar] = React.useState(false);
  const handelClickMenuAppBar = () => {
    setOpenMenuAppBar(!openMenuAppBar);
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      borderBottom={`1px solid ${mainColor.borderColor}`}
      component={'nav'}
      sx={{ backgroundColor: '#1b1b1bb0', borderRadius: 3 }}
    >
      <Grid container sx={{ height: { xs: '60px', md: '64px' } }} width={'98%'}>
        {/* Menu left */}
        <Grid
          item
          xs={6}
          md={5}
          xl={4}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          {width > 600 ? (
            <Box display={'flex'} alignItems={'center'}>
              <IconButton onClick={handelClickMenuAppBar}>
                <LazyLoadImage src={openMenuAppBar ? iconClose : iconMenu} alt='icon' />
              </IconButton>
              <Link to={'/'}>
                <LazyLoadImage src={logo} alt='logo' style={{ paddingLeft: '20px' }} />
              </Link>
            </Box>
          ) : (
            <Box padding={0}>
              <Button
                sx={{ cursor: 'pointer', minWidth: '50px', padding: 0 }}
                id='menu-button'
                aria-controls={isOpenMenu ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={isOpenMenu ? 'true' : undefined}
                onClick={handleClickMenu}
              >
                <MenuOutlined />
              </Button>
            </Box>
          )}
        </Grid>

        {/* Menu right */}
        <Grid
          item
          xs={6}
          md={7}
          xl={8}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-end'}
          position={'relative'}
        >
          {isLogged ? (
            <Box
              component={'button'}
              display={'flex'}
              alignItems={'center'}
              bgcolor={'transparent'}
              border={'none'}
              sx={{ cursor: 'pointer', gap: { xs: '10px', md: '20px' } }}
              aria-describedby={id}
            >
              <BoxCredit />
              <Box
                sx={{
                  cursor: 'pointer',
                  border: `1px solid ${mainColor.borderColor2}`,
                  borderRadius: '12px',
                  ':hover': {
                    opacity: 0.7,
                    transition: 'all 0.25s ease-in-out',
                  },
                }}
                bgcolor={'#333333'}
                display={'flex'}
                alignItems={'center'}
                gap={'10px'}
                justifyContent={'center'}
                height={'40px'}
                component={'div'}
                width={'162px'}
                onClick={() => navigate(APP_ROOT.PLAN)}
              >
                {Icons.UpgradePlan()}
                <TextCus color={mainColor.textMain} fontSize={`${fontSize.sm}`}>
                  Upgrade Plan
                </TextCus>
              </Box>

              <Box
                border={'none'}
                display={'flex'}
                alignItems={'center'}
                gap={'5px'}
                sx={{ cursor: 'pointer' }}
                onClick={handleClick}
                bgcolor={'transparent'}
                component={'button'}
              >
                <AvatarCus />
                <TextCus ml={'10px'} fontSize={fontSize.sm} color={mainColor.textMain}>
                  {user?.userName ?? user?.email}
                </TextCus>
                <KeyboardArrowDown style={{ color: mainColor.textGray }} />
              </Box>
            </Box>
          ) : (
            <Box display={'flex'} alignItems={'center'} gap={'15px'}>
              <ButtonCus
                sx={{ padding: '0px !important' }}
                onClick={() => handleNavMenu(PUBLIC_URL.LOGIN)}
              >
                <Box
                  bgcolor={'#3B364C'}
                  width={'97%'}
                  borderRadius={'10px'}
                  height={'90%'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <TextCus color={mainColor.textMain} fontSize={fontSize.xs} fontWeight={'600'}>
                    Login
                  </TextCus>
                </Box>
              </ButtonCus>

              <ButtonCus onClick={() => handleNavMenu(PUBLIC_URL.SIGNUP)} title='Register' />
            </Box>
          )}

          {/* Modal popup profile user */}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            sx={{
              top: '15px',
              left: '-10px',
              overflow: 'hidden',
              borderRadius: '20px',
              backgroundColor: 'unset !important',
            }}
          >
            <Box
              width={'242px'}
              mx={'15px'}
              paddingY={'20px'}
              display={'flex'}
              overflow={'hidden'}
              bgcolor={mainColor.primary}
              flexDirection={'column'}
              gap={'10px'}
              borderRadius={'20px !important'}
            >
              <Box alignItems={'center'} gap={'10px'} display={'flex'}>
                <AvatarCus />
                <Box display={'flex'} flexDirection={'column'}>
                  <TextCus fontSize={fontSize.sm} color={mainColor.textMain} fontWeight={'600'}>
                    {user?.userName}
                  </TextCus>
                  <TextCus
                    fontSize={fontSize.xs}
                    color={mainColor.textMain}
                    width={'70%'}
                    fontWeight={'400'}
                    className='res_text'
                  >
                    {user?.email}
                  </TextCus>
                </Box>
              </Box>
              <Divider sx={{ background: '#ccc', my: '10px' }} />
              <ItemMenu
                icon={Icons.Home()}
                title={'Edit profile'}
                onAction={() => setOpenModalProfile(true)}
              />
              <ItemMenu
                isMgTop
                icon={Icons.Logout()}
                title={'Logout'}
                onAction={() => {
                  logoutMutation.mutate();
                  handleClose();
                }}
              />
            </Box>
          </Popover>
        </Grid>
      </Grid>
      {/* ============ Modal Profile ============== */}
      {openModalProfile && (
        <ModalProfile
          open={openModalProfile}
          onClose={() => setOpenModalProfile(false)}
          onOpenUpdatePass={() => setOpenUpdatePass(true)}
        />
      )}
      <ModalMenuRight open={openMenuAppBar} onClose={() => setOpenMenuAppBar(!openMenuAppBar)} />
      {openUpdatePass && (
        <ModalUpdatePassword open={openUpdatePass} onClose={() => setOpenUpdatePass(false)} />
      )}
    </Box>
  );
};
