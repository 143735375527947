import React, { forwardRef, useEffect, useRef, } from 'react'
import { mainColor } from 'theme'
import { Icons } from 'assets';
import { IInputTools } from 'types';

const TextareaTool = forwardRef<HTMLTextAreaElement, IInputTools>(function InputTool({
  setFormData, formData, name, placeholder, setErrors,
  errors, value, setValueInput, isIcon = true, bgColor = 'transparent', isDisabled = false }, ref) {
  const refTextarea: any = useRef(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setFormData({ ...formData, [name]: e.target.value });

    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  // setFormData({ ...formData, [name]: e.target.value });
  const handleOnclick = () => {
    if (value) {
      setFormData({ ...formData, [name]: value });
      setValueInput('')
    }
    setErrors({ ...errors, [name]: "" });
  };

  const autoResizeTextarea = () => {
    const textarea = refTextarea?.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(() => {
    if (value) {
      autoResizeTextarea()
    }
  }, [value])



  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      // marginLeft: '25px',
      // marginRight: '25px',
    }}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          marginTop: '15px',
        }}
      >
        <textarea
          disabled={isDisabled}
          ref={refTextarea ?? ref}
          onChange={handleInputChange}
          onClick={handleOnclick}
          value={formData?.[name]}
          placeholder={!isDisabled ? placeholder : ""}
          autoComplete="off"
          style={{
            border: `2px solid ${mainColor.borderColor1}`,
            borderRadius: '12px',
            width: '100%',
            // height: `${value ? `58px` : '45px'}`,
            backgroundColor: `${!isDisabled ? bgColor : mainColor.hoverIcon}`,
            padding: `10px 10px 10px 40px`,
            outline: 'none',
            color: `${bgColor === '' ? '#1f1f1f' : mainColor.textMain}`,
            fontSize: '16px',
            fontWeight: '500',
            overflowY: 'hidden',
            resize: 'none'
          }}
          rows={1}
        />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '10px',
            transform: 'translateY(-50%)',
            height: '24px',
            width: '24px',
          }}
        >
          {isIcon && Icons.IconInput()}
        </div>
        {errors
          ? (<p
            style={{
              position: 'absolute',
              color: '#e03636',
              fontSize: '12px',
              fontWeight: '500',
              top: '-35px',
              left: '45px',

            }}
          >{errors[name]}</p>) : ("")}

      </div>

    </div>
  )
})

export default TextareaTool