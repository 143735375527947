/** @format */

import SnackbarUtils from 'utils/SnackbarUtils';

export function useAlert() {

  const success = (msg: string) => () => {
    // enqueueSnackbar(msg, { variant: 'success' });
    SnackbarUtils.success(msg)
  };

  const error = (msg: string) => () => {
    // enqueueSnackbar(msg, { variant: 'error' });
    SnackbarUtils.error(msg)
  };

  const info = (msg: string) => () => {
    // enqueueSnackbar(msg, { variant: 'info' });
    SnackbarUtils.info(msg)
  };

  const warn = (msg: string) => () => {
    // enqueueSnackbar(msg, { variant: 'warning' });
    return SnackbarUtils.warning(msg)
  };


  return { success, error, info, warn };
}
