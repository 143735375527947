/** @format */
import React from 'react';

import 'assets/style/index.css';
import 'react-toastify/dist/ReactToastify.css';

import App from 'App';
import { ModalGlobal } from 'components';
import { AuthProvider, SocketProvider, UserProvider } from 'contexts';
import ReactDOM from 'react-dom/client';
import theme from 'theme/themes';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from 'utils/SnackbarUtils';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { initialOptionsPaypal } from 'utils';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GG_CLIENT_ID}`}>
      <PayPalScriptProvider options={initialOptionsPaypal}>
        <SocketProvider>
          <AuthProvider>
            <UserProvider>
              <I18nextProvider i18n={i18n}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <ModalGlobal />
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                  >
                    <App />
                    <SnackbarUtilsConfigurator />
                  </SnackbarProvider>
                </ThemeProvider>
              </I18nextProvider>
            </UserProvider>
          </AuthProvider>
        </SocketProvider>
      </PayPalScriptProvider>
    </GoogleOAuthProvider>
  </QueryClientProvider>,
  // </React.StrictMode>,
);
