/** @format */

import { TParams, TResponse } from './app';

export interface IImages2VideoParams {
  files?: [] | null;
  promt?: string | null;
}
export interface IViewParams {
  videoId?: string | null;
}
export interface IChangingBackgroundParams {
  fileObject?: any | null;
  fileBackground?: any | null;
  backgroundId?: string | null;
  prompt?: string | null;
}

export interface IChangeBackgroundParams {
  fileObject?: any | null;
  fileBackground?: any | null;
  backgroundId?: string | null;
  promptCreateBG?: string | null;
  promptLighting?: string | null;
}

export interface IModelBodyProps {
  setSelectedFile?: (file: File | null) => void;
  dataGallery?: TResponse<IGallery[]>;
  setParamsGallery: React.Dispatch<React.SetStateAction<TParams>>;
  paramsGallery: TParams;
  isLoadingGallery: boolean;
}

export interface IClothesProps {
  setSelectedFile?: (file: File | null) => void;
  valueCategory?: string;
  setValueCategory?: (value: string) => void;
  valueSize?: string;
  setValueSize?: (value: string) => void;
}

export interface IOutfitFinalsProps {
  result?: IFinalOutfitResult;
  isLoading?: boolean;
}

export interface IFinalOutfitResult {
  url?: string | null;
  status?: EnumStatusOutfit;
  totalStep?: number;
  currentStep?: number;
  message?: string | null;
  galleryId?: string | null;
}

export interface IFaceSwapParams {
  fileModel?: string | null;
  fileFace?: string | null;
  modelBodyId?: string | null;
  galleryId?: string | null;
}

export interface IRecentType {
  state?: string;
  id?: string;
  video?: string;
  method?: string;
  prompt?: string | null;
}

export interface ITrackingType {
  state?: string;
  id?: string;
}

export enum EnumStatusOutfit {
  STARTED = 'STARTED',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface IGallery {
  description?: string; //"";
  id?: string; //"66ab56ec0ca15f8221b20203";
  image?: string; //"http://localhost:8000/swap/outfit/result.png";
  isDeleted?: boolean; //false;
  maskBody?: string; //"";
  maskLength?: string; //"";
  serviceName?: string; //"OUTFIT";
  createdAt?: string;
}

export enum IGenerateType {
  IMAGE2VIDEO = 'IMAGE2VIDEO',
  TEXT2VIDEO = 'TEXT2VIDEO',
  ALL = 'ALL',
  CHANGE_BACKGROUND = 'CHANGE_BACKGROUND',
  GREATE_BACKGROUND = 'GREATE_BACKGROUND',
}
export interface IDetailGallery {
  clothesImage: string;
  createdAt: string;
  description: string;
  id: string;
  image: string;
  maskBody: string;
  maskLength: string;
  modelImage: string;
  type: string;
  uid: string;
  updatedAt: string;
}
