/** @format */

import { IColorDefault, IFontSize } from 'types/theme';

export const defaultColorTheme: IColorDefault = {
  // primary: 'rgb(30 31 35)',
  primary: 'rgb(18 19 23)',
  secondary: '#21303f',
  warning: '#FFCC00',
  success: '#3CD4A0',
  info: '#9013FE',
  error: '#D02B20',
  grey: '#ECECEC',
  mainBlack: '#000000',
  textMain: '#808080',
  textPurple: '#21065D',
  textD8: '#D8D8D8',
  textD1: '#707070',
  transparent: 'transparent',
  borderColor2: '#0097F5',
};

export const mainColor: IColorDefault = {
  primary: 'rgb(18 19 23)',
  secondary: '#5ADBFC',
  warning: '#FFCC00',
  success: '#3CD4A0',
  info: '#9013FE',
  error: '#750606',
  grey: '#ECECEC',
  mainBlack: '#000000',
  blackDark: '#1F1F24',
  textMain: '#FFFFFF',
  textSecondary: '#0097F5',
  textGray: '#666666',
  borderColor: '#1e1f23',
  borderColor1: '#2c2d31',
  borderColor2: '#0097F5',
  borderColor3: '#F5B2F5',
  textD1: '#707070',
  textD2: '#333333',
  textD3: '#cccccc',
  bgVideoLoading: '#1B1B1B',
  bgCountdownTimer: '#33333380',
  transparent: 'transparent',
  bgLinear: 'linear-gradient(90deg, #0097F5, #F5B2F5)',
  bgButtonHover: '#ADADAD',
  hoverIcon: '#68666671',
};

export const fontSize: IFontSize = {
  s: '12px',
  xs: '14px',
  sm: '16px',
  md: '20px',
  xl: '24px',
  xxl: '48px',
  '6xl': '144px'
};
