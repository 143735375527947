/** @format */

import {
  Box,
  Popover
} from "@mui/material";
import { InputCus } from "components/InputCus";
import { TextCus } from "components/TextCus";
import React, { useState } from "react";
import { fontSize, mainColor } from "theme";
import { EnumInputVariant, dataLighting } from "utils";
// import { mainColor } from "theme/default";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Icons } from "assets";
import { ButtonCus } from "components/ButtonCus";

interface IModalProfile {
  open?: boolean;
  onClose?: () => void;
  anchorEl?: any,
  setAnchorEl?: any,
  valueLighting?: any,
  setValueLighting?: any
}
const formInterface = { promptLighting: "" }

const ModalSelectLighting = ({ anchorEl, setAnchorEl, valueLighting, setValueLighting }: IModalProfile) => {
  /*STATE*/
  const [formData, setFormData] = useState(formInterface);
  const [errors, setErrors] = useState({});
  const [valueInput, setValueInput] = useState<any>(null)

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  /*HANDLE CLICK*/
  const handleClick = (title: string) => {
    setValueLighting(title)
    setValueInput(title)
    setFormData({ promptLighting: title })
  }

  /*HANDLE CONFIRM*/
  const handleConfirm = () => {
    setValueLighting(formData.promptLighting)
    setAnchorEl(null)
    // setValueInput(title)
    // setFormData({ promptLighting: title })
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => { setAnchorEl(null) }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        top: '10px',
        left: '0px',
        overflow: 'hidden',
        borderRadius: '12px',
        backgroundColor: 'unset !important',
      }}
    >
      <Box
        width={'520px'}
        // mx={'15px'}
        // paddingY={'20px'}
        overflow={'hidden'}
        bgcolor={`rgba(51,51,51,0.7)`}
        flexDirection={'column'}
        gap={'10px'}
        borderRadius={'12px !important'}
      >
        <Box
          px={'20px'}
          width={'100%'}>
          <TextCus
            pl={'5px'}
            pt={'15px'}
            color={mainColor.textMain}
            fontSize={`${fontSize.sm}`}
            fontWeight={600}
          >
            Lighting Config (Optional)
          </TextCus>

          <TextCus
            pl={'5px'}
            pt={'30px'}
            color={mainColor.textMain}
            fontSize={`${fontSize.xs}`}
            fontWeight={600}
          >
            Prompt Your Lighting
          </TextCus>
          <InputCus
            name="promptLighting"
            inputVariant={EnumInputVariant.textarea}
            placeholder=" Prompt Your Lighting"
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
            value={valueInput}
            setValueInput={setValueInput}
            isIcon={false}
            bgColor=""
          />
          <Box className="divider" sx={{ width: '100% !important' }}></Box>
          <TextCus
            pl={'5px'}
            color={mainColor.textMain}
            fontSize={`${fontSize.xs}`}
            fontWeight={600}
          >
            Preset Lighting
          </TextCus>
          <Box
            pt={'15px'}
            display={'flex'}
            flexWrap={'wrap'}
          >
            {dataLighting.map((item) => (
              <Box
                onClick={() => handleClick(item?.title)}
                position={'relative'}
                pt={'20px'}
                pl={'0px'}
                key={item?.id}
                display={'flex'}
                alignItems={'center'}
                flexDirection={'column'}>
                <LazyLoadImage src={item?.srcImg} alt='icon' width={'93%'}
                  style={{
                    border: `${valueLighting === item?.title ? '2px solid #0097f5' : ''} `,
                    borderRadius: '12px',
                    cursor: 'pointer',
                    // transition: 'all 0.2s ease-in-out',
                  }} />
                <Box position={'absolute'} top={'25px'} right={'10px'}>
                  {valueLighting === item?.title && Icons.IconCheckedLighting()}
                </Box>

                <TextCus
                  pt={'10px'}
                  color={mainColor.textMain}
                  fontSize={`${fontSize.s}`}
                  fontWeight={600}
                >
                  {item?.title}
                </TextCus>
              </Box>
            ))}
          </Box>

          <Box width={'100%'} display={'flex'} justifyContent={'center'} py={'25px'}>
            <ButtonCus
              onClick={handleConfirm}
              sx={{
                padding: "0px !important",
                marginBottom: `5px`,
                borderRadius: '12px',
                width: '157px',
                height: '44px',
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                background: "linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)"
              }}
            >
              <TextCus
                color={mainColor.textMain}
                fontSize={`${fontSize.sm}`}
                paddingX={1}
              >
                Confirm
              </TextCus>
            </ButtonCus>
          </Box>

        </Box>

      </Box>
    </Popover>
  );
};

export default ModalSelectLighting;
