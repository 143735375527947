/** @format */

import { Box, } from '@mui/material';
import { TextCus } from 'components/TextCus';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { dataEmty } from 'assets';

import { fontSize, mainColor } from 'theme';



// interface TypeRecent {
//   dataRecents?: any,
//   isLoading?: boolean
// }
const RecentEmty = () => {
  return (
    <Box
      // mt={'30px'}
      width={'100%'}
      height={'100%'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <LazyLoadImage src={dataEmty} alt='icon' />
      <TextCus
        color={mainColor.textMain}
        fontSize={`${fontSize.sm}`}
        padding={'15px'}
      >
        Your generations will be shown here
      </TextCus>

    </Box>


  );
};

export default RecentEmty;

