/** @format */

import { Box } from '@mui/material';
import { ButtonCus, TextCus } from 'components';
import * as React from 'react';
import { fontSize, mainColor } from 'theme';
import { useNavigate } from 'react-router-dom';
import { Icons, iconRoundArrow } from 'assets';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface CardHomeMedia {
  title?: string;
  srcImg?: string;
  srcVideo?: string;
  to?: string;
}

export const CardHomeMedia: React.FC<CardHomeMedia> = ({ title, srcImg, srcVideo, to }) => {
  const navigate = useNavigate();
  const handleClick = (path: any) => {
    navigate(path);
  };

  return (
    <Box
      width={`calc((100% - 40px) / 3)`}
      justifyContent={'center'}
      alignItems={'center'}
      height={'200px'}
      marginX={'auto'}
      sx={{
        position: 'relative',
        '&:hover': {
          '.layout1': {
            opacity: 0,
            transition: 'all 0.5s ease-in-out',
          },
          '.layout2': {
            opacity: 1,
            transition: 'all 0.5s ease-in-out',
          },
          '.button': {
            transform: 'translateY(0px)',
            transition: 'all 0.5s ease-in-out',
          },
          '.title': {
            transform: 'translateY(calc(100% + 20px))',
            transition: 'all 0.5s ease-in-out',
          },
          '.img-block': {
            transform: 'translateX(-100%)',
            transition: 'all 0.5s ease-in-out',
          },
          '.arrow': {
            opacity: 0,
            transition: 'all 0.5s ease-in-out',
          },
          '.img-overlay': {
            opacity: 1,
            transition: 'all 0.5s ease-in-out',
          },
        },
      }}
    >
      {title !== 'Background Change' ? (
        <>
          <Box width={'100%'} height={'100%'} position={'absolute'}>
            <Box
              marginX={'auto'}
              marginY={'14px'}
              display={'flex'}
              justifyContent={''}
              flexDirection={'column-reverse'}
              textAlign={'left'}
              position={'relative'}
              width={'100%'}
              height={'100%'}
              maxWidth={'504px'}
              sx={{
                borderRadius: '12px',
                overflow: 'hidden',
                objectFit: 'cover',
              }}
            >
              <Box
                className='layout1'
                margin={'auto'}
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url(${srcImg})`,
                  transition: 'all 0.5s ease-in-out',
                }}
              ></Box>

              <Box
                position={'absolute'}
                display={'flex'}
                width={'100%'}
                height={'60px'}
                className='title'
                overflow={'hidden'}
                sx={{
                  background: 'linear-gradient(0deg, rgba(1,1,1,0.5) 0%, rgba(0,0,0,0.5) 100%)',
                  transition: 'all 0.5s ease-in-out',
                  backdropFilter: 'blur(10px)',
                  boxShadow: 'inset -10px -10px 10px -5px rgba(135,207,254,0.3)',
                  borderRadius: '8px',
                }}
              >
                <TextCus
                  position={'absolute'}
                  color={mainColor.textMain}
                  fontSize={`${fontSize.sm}`}
                  fontWeight={'bold'}
                  p={'15px'}
                >
                  {title}
                </TextCus>
              </Box>
            </Box>
          </Box>

          <Box position={'absolute'} width={'100%'} height={'100%'}
            onClick={() => {
              handleClick(to);
            }}
            sx={{ cursor: 'pointer' }}>
            <Box
              marginX={'auto'}
              marginY={'14px'}
              display={'flex'}
              justifyContent={''}
              flexDirection={'row'}
              alignItems={'center'}
              textAlign={'left'}
              position={'relative'}
              width={'100%'}
              height={'100%'}
              borderRadius={'12px'}
              overflow={'hidden'}
              maxWidth={'504px'}

            >
              <Box
                className='layout2'
                sx={{
                  position: 'absolute',
                  opacity: 0,
                  width: '100%',
                  height: 'auto',
                }}
              >
                <video
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '12px',
                  }}
                  onMouseEnter={(e) => {
                    const videoElement = e?.currentTarget as HTMLVideoElement;
                    if (videoElement && videoElement.paused) {
                      videoElement.muted = true;
                      videoElement.play().catch((error) => {
                        console.error("Error playing video:", error);
                      });
                    }
                  }}
                  onMouseLeave={(e) => {
                    const videoElement = e?.currentTarget as HTMLVideoElement;
                    if (videoElement && !videoElement.paused) {
                      videoElement.pause();
                      videoElement.muted = false;
                    }
                  }}
                  loop
                  muted={false}
                >
                  <source src={srcVideo} type="video/mp4" />
                </video>
              </Box>
              <Box
                position={'absolute'}
                right={'10px'}
                bottom={'10px'}
                sx={{
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                <ButtonCus
                  className='button'
                  sx={{
                    transform: 'translateY(calc(100% + 20px))',
                    transition: 'all 0.3s ease-in-out',
                    borderRadius: '12px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background:
                      'linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)',
                  }}
                  endIcon={Icons.ArrowRight()}
                >
                  <TextCus
                    color={mainColor.textMain}
                    fontSize={`${fontSize.sm}`}
                    paddingX={1}
                    fontWeight={'600'}
                  >
                    Create Now
                  </TextCus>
                </ButtonCus>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box width={'100%'} height={'100%'} position={'absolute'}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              handleClick(to);
            }}>
            <Box
              marginX={'auto'}
              marginY={'14px'}
              display={'flex'}
              flexDirection={'column-reverse'}
              textAlign={'left'}
              position={'relative'}
              width={'100%'}
              height={'100%'}
              maxWidth={'504px'}
              sx={{
                borderRadius: '12px',
                overflow: 'hidden',
                zIndex: 10,
                objectFit: 'cover',
              }}
            >
              <Box
                className='img-block'
                margin={'auto'}
                sx={{
                  position: 'absolute',
                  width: '50%',
                  height: '100%',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url(${srcImg})`,
                  transform: 'translateX(0%)',
                  transition: 'all 0.3s ease-in-out',
                }}
              ></Box>
              <Box
                position={'absolute'}
                display={'flex'}
                width={'100%'}
                height={'60px'}
                className='title'
                overflow={'hidden'}
                sx={{
                  background: 'linear-gradient(0deg, rgba(1,1,1,0.5) 0%, rgba(0,0,0,0.5) 100%)',
                  transition: 'all 0.3s ease-in-out',
                  backdropFilter: 'blur(10px)',
                  boxShadow: 'inset -10px -10px 10px -5px rgba(135,207,254,0.3)',
                  borderRadius: '8px',
                }}
              >
                <TextCus
                  position={'absolute'}
                  color={mainColor.textMain}
                  fontSize={`${fontSize.sm}`}
                  fontWeight={'bold'}
                  p={'15px'}
                  bottom={0}
                  left={0}
                >
                  {title}
                </TextCus>
              </Box>
            </Box>
          </Box>

          <Box width={'100%'} height={'100%'} position={'absolute'}>
            <LazyLoadImage
              className='arrow'
              src={iconRoundArrow}
              style={{
                position: 'absolute',
                top: '10%',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 10,

              }}
            />
          </Box>

          <Box position={'absolute'} width={'100%'} height={'100%'}

          >
            <Box
              marginX={'auto'}
              marginY={'14px'}
              display={'flex'}
              justifyContent={''}
              flexDirection={'row'}
              alignItems={'center'}
              textAlign={'left'}
              position={'relative'}
              width={'100%'}
              height={'100%'}
              borderRadius={'12px'}
              overflow={'hidden'}
              maxWidth={'504px'}
            >
              <Box
                className='img-overlay'
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${srcVideo})`,
                }}
              ></Box>
              <Box
                position={'absolute'}
                right={'10px'}
                bottom={'10px'}
                sx={{
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                <ButtonCus

                  className='button'
                  sx={{

                    transform: 'translateY(calc(100% + 20px))',
                    transition: 'all 0.3s ease-in-out',
                    borderRadius: '12px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background:
                      'linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)',
                  }}
                  endIcon={Icons.ArrowRight()}
                >
                  <TextCus
                    color={mainColor.textMain}
                    fontSize={`${fontSize.sm}`}
                    paddingX={1}
                    fontWeight={'600'}
                  >
                    Create Now
                  </TextCus>
                </ButtonCus>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
