/** @format */

import { useState } from 'react';
import { userService } from 'services';
import SnackbarUtils from 'utils/SnackbarUtils';

export const useDownloadImage = () => {
  const [isLoadingDlImg, setIsLoadingDlImg] = useState(false);

  const handleDownLoadImg = async (urlImg: any, imgName: any) => {
    console.log('🚀 ~ handleDownLoadImg ~ imgName:', imgName);
    console.log('🚀 ~ handleDownLoadImg ~ urlImg:', urlImg);
    setIsLoadingDlImg(true);
    try {
      const res: any = await userService.getBlobImg(urlImg);
      const blob = new Blob([res]);
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = imgName || 'image.jpg';
      document.body.appendChild(a);
      a.click();
      setIsLoadingDlImg(false);
    } catch (error) {
      SnackbarUtils.warning('Download error!');
      setIsLoadingDlImg(false);
    }
  };

  return { handleDownLoadImg, isLoadingDlImg };
};
