import { Box } from '@mui/material'
import { TextCus } from 'components'
import React, { memo } from 'react'
import { fontSize, mainColor } from 'theme'

interface IBoxPromt {
    handleClickPrompt: (v: string) => void
    item: any
}


const BoxPromt = ({ item, handleClickPrompt }: IBoxPromt) => {
    return (
        <Box
            onClick={() => handleClickPrompt(String(item?.prompt))}
            marginX={'7px'}
            marginY={'14px'}
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
            alignItems={'center'}
            textAlign={'left'}
            sx={{
                width: '389px',
                height: '74px',
                borderRadius: '12px',
                backgroundColor: `${mainColor.textD2}`,
                '&:hover': { background: `${mainColor.textD1} `, cursor: 'pointer', border: '1px solid white' },
            }} >
            <TextCus
                width={'100%'}
                className='multiline-ellipsis'
                px={'10px'}
                color={mainColor.textMain}
                fontSize={`${fontSize.xs}`}
            >
                {item?.prompt}
            </TextCus>
        </Box>
    )
}

export default memo(BoxPromt, (prev, next) => (prev.item.prompt === next.item.prompt))
