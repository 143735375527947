/** @format */

export type FormLogin = {
  email: string;
  password: string;
  checked?: boolean;
};

export type FormRegister = {
  email: string;
  password: string;
  confirmPassword: string;
  userName: string;
};

export type FormLoginSocial = {
  idToken: string;
};

export interface ForgotForm {
  email: string;
  type?: EnumTypeSendMailOtp;
}

export interface OTPForm extends ForgotForm {
  otp: string;
}

export interface OTPRegisterForm {
  otp: string;
}

export type FormResetPassword = {
  newPassword: string;
  confirmPassword: string;
};

export type ErrorOtpForm = {
  otp: string;
};

export type ErrorOtpMessage = {
  otp: {
    message: string;
  };
};

export type LastPageType = {
  pagination: {
    page: number;
    total: number;
  };
};

export enum EnumTypeSendMailOtp {
  REGISTER = 'REGISTER',
  FOR_GOT_PASS_WORD = 'FOR_GOT_PASS_WORD',
}

export enum TypeStatusDevice {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  REGISTER = 'REGISTER',
}

export interface IFormImageToVideo {
  file?: File;
  prompt?: string;
}

export interface IDeviceProps {
  deviceId: string;
  ipAddress: string;
  platForm: string;
}
