/** @format */

export const PUBLIC_URL = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY_OTP: '/verify-otp',
  RESET_PASSWORD: '/reset-password',
  NOT_FOUND: '/*',
  MAINTAIN: '/maintain',
};

export const APP_ROOT = {
  ROOT: '/admin',
  DASHBOARD: '/',
  PLAN: '/pricing-plan',
  PLAN_STANDARD: '/pricing-plan/standard',
  INFO_PAYMENT: '/info-payment',
  TRANSACTION: '/transactions',
  TEXT_2_VIDEO: '/text-to-video',
  IMAGE_2_VIDEO: '/image-to-video',
  CHANGE_BACKGROUND: '/changing-background',
  CONTACT_US: '/contact-us',
};
