/** @format */

import { Box } from '@mui/material';
import { TextCus } from 'components/TextCus';
import dayjs from 'dayjs';
import { useWindowDimensions } from 'hooks';
import { useGenerate } from 'hooks/useGenerate';
import * as React from 'react';
import { fontSize, mainColor } from 'theme';

interface CardCommunity {
  title?: string,
  desc?: string,
  createdAt?: string,
  author?: string,
  url?: string,
  to?: string,
  views?: number
  setStateVideoModal: (item: any) => void
  item: any,
  setOpenVideo: React.Dispatch<React.SetStateAction<boolean>>
}


export const CardCommunity: React.FC<CardCommunity> = ({ title, createdAt, url, views, author, item, desc, setStateVideoModal, setOpenVideo }) => {
  /*HOOKS*/
  const { countViews } = useGenerate();

  const handelClickVideo = (videoId: string) => {
    countViews.mutate(videoId)
    setOpenVideo(true)
    setStateVideoModal(item)
  }

  const refVideo = React.useRef<HTMLVideoElement>(null);
  React.useEffect(() => {
    if (refVideo?.current) {
      refVideo?.current.load()
    }
  }, [url])

  const [amount, setAmount] = React.useState(5)
  const widthGlobal = useWindowDimensions()?.width
  React.useEffect(() => {
    if (widthGlobal >= 1440) {
      setAmount(5)
    }
    if (widthGlobal <= 1440) {
      setAmount(4)
    }
    if (widthGlobal <= 1024) {
      setAmount(3)
    }
    if (widthGlobal <= 768) {
      setAmount(2)
    }
    if (widthGlobal <= 425) {
      setAmount(1)
    }
  }, [widthGlobal])
  return (
    <Box
      width={`calc((100% - 40px) / ${amount})`}
      height={'334px'}
      sx={{
        '&:hover': {
          ".box-blur": {
            display: 'none',
          },
          ".desc": {
            display: 'inline-block',
          },
          ".box-video": {
            filter: `blur(2px)`,
            scale: 1.15,
            transition: 'all 0.5s ease-in-out',
          }
        },
        overflow: 'hidden',
        borderRadius: '12px',
        zIndex: 55
      }}
    >
      <Box
        onClick={() => { handelClickVideo(item?.id) }}
        display={'flex'}
        position={'relative'}
        height={'100%'}
      >
        <Box
          width={"100%"}
          className="box-video"
          sx={{ objectFit: 'cover' }}
        >
          <video
            ref={refVideo}
            key={url}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '12px',
              cursor: 'pointer',
            }}
            onMouseEnter={(e) => {
              const videoElement = e?.currentTarget as HTMLVideoElement;
              if (videoElement && videoElement.paused) {
                videoElement.muted = true;
                videoElement.play().catch((error) => {
                  console.error("Error playing video:", error);
                });
              }
            }}
            onMouseLeave={(e) => {
              const videoElement = e?.currentTarget as HTMLVideoElement;
              if (videoElement && !videoElement.paused) {
                videoElement.pause();
                videoElement.muted = false;
              }
            }}
            loop
          // muted={false}
          >
            <source src={url} type="video/mp4" />
          </video>
        </Box>

        <Box
          position={'absolute'}
          paddingY={'15px'}
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={'column'}
          // alignItems={'center'}
          width={'100%'}
          height={'100%'}
        >

          <TextCus
            color={mainColor.textMain}
            fontSize={`${fontSize.s}`}
            marginX={'15px'}
          >
            {author}
          </TextCus>

          <Box
            marginX={'15px'}
            display={'flex'}
            justifyContent={'space-between'}
            flexDirection={'column'}
          >
            <Box
              className="multiline-ellipsis"
              sx={{
                color: `${mainColor.textMain}`,
                fontSize: `${fontSize.xs}`,
                fontWeight: `${'bold'}`,
                zIndex: 6,
                paddingBottom: '20px'
              }}
            >
              {title}
            </Box>
            <Box
              className='desc'
              sx={{
                display: 'none',
                color: `${mainColor.textD3}`,
                fontSize: `${fontSize.s}`,
                fontWeight: `${'normal'}`,
                paddingBottom: '30px',
                zIndex: 6,
              }}
            >
              {desc}
            </Box>
            <span
              style={{
                color: `${mainColor.textD3}`,
                fontSize: `${fontSize.s}`,
                zIndex: 6
              }}
            >
              {`${views} views`} <span style={{ paddingLeft: '15px' }}>
                {dayjs(createdAt).format("HH:mm:ss YYYY/MM/DD")}
              </span>
            </span>

            <Box
              className='box-blur'
              position={'absolute'}
              width={'100%'}
              height={'120px'}
              sx={{
                display: 'hidden',
                zIndex: 1,
                bottom: '-5px',
                left: '0px',
                filter: `blur(5px)`,
                background: `linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,1) 100%, rgba(0,0,0,0) 1000%)`
              }}
            ></Box>

          </Box>

        </Box>
      </Box>

      {/* <ModalVideo
        open={openVideo}
        onClose={() => setOpenVideo(!openVideo)}
        url={url}
        desc={desc}
        createdAt={createdAt}
      /> */}

    </Box >

  );
}


