/** @format */

import { Box } from '@mui/material';
// import Grid from '@mui/material/Grid2';

import React, { FC, useState } from 'react';
import { CardCommunity } from './CardCommunity';
import { ModalVideo } from 'components/ModalGlobal';

interface TypeCommunity {
  dataCommunity?: any,
}
const Community: FC<TypeCommunity> = ({ dataCommunity }) => {
  const [stateVideoModal, setStateVideoModal] = useState<any>(null)
  const [openVideo, setOpenVideo] = React.useState(false);

  return (
    <Box
      mt={'40px'}
      width={'100%'}
      display={'flex'}
      flexWrap={'wrap'}
      justifyContent={"start"}
      // alignItems={'center'}
      gap={"10px"}
      minHeight={'680px'}
    >
      {dataCommunity?.map((item, index) => (
        <CardCommunity
          key={index}
          item={item}
          views={item?.views ? item.views : 0}
          author={item?.author ?? "Illuminus-AI Vipix"}
          desc={item?.prompt ?? "default"}
          title={item?.prompt ?? "prompt...."}
          url={item?.video}
          createdAt={item?.createdAt ?? "Aug 14, 2024, 2:42 PM"}
          setStateVideoModal={setStateVideoModal}
          setOpenVideo={setOpenVideo}
        />))}

      <ModalVideo
        open={openVideo}
        onClose={() => setOpenVideo(false)}
        url={stateVideoModal?.video}
        desc={stateVideoModal?.prompt}
        createdAt={stateVideoModal?.createdAt}
      />
    </Box>

  );
};

export default Community;
