/** @format */

import React, { useEffect, useRef } from 'react';

import { TextCus } from 'components/TextCus';
import { fontSize, mainColor } from 'theme/default';

import { Box, Modal } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { iconCloseModal } from 'assets';


interface IModalVideo {
  open: boolean,
  onClose?: () => void,
  url?: any,
  desc?: string | any,
  createdAt?: string | any,

}

const ModalVideo = ({ open, onClose, url, desc, createdAt }: IModalVideo) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (open) {
        videoElement?.play();
      } else {
        videoElement?.pause();
      }
    }
  }, [open])

  return (
    <Modal
      keepMounted
      open={open}
      // onClick={onClose}
      onClose={onClose}
      sx={{
        backgroundColor: '#00000087',
        zIndex: 9999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      aria-labelledby='keep-mounted-modal-title'
      aria-describedby='keep-mounted-modal-description'
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        // border={'1px solid #fff'}
        borderRadius={'12px'}
        flexDirection={'column'}
        width={'742px'}
        // height={'617px'}
        sx={{ outline: 'none' }}
        bgcolor={mainColor.textD2}
      >
        <Box
          p={'15px'}
          mr={'15px'}
          display={'flex'}
          justifyContent={'end'}
          width={'100%'}

        >
          <LazyLoadImage
            onClick={onClose}
            src={iconCloseModal} alt='icon'
            style={{ cursor: 'pointer' }} />
        </Box>

        <Box
          width={'650px'}
          // height={'449px'}
          sx={{ objectFit: 'cover' }}
        >
          <video
            key={url}
            className='modal-video'
            style={{
              width: '100%',
              maxHeight: '80%',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            ref={videoRef}
            controls={true}
            // autoPlay={true}
            loop={true}
            muted={true}
          >
            <source src={url} type="video/mp4" />
          </video>
        </Box>
        <Box
          width={'100%'}
          ml={'25px'}
        >
          <TextCus
            className='multiline-ellipsis'
            color={mainColor.textMain}
            fontSize={`${fontSize.sm}`}
            marginX={'15px'}
            marginY={'10px'}
          >
            {desc}
          </TextCus>
          <TextCus
            color={mainColor.textD3}
            fontSize={`${fontSize.s}`}
            marginX={'15px'}
            marginY={'0px'}
            pb={'20px'}
          >
            {createdAt}
          </TextCus>

        </Box>
      </Box>
    </Modal>
  );
};

export default ModalVideo;
