/** @format */

import { Box, Checkbox, Divider } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { ButtonNavigate } from '../components';
import { useNavigate } from 'react-router-dom';
import { APP_ROOT, PUBLIC_URL } from 'utils/routes';
import { ButtonCus, InputOutLine, TextCus } from 'components';
import { fontSize, mainColor } from 'theme';
import { useAuthentication } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import { FormRegister } from 'types';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerSchema } from 'utils';
import { Icons } from 'assets';
import { useGoogleLogin } from '@react-oauth/google';

const Register = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormRegister>({
    resolver: yupResolver(registerSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const navigateSignIn = useCallback(() => {
    navigate(PUBLIC_URL.LOGIN);
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [showCpw, setShowCpw] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [checked, setChecked] = useState(true);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  const handleClickShowPcw = () => setShowCpw(!showCpw);
  const { registerMutation, loginSocialMutation } = useAuthentication(navigateSignIn);

  const onSubmit = useCallback((data: FormRegister) => {
    registerMutation.mutate(data);
  }, []);

  const handleLoginGoogle = useGoogleLogin({
    onSuccess: ({ access_token }) => {
      try {
        loginSocialMutation.mutate({ idToken: access_token });
      } catch (error: unknown) {
        console.log('Login Social error:', error);
      }
    },
  });

  return (
    <Box
      bgcolor={'black'}
      width={'100%'}
      height={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      padding={'20px'}
    >
      {/* Header */}
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
        <ButtonNavigate title='Skip register' action={() => navigate(APP_ROOT.DASHBOARD)} />
      </Box>
      {/* Content */}
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Box mt={3} width={'90%'} px={'15px'}>
          <TextCus fontSize={'20px'} mb={4} fontWeight={'700'} color={mainColor.textMain}>
            Create an account
          </TextCus>
          <ButtonCus
            LabelProps={{ variant: 'bodyBold' }}
            useI18n
            sx={{
              width: '100%',
              background: 'white !important',
              gap: '20px',
              border: '1px solid #333333 !important',
              height: '54px !important',
              borderRadius: '12px',
            }}
            variant={'text'}
            iconProps={{ variant: 'google' }}
            onClick={() => handleLoginGoogle()}
          >
            <TextCus fontSize={fontSize.xs} fontWeight={'600'} color={'rgba(0, 0, 0, 0.54)'}>
              Sign in with Google
            </TextCus>
          </ButtonCus>
        </Box>
        <Box
          flex={0.5}
          width={'90%'}
          px={'15px'}
          mt={2}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            width={'100%'}
          >
            <Divider sx={{ flex: 1, background: 'rgba(102, 102, 102, 0.25)' }} />
            <TextCus color={'#B2B2B2'} fontSize={fontSize.xs} fontWeight={'400'} padding={'20px'}>
              OR
            </TextCus>
            <Divider sx={{ flex: 1, background: 'rgba(102, 102, 102, 0.25)' }} />
          </Box>
          <Box mt={2}>
            <Controller
              name='userName'
              control={control}
              render={({ field }) => (
                <InputOutLine
                  label='Name'
                  {...field}
                  value={field.value}
                  setValue={field.onChange}
                  error={!!errors.userName}
                  errorMessage={errors?.userName?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <InputOutLine
                  type='text'
                  label='Email'
                  {...field}
                  value={field.value}
                  setValue={field.onChange}
                  fullWidth
                  error={!!errors.email}
                  errorMessage={errors?.email?.message}
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name='password'
              control={control}
              render={({ field }) => (
                <InputOutLine
                  label='Password'
                  {...field}
                  type={showPassword ? 'text' : 'password'}
                  value={field.value}
                  setValue={field.onChange}
                  error={!!errors.password}
                  errorMessage={errors.password?.message}
                  fullWidth
                  sx={{
                    mb: 2.5,
                  }}
                  icon={<>{showPassword ? <Icons.Eye /> : <Icons.EyeHide />}</>}
                  onClickIcon={handleClickShowPassword}
                />
              )}
            />

            <Box mt={2}>
              <Controller
                name='confirmPassword'
                control={control}
                render={({ field }) => (
                  <InputOutLine
                    label='Confirm Password'
                    {...field}
                    type={showCpw ? 'text' : 'password'}
                    value={field.value}
                    setValue={field.onChange}
                    error={!!errors.confirmPassword}
                    errorMessage={errors.confirmPassword?.message}
                    fullWidth
                    icon={<>{showCpw ? <Icons.Eye /> : <Icons.EyeHide />}</>}
                    onClickIcon={handleClickShowPcw}
                  />
                )}
              />
            </Box>
            <Box mt={2} display={'flex'} alignItems={'start'} ml={'-10px'}>
              <Checkbox
                sx={{
                  color: mainColor.textSecondary,
                  '&.Mui-checked': {
                    color: mainColor.textSecondary,
                  },
                }}
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Box component={'p'} color={mainColor.textMain}>
                By signing in to ViPix, you agree to our{' '}
                <a
                  style={{ color: mainColor.textSecondary }}
                  target='blank'
                  href='https://vipix.io/assets/TERMS-AND-CONDITIONS-STYLEGEN.pdf'
                >
                  Terms & Conditions
                </a>{' '}
                and{' '}
                <a
                  style={{ color: mainColor.textSecondary }}
                  href='https://vipix.io/assets/PRIVACY-POLICY-STYLEGEN.pdf'
                  target='blank'
                >
                  Privacy Policy
                </a>
              </Box>
            </Box>

            <Box mt={10} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <ButtonCus
                title='Register'
                LabelProps={{ variant: 'bodyBold' }}
                useI18n
                sx={{ width: '100%' }}
                variant={'text'}
                type='submit'
                isLoading={registerMutation.isPending}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Footer */}
      <Box>
        <Box display={'flex'} gap={'5px'} mt={'30px'} justifyContent={'center'}>
          <TextCus color={mainColor.textMain}> Don&apos;t have an account?</TextCus>{' '}
          <Box
            component={'div'}
            onClick={navigateSignIn}
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            fontWeight={'bold'}
            color={mainColor.textSecondary}
          >
            Sign in
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
