/** @format */

export const END_POINT = {
  AUTH: {
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    REFRESH_TOKEN: '/auth/refresh-token',
    CHANGE_PASSWORD: '/auth/change-password',
    LOGIN_SOCIAL: '/auth/login-social',
    VERIFY_OTP: '/auth/verify-otp',
    VERIFY_EMAIL: '/auth/verify-email',
  },
  USER: {
    GET_PROFILE: '/user/me',
    LOGOUT: '/user/logout',
    UPDATE_PROFILE: '/user/profile',
    UPDATE_PASS: '/auth/update-password',
    GET_BLOB_IMG: '/storage/download-link',
  },
  OTP: {
    SEND_OTP: '/send-mail/otp',
  },
  GENERATE: {
    GET_RENCENT: '/ai-histories/recents',
    GET_PROMPT: '/ai-histories/prompt',
    GET_COMMUNITY: '/ai-histories/community',
    TEXT_2_VIDEO: '/ai-tools/text-to-video',
    IMAGES_2_VIDEO: '/ai-tools/image-to-video',
    TASK_TRACKING: '/ai-tools/task-tracking',
    TRACKING_AI: '/ai-tools/tracking-ai',
    COUNT_VIEWS: '/ai-histories/count-views',
    CHANGING_BACKGROUND: '/ai-tools/changing-background',
    GENERATE_BACKGROUND: '/ai-tools/generate-background',
    DELETE_PROJECT: '/ai-histories/user-delete',
    CHANGE_BACKGROUND: '/ai-tools/change-background',
  },

  PAYMENT: {
    ORDER: '/payments/paypal/orders',
    APPROVE: '/payments/paypal/approve',
    PAYMENT_PAYPAL: '/payments',
    IMAGES_2_VIDEO: '/ai-tools/image-to-video',
    PLAN: '/plan',
    DETAIL_PLAN: '/plan/detail?id=',
    TRANSACTIONS: '/transaction-plans/user',
    CONTACT_US: '/contact-us',
  },

  CLOTHES: {
    GET_EXAMPLE: '/clothes/example',
  },
  AI_TOOLS: {
    OUTFIT_TOOLS: '/ai-tools/outfit',
    FACE_SWAP: '/ai-tools/face-swap',
  },
  GALLERY: {
    GET_GALLERY: '/gallery/user',
  },
  PRICING: {
    PAYMENT: {
      ORDERS: '/payments/paypal/orders',
      TRACKING_ORDER: '/payments/paypal/tracking-order',
      APPROVE: '/payments/paypal/approve',
    },
    PLAN: {
      GET_PACKAGES: '/plan',
      GET_INVOICES: '/transaction-plans/user',
    },
  },
  NOTIFICATIONS: {
    GET_NOTIFICATIONS: '/notifications',
  },
};
