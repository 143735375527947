/** @format */

import { Box } from '@mui/material';
import { TextCus } from 'components';
import React, { forwardRef, useMemo } from 'react';
import { IBaseInput, IInputTools } from 'types';
import { EnumInputVariant } from 'utils';
import createStyle from './styles';
import BaseInput from './BaseInput';
import InputTool from './InputTool';
import TextareaTool from './TextareaTool';

const InputCus = forwardRef<
  HTMLDivElement | HTMLInputElement,
  IBaseInput | IInputTools // | IInputLookup | IInputNumber
>(function Input(
  {
    inputVariant = EnumInputVariant.base,
    variant = 'outlined',
    autoComplete = 'off',
    label,
    title,
    LabelProps,
    required,
    useI18n,
    labelStyles: labelstyles,
    ...props
  }: any,
  ref,
) {
  const styles = createStyle();

  const InputCompo = useMemo(
    () => ({
      [EnumInputVariant.base]: BaseInput,
      [EnumInputVariant.tool]: InputTool,
      [EnumInputVariant.textarea]: TextareaTool,

    }),
    [],
  );
  const inpLabel = useMemo(
    () =>
      !label && !title
        ? undefined
        : label || (
          <Box sx={styles.wrapLabel}>
            <TextCus useI18n sx={LabelProps?.sx || labelstyles} {...LabelProps}>
              {title}
            </TextCus>
            {required && (
              <TextCus useI18n sx={styles.rMark} {...LabelProps}>
                *
              </TextCus>
            )}
          </Box>
        ),
    [label, title, useI18n, required, LabelProps, labelstyles],
  );

  const ContentInputComponent = (inputVariant && InputCompo[inputVariant]);
  return (
    <ContentInputComponent
      variant={variant}
      autoComplete={autoComplete}
      inputLabel={inpLabel}
      title={title}
      value={props.value}
      ref={ref}
      {...(props as any)}
    />
  );
});

export default InputCus;
