/** @format */

import { MoreVert, Launch, ExpandMore, ViewListOutlined, DateRange } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, {
  forwardRef,
  memo,
  useEffect,
  useId,
  useState,
  useMemo,
  FocusEventHandler,
  useCallback,
} from 'react';
import { IBaseInput, ISXTheme } from 'types';
import { EnumValueDataType, getDataType } from 'utils';
import createStyle from './styles';

const BaseInput = forwardRef<HTMLDivElement, IBaseInput>(function Base(
  {
    variant = 'standard',
    underline,
    inputLabel,
    sx,
    icon,
    iconType,
    defaultValue,
    InputLabelProps,
    type,
    autoComplete = 'off',
    InputProps,
    disabled = InputProps?.readOnly,
    onChangeText,
    onBlur,
    onFocus,
    onChange,
    onClick,
    ...props
  },
  ref,
) {
  const styles = createStyle();
  const id = useId();

  const [inputVal, setIputVal] = useState('');
  const [isOpenExt, setIsOpenExt] = useState(false);
  const [shrinkIp, setShink] = useState<boolean>(false);

  const labelProps = useMemo(
    () => ({ ...InputLabelProps, shrink: shrinkIp }),
    [InputLabelProps, shrinkIp],
  );

  const iconsModal = {
    threeDot: {
      position: icon?.position ?? 'end',
      component: icon?.component ?? <MoreVert fontSize='small' />,
    },
    externalLink: {
      position: icon?.position ?? 'end',
      component: icon?.component ?? <Launch fontSize='small' />,
    },
    downArrow: {
      position: icon?.position ?? 'end',
      component: icon?.component ?? <ExpandMore fontSize='small' />,
    },
    datePicker: {
      position: icon?.position ?? 'end',
      component: icon?.component ?? <DateRange fontSize='small' />,
      downArrow: {
        position: icon?.position ?? 'end',
        component: icon?.component ?? <ExpandMore fontSize='small' />,
      },
      viewList: {
        position: icon?.position ?? 'end',
        component: icon?.component ?? <ViewListOutlined fontSize='small' />,
      },
    },
  };

  useEffect(() => {
    const v = `${props.value ?? defaultValue ?? ''}`.trim();

    if (v !== 'undefined') {
      setIputVal(v);
      v && setShink(true);
      !v && setShink(false);
    }
  }, [defaultValue, JSON.stringify(props)]);

  const item = iconType ? iconsModal[iconType] : null;

  const handleOnclick = e => {
    onClick?.(e);
    if (iconType === 'externalLink') {
      setIsOpenExt(!isOpenExt);
    }
  };
  useEffect(() => {
    inputVal && setShink(true);
  }, [inputVal]);

  const InputCustomProps = useMemo(
    () => ({
      ...InputProps,
      [!!item && item?.position === 'start' ? 'startAdornment' : 'endAdornment']:
        !!item?.component && (
          <InputAdornment
            position={!!item && item?.position === 'start' ? 'start' : 'end'}
            onClick={handleOnclick}
          >
            <IconButton sx={{ padding: 0 }} disabled={disabled}>
              {item?.component}
            </IconButton>
          </InputAdornment>
        ),
      readOnly: disabled,
      disableUnderline: underline,
    }),
    [JSON.stringify(item), disabled, InputProps, underline],
  );

  const baseStyle = variant ? styles[variant] : {};
  const dateStyle = (type === 'date' && styles.dateLabel) || {};
  const stylesOveride = (
    getDataType(sx) === EnumValueDataType.Array
      ? [baseStyle, dateStyle, ...(sx as any)]
      : [baseStyle, dateStyle, { ...sx }]
  ) as ISXTheme;

  const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    e => {
      setShink(true);
      onFocus?.(e);
    },
    [onFocus],
  );
  const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (!inputVal) setShink(false);
      onBlur?.(e);
    },
    [onBlur, inputVal],
  );
  return (
    <>
      <TextField
        id={id}
        sx={stylesOveride}
        variant={variant}
        InputProps={InputCustomProps}
        InputLabelProps={labelProps}
        onChange={({ target }) => {
          setIputVal(target.value);
          onChange?.({ target: { name: props?.name || '', value: target.value } } as any);
          onChangeText?.(target.value);
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        label={inputLabel}
        defaultValue={defaultValue as any}
        value={inputVal}
        type={type}
        autoComplete={autoComplete}
        {...props}
        ref={ref}
      />
    </>
  );
});
export default memo(BaseInput);
