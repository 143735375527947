import { Box } from "@mui/material";
import React from "react";
import { fontSize, mainColor } from "theme";

interface InputUpdate {
	valueTitle: string;
	handleGalleryTitleChange: (
		event: React.ChangeEvent<HTMLInputElement>,
	) => void;
	handleGalleryTitleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
	selectAllInlineText: (event: React.MouseEvent<HTMLInputElement>) => void;
	refInput?: React.RefObject<HTMLInputElement>;
}

const InputUpdate = ({
	valueTitle,
	handleGalleryTitleChange,
	handleGalleryTitleBlur,
	selectAllInlineText,
	refInput,
}: InputUpdate) => {
	return (
		<Box
			width={"100%"}
			component={"input"}
			color={mainColor.textGray}
			fontSize={fontSize.sm}
			fontWeight={"600"}
			ref={refInput}
			sx={{
				outline: "none",
				fontWeight: "inherit",
				backgroundColor: "inherit",
				border: "none",
				cursor: "pointer",
				"&:focus": {
					backgroundColor: "#ffffff",
					borderBottom: "1px solid #ccc",
				},
				"&:hover": {
					cursor: "text",
				},
			}}
			type="text"
			value={valueTitle}
			spellCheck="false"
			onClick={selectAllInlineText}
			onChange={handleGalleryTitleChange}
			onBlur={handleGalleryTitleBlur}
			onKeyDown={(
				e: React.KeyboardEvent<HTMLInputElement> &
					React.FocusEvent<HTMLInputElement>,
			) => e.key === "Enter" && e.target.blur()}
			onMouseDown={(e: React.MouseEvent<HTMLInputElement>) =>
				e.preventDefault()
			}
		/>
	);
};

export default InputUpdate;
