/** @format */

import { useCallback, useEffect } from 'react';
import { useLayout } from './useLayout';

export const useDetectMultiTab = () => {
  const { setOpenModalGlobal } = useLayout();
  const generateUniqueId = useCallback(() => {
    return `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
  }, []);

  useEffect(() => {
    const channel = new BroadcastChannel('tab-detection');
    console.log('🚀 ~ useEffect ~ channel:', channel);
    const tabId = generateUniqueId();

    channel.postMessage({ tabId, type: 'opened' });
    channel.onmessage = event => {
      if (event.data.type === 'opened' && event.data.tabId !== tabId) {
        setOpenModalGlobal(true);
      }
    };
    return () => {
      channel.close();
    };
  }, []);
};
