/** @format */

import { Box } from '@mui/material';
import { bgNotFound } from 'assets';
import { TextCus } from 'components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import React from 'react';
import { maintenance } from 'assets';
import CountdownTimer from './CountdownTimer';
import { fontSize, mainColor } from 'theme';

interface MaintenanceProps {
  endTime: string;
}

const Maintenance: React.FC<MaintenanceProps> = ({ endTime }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      textAlign={'center'}
      height={'100vh'}
      color={`${mainColor.textMain}`}
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundImage: `url(${bgNotFound})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
      }}
    >
      <LazyLoadImage src={maintenance} alt='Maintenance' />
      <TextCus
        sx={{
          fontSize: `${fontSize['xxl']}`,
          fontWeight: '700',
          margin: '8px',
        }}
      >
        We’re under maintenance
      </TextCus>
      <TextCus
        style={{
          fontSize: `${fontSize.md}`,
          marginBottom: '24px',
        }}
      >
        Our website is down for maintenance. We will be back shortly.
      </TextCus>
      <Box bgcolor={mainColor.bgCountdownTimer} padding={'4px 40px'} borderRadius={'16px'}>
        <CountdownTimer targetDate={new Date(endTime)} />
      </Box>
    </Box>
  );
};

export default Maintenance;
