/** @format */

import React, { useRef, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'utils';
import { paymentService } from 'services';
import { fontSize, mainColor } from 'theme/default';
import { useProfile } from 'hooks';
import {
  CreateOrderActions,
  OnApproveData,
  OnApproveActions,
  CreateOrderData,
} from '@paypal/paypal-js';
import { ModalPaymentFail, ModalPaymentSuccess, TextCus } from 'components';
import { Icons } from 'assets';
import { STATUS_PAYMENT, TDetailBill } from 'types/payment';
import { PayPalButtons } from '@paypal/react-paypal-js';

const PlanInfoPayment = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const quantity = searchParams.get('quantity');
  const { getProfile } = useProfile();
  const prevRef = useRef({ orderID: '' }).current;

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GET_DETAIL_PLAN, id, quantity],
    queryFn: () => paymentService.detailPlan(id + ''),
  });
  const detailPlan = data;
  const [statusPayment, setStatusPayment] = useState<
    STATUS_PAYMENT.ANNUAL | STATUS_PAYMENT.SUCCESS | STATUS_PAYMENT.FAIL
  >(STATUS_PAYMENT.ANNUAL);

  const [detailBill, setDetailBill] = useState<null | TDetailBill>(null);

  const createOrder = async (data: CreateOrderData, actions: CreateOrderActions) => {
    console.log('========createOrder==========>', data, actions);
    try {
      const res = await paymentService.orderPaypal({
        reference_id: id + '',
        qty: quantity + '',
      });
      console.log('Res Create Order', res);
      prevRef.orderID = res?.id;
      return res?.id;
    } catch (error) {
      setStatusPayment(STATUS_PAYMENT.FAIL);
    }
  };

  const approve = async (data: OnApproveData, actions: OnApproveActions) => {
    console.log('=========approve=========>', data, actions);
    try {
      const res = await paymentService.approvePaypal({
        orderID: prevRef.orderID,
        quantity: quantity + '',
      });
      if (res) {
        if (res?.error) {
          return setStatusPayment(STATUS_PAYMENT.FAIL);
        }
        getProfile();
        setStatusPayment(STATUS_PAYMENT.SUCCESS);
        setDetailBill(res);
      }
    } catch (error) {
      setStatusPayment(STATUS_PAYMENT.FAIL);
    }
  };

  if (!detailPlan || !quantity || !id)
    return (
      <>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100vh'}>
          <CircularProgress />
        </Box>
      </>
    );
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'20px'}
      marginBottom={'20px'}
      flex={1}
      width={'100%'}
      paddingBottom={'100px'}
    >
      {isLoading ? (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100px'}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          zIndex={999}
          padding={'2px'}
          maxWidth={'600px'}
          width={'97%'}
          borderRadius={'20px'}
          sx={{
            background: mainColor.bgLinear,
          }}
        >
          <Box
            bgcolor={mainColor.primary}
            borderRadius={'20px'}
            padding={'20px'}
            flexDirection={'column'}
            display={'flex'}
            gap={'15px'}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              marginBottom={'10px'}
              color={mainColor.textMain}
            >
              <TextCus
                className='text_linear'
                fontSize={fontSize.xl}
                fontWeight={'700'}
                fontStyle={'italic'}
              >
                PLAN SUMMARY
              </TextCus>
            </Box>

            <Box
              justifyContent={'start'}
              gap={'15px'}
              alignItems={'center'}
              flexWrap={'wrap'}
              display={'flex'}
            >
              <Box display={'flex'} alignItems={'flex-end'} gap={'2px'}>
                <TextCus color={mainColor.secondary} fontSize={fontSize.xl} fontWeight={'700'}>
                  {'$' +
                    Math.round(detailPlan.priceSale * +quantity * (+quantity === 1 ? 1 : 0.85))}
                </TextCus>
                <TextCus color={mainColor.grey} fontSize={fontSize.sm}>
                  {+quantity === 1 ? '/month' : '/year'}
                </TextCus>
              </Box>
            </Box>

            <Box display={'flex'} flexDirection={'column'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                borderBottom={'1px solid #ccc'}
                paddingBottom={'10px'}
                marginBottom={'10px'}
                justifyContent={'space-between'}
              >
                <TextCus color={mainColor.grey} useI18n fontSize={fontSize.sm}>
                  Tax
                </TextCus>
                <TextCus color={mainColor.textMain} fontSize={fontSize.sm} fontWeight={'700'}>
                  $0.00
                </TextCus>
              </Box>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <TextCus color={mainColor.grey} useI18n fontSize={fontSize.md}>
                  Total due
                </TextCus>
                <TextCus color={mainColor.textMain} fontSize={fontSize.md} fontWeight={'700'}>
                  {'$' +
                    Math.round(detailPlan.priceSale * +quantity * (+quantity === 1 ? 1 : 0.85))}
                </TextCus>
              </Box>
            </Box>

            <Box
              component={'ul'}
              marginTop={'10px'}
              sx={{ listStyleType: 'none' }}
              display={'flex'}
              gap={'5px'}
              flexDirection={'column'}
            >
              <TextCus color={mainColor.secondary} fontSize={fontSize.md} fontWeight={'700'}>
                {detailPlan.packageName}
              </TextCus>
              <TextCus color={mainColor.grey} fontSize={fontSize.xs}>
                {detailPlan.subTitle}
              </TextCus>
              {detailPlan?.description?.map((item, index) => {
                return (
                  <Box
                    component={'li'}
                    display={'flex'}
                    mt={'5px'}
                    alignItems={'center'}
                    gap={'15px'}
                    color={mainColor.secondary}
                    fontSize={'14px'}
                    fontWeight={'400'}
                    textTransform={'capitalize'}
                    key={index}
                  >
                    {Icons.Tick()}
                    {item.toLocaleLowerCase()}
                  </Box>
                );
              })}
            </Box>

            <TextCus
              color={mainColor.grey}
              fontSize={'13px'}
              fontStyle={'italic'}
              maxWidth={'1000px'}
            >
              * By providing your payment information and clicking “Confirm Purchase/Upgrade Plan”,
              you agree you will be charged the amount listed above and your subscription will
              automatically renew untill you cancel (price subject to change). Cancel anytime via
              Account Settings or Customer Support. You also agree to the Terms of Service and
              Privacy Policy.
            </TextCus>

            <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
              <TextCus color={mainColor.textMain} fontStyle={'italic'} fontSize={fontSize.sm}>
                * Payment Method:
              </TextCus>
              <Box width={'100%'} margin={'0 auto'}>
                <PayPalButtons createOrder={createOrder} onApprove={approve} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {/* ======== MODALS STATUS ========= */}
      <ModalPaymentSuccess
        open={statusPayment === STATUS_PAYMENT.SUCCESS}
        onClose={() => {
          setDetailBill(null);
          setStatusPayment(STATUS_PAYMENT.ANNUAL);
        }}
        info={detailBill}
      />
      <ModalPaymentFail
        open={statusPayment === STATUS_PAYMENT.FAIL}
        onClose={() => {
          setStatusPayment(STATUS_PAYMENT.ANNUAL);
        }}
      />
      {/* ======== END MODALS STATUS ========= */}
    </Box>
  );
};

export default PlanInfoPayment;
