/** @format */

import { Box, CircularProgress } from '@mui/material';
import { Icons } from 'assets';
import { TextCus } from 'components/TextCus';
import dayjs from 'dayjs';
import { useWindowDimensions } from 'hooks';
import { useDownloadImage } from 'hooks/useDownloadImage';
import { useGenerate } from 'hooks/useGenerate';
import * as React from 'react';
import { fontSize, mainColor } from 'theme';

interface CardRecent {
  title?: string,
  createdAt?: string,
  url?: string,
  to?: string,
  setStateVideoModal: (item: any) => void
  item: any,
  setOpenVideo: React.Dispatch<React.SetStateAction<boolean>>,
  refetch: () => void
}


export const CardRecent: React.FC<CardRecent> = ({ title, item, createdAt, url, setStateVideoModal, setOpenVideo, refetch }) => {
  /*HOOKS*/
  const { countViews, deleteProject } = useGenerate();
  const { handleDownLoadImg, isLoadingDlImg } = useDownloadImage();


  const handelClickVideo = (videoId: string) => {
    countViews.mutate(videoId)
    setOpenVideo(true)
    setStateVideoModal(item)
  }

  const refVideo = React.useRef<HTMLVideoElement>(null);
  React.useEffect(() => {
    if (refVideo?.current) {
      refVideo?.current.load()
    }
  }, [url])

  const [amount, setAmount] = React.useState(5)
  const widthGlobal = useWindowDimensions()?.width
  React.useEffect(() => {
    if (widthGlobal >= 1440) {
      setAmount(5)
    }
    if (widthGlobal <= 1440) {
      setAmount(4)
    }
    if (widthGlobal <= 1024) {
      setAmount(3)
    }
    if (widthGlobal <= 768) {
      setAmount(2)
    }
    if (widthGlobal <= 426) {
      setAmount(1)
    }
  }, [widthGlobal])


  //HANDLE DELETED 
  const handleDelete = async (id: string) => {
    deleteProject.mutate(id, {
      onSuccess: async (data: any) => {
        if (data?.statusCode === 200) {
          refetch()
          return
        }
      },
    })
  }

  return (
    <Box

      mt={'10px'}
      marginBottom={'25px'}
      width={`calc((100% - 45px) / ${amount})`}
      sx={{
        ".layout1": { opacity: 0 },
        '&:hover': {
          ".layout1": {
            opacity: 1,
            transition: "all 0.3s ease-in-out",
          },
        },
        objectFit: 'cover',
      }}
    >
      <Box

        className="layout"
        position={'relative'}
        display={'flex'}
      >
        <video
          // width={`${widthGlobal === 768 ? "240px" : '350px'}`}
          onClick={() => { handelClickVideo(item?.id) }}
          ref={refVideo}
          key={url}
          style={{
            width: '100%',
            height: '218px',
            objectFit: 'cover',
            borderRadius: '12px',
            // cursor: 'pointer',
          }}
          onMouseEnter={(e) => {
            const videoElement = e?.currentTarget as HTMLVideoElement;
            if (videoElement && videoElement.paused) {
              videoElement.muted = true;
              videoElement.play().catch((error) => {
                console.error("Error playing video:", error);
              });
            }
          }}
          onMouseLeave={(e) => {
            const videoElement = e?.currentTarget as HTMLVideoElement;
            if (videoElement && !videoElement.paused) {
              videoElement.pause();
              videoElement.muted = false;
            }
          }}
          loop
        // muted={false}
        >
          <source src={url} type="video/mp4" />
        </video>


        <Box
          className="layout1"
          position={'absolute'}
          right={'0'} mt={'20px'}
          display={'flex'}
          justifyContent={'end'}>
          <Box className="hover-icon" onClick={() => handleDownLoadImg(url, `${url?.split("/").pop()}`)}>
            {isLoadingDlImg ? (
              <CircularProgress size={15} />
            ) : (
              Icons.IconDownLoad()
            )}

          </Box>
          <Box className="hover-icon" mx={'15px'} onClick={() => { handleDelete(item?.id) }}> {Icons.IconRemove()}</Box>
        </Box>

        <Box
          className="layout1"
          bottom={'0'}
          right={'0'}
          position={'absolute'}
          // onClick={() => { handelClickVideo(item?.id) }}
          mb={'10px'}
          display={'flex'}
          justifyContent={'end'}>
          <Box mx={'10px'} className="hover-icon-view">{Icons.IconView()}</Box>
        </Box>

      </Box>
      <Box
        pt={'10px'}
        px={'15px'}
        display={'flex'}
        justifyContent={'start'}
        flexDirection={'column'}
        // alignItems={'center'}
        width={'100%'}
      >
        <TextCus
          color={mainColor.textMain}
          fontSize={`${fontSize.xs}`}
          fontWeight={'bold'}
          className='multiline-ellipsis'
        >
          {title}
        </TextCus>
        <TextCus
          color={mainColor.textD3}
          fontSize={`${fontSize.s}`}
        >
          {dayjs(createdAt).format("HH:mm:ss YYYY/MM/DD")}
        </TextCus>
      </Box>
    </Box >

  );
}


