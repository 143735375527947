import React, { useEffect, useState } from "react";
import { Box, Checkbox, Fade, IconButton, Modal } from "@mui/material";
import { mainColor } from "theme/default";
import { ButtonCus, TextCus } from "components";
import { CloseOutlined } from "@mui/icons-material";
import { KEY_CONTEXT, dataClearImg } from "utils";
import { useKey } from "hooks";

interface IModalPhotoRequirement {
	open: boolean;
	onClose: () => void;
	setValueChecked?: any
	setOpenBrowse?: any
	valueChecked?: any
}

const ModalPhotoRequirement = ({ open, onClose, setOpenBrowse }: IModalPhotoRequirement) => {

	const [valueChecked, setValueChecked] = useState(false);

	const { setKey } = useKey();

	useEffect(() => {
		if (valueChecked) {
			setKey(KEY_CONTEXT.IS_VALUES_CHECKED, 'true')
		} else { setKey(KEY_CONTEXT.IS_VALUES_CHECKED, 'false') }
	}, [valueChecked])

	const handleChange = (e) => {
		setValueChecked(e.target.checked)
	}

	const handleChoosePhoto = () => {
		setOpenBrowse(true);
		onClose();
	};
	return (
		<Modal
			onClose={onClose}
			keepMounted
			open={open}
			sx={{
				backgroundColor: "rgba(0, 0, 0, 0.5)",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					position={"relative"}
					justifyContent={"center"}
					gap={"20px"}
					padding={"20px 20px"}
					flexDirection={"column"}
					height={"auto"}
					sx={{

					}}
					borderRadius={"10px"}
					bgcolor={"rgba(79, 76, 76, 0.821)"}>
					<Box display={'flex'} justifyContent={"space-between"} width={'100%'}>
						<TextCus
							ml={3}
							color={mainColor.textMain}
							fontSize={`18px`}
							fontWeight={600}
						>Subject Photo Requirement</TextCus>
						<IconButton
							sx={{ position: "absolute", top: 0, right: 0 }}
							onClick={() => {
								window.location.reload();
								onClose();
							}}>
							<CloseOutlined sx={{ color: `#ffffff` }} />
						</IconButton>
					</Box>
					<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
						{dataClearImg.map((item) => (
							<Box key={item.id}
								bgcolor={"rgba(0, 0, 0, 0.3)"}
								width={'284px'}
								height={'284px'}
								mx={3}
								borderRadius={'12px'}
								display={'flex'}
								flexDirection={'column'}
								alignItems={'center'}
								justifyContent={'center'}
							>
								<TextCus
									color={mainColor.textMain}
									fontSize={`16px`}
									fontWeight={'500'}
									py={2}
								>Clean Background</TextCus>

								<img src={item?.srcImg} alt='img-clear' width={'85%'} />

								<Box p={2}>
									<TextCus
										color={mainColor.textMain}
										fontSize={`14px`}
										fontWeight={'500'}
										textAlign={'center'}
									>{item?.title}</TextCus>
									{item?.subTitle &&
										<TextCus
											color={mainColor.textGray}
											fontSize={`14px`}
											fontWeight={'500'}
											textAlign={'center'}
										>{item?.subTitle}</TextCus>}
								</Box>

							</Box>
						))}
					</Box>
					<Box width={'100%'} display={'flex'} justifyContent={'start'} alignItems={'center'} ml={3}>
						<Checkbox
							onChange={handleChange}
							sx={{
								color: `#ffffff`,
								'&.Mui-checked': {
									color: `#ffffff`,
								},
							}}
						/>
						<TextCus
							color={mainColor.textMain}
							fontSize={`14px`}
							fontWeight={'600'}
							textAlign={'center'}
						>Don’t show again</TextCus>
					</Box>

					<Box width={'100%'} display={'flex'} justifyContent={'center'} py={'25px'}>
						<ButtonCus
							onClick={handleChoosePhoto}
							sx={{
								padding: "0px !important",
								marginBottom: `5px`,
								borderRadius: '12px',
								width: '303px',
								height: '40px',
								background: "linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)"
							}}
						>
							<TextCus
								color={mainColor.textMain}
								fontSize={`16px`}
								paddingX={1}
								fontWeight={600}
							>
								Choose Object Photo
							</TextCus>
						</ButtonCus>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalPhotoRequirement;
