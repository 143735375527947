/** @format */

import React from 'react';
import { mainColor } from 'theme';
import { Box } from '@mui/material';

interface BoxLayoutProps {
  children: React.ReactNode;
  sx?: any
}

export const BoxLayout: React.FC<BoxLayoutProps> = ({ children, ...sx }) => {
  return (
    <Box
      marginY={'50px'}
      p={'25px'}
      width={'70%'}
      height={'auto'}
      display='flex'
      flexDirection='column'
      // justifyContent={'space-between'}
      // alignItems={'center'}
      component={'div'}
      bgcolor={mainColor.primary}
      sx={{
        border: `2px dashed ${mainColor.borderColor3}`,
        borderRadius: '12px',
        boxShadow: ` 0 4px 20px rgba(135,207,254,0.7)`,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
