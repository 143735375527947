/** @format */

export type TLogin = {
  email: string;
  password: string;
};

export type TLoginSocial = {
  idToken: string;
};

export type TRegister = {
  userName: string;
  email: string;
  password: string;
  repassword: string;
};

export interface TForgotPassword {
  email?: string;
  uid?: string;
  otp?: string;
  newPassword?: string;
  repassword?: string;
}

export interface IInputFX {
  type: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  value: string;
  setValue?: (newValue: string) => void;
  formData?: TForgotPassword;
  setFormData?: (data: TForgotPassword) => void;
  errors?: TForgotPassword;
  setErrors?: (error: TForgotPassword) => void;
}

export interface IUser {
  avatar: string;
  credits: number;
  email: string;
  id: string;
  plan: string;
  totalBill: string;
  userName: string;
  startDate: string;
  typeLogin?: string;
}

export interface IContextState {
  profile: any;
}

export enum IUserActionsType {
  UPDATE_PARAMS = 'UPDATE_PARAMS',
}

export interface AuthContextType {
  isLogged: boolean;
  login: () => void;
  logout: () => void;
}

export enum EModelAuth {
  LOGIN = 'login',
  REGISTER = 'register',
  DEFAULT = '',
}

export interface UpdateProfileForm {
  userName: string;
  avatar?: File;
}
export type FormUpdatePassword = {
  oldPassword: string;
  newPassword: string;
};

export enum TypeLogin {
  SYSTEM = "SYSTEM",
  GOOGLE = "GOOGLE",
}
