/** @format */

import { Box } from '@mui/material';
import { bgNotFound } from 'assets';
import { Header } from 'pages/notFound/components';
import React from 'react';
import { mainColor } from 'theme';

interface NotFoundLayoutProps {
  children: React.ReactNode;
}

export const NotFoundLayout = ({ children }: NotFoundLayoutProps) => {
  return (
    <Box
      height={'auto'}
      width={'100%'}
      display='flex'
      flexDirection='column'
      component={'div'}
      justifyContent={'space-between'}
      alignItems={'center'}
      bgcolor={mainColor.primary}
      paddingX={'25px'}
      paddingY={'15px'}
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundImage: `url(${bgNotFound})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
      }}
    >
      <Header />
      {children}
    </Box>
  );
};
