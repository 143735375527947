/** @format */

import { Box, Grid } from '@mui/material';
import { bgAuthen, logoAuth } from 'assets';
import { TextCus } from 'components/TextCus';
import React from 'react';
import { fontSize, mainColor } from 'theme';

interface AuthLayoutProps {
  children: React.ReactNode;
}

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <Box bgcolor={mainColor.primary} minHeight={'100vh'}>
      <Box
        width={'100%'}
        height={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box width={'100%'} height={'100%'} component={'main'}>
          <Grid container overflow={'hidden'}>
            {/* ============== MAIN CONTENT ================ */}
            <Grid
              item
              xs={12}
              md={9}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
              gap={'20px'}
              sx={{
                overflowY: 'auto',
                overflowX: 'hidden',
                backgroundImage: `url(${bgAuthen})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
              }}
            >
              <img src={logoAuth} alt='Logo auth' />
              <TextCus color={mainColor.textMain} fontSize={fontSize.xl} fontWeight={'500'}>
                Maximize Your Business Growth
              </TextCus>
            </Grid>
            {/* ============== END MAIN CONTENT ================ */}
            {/* ============== CONTENT AUTH ================ */}
            <Grid item xs={12} md={3}>
              {children}
            </Grid>
            {/* ============== CONTENT AUTH ================ */}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
