/** @format */

import { Box } from '@mui/material';
import { Icons } from 'assets';
import { TextCus } from 'components';
import React from 'react';
import { fontSize, mainColor } from 'theme';

interface ButtonNavigateProps {
  title: string;
  action: () => void;
}

const ButtonNavigate = ({ title, action }: ButtonNavigateProps) => {
  return (
    <Box
      display={'flex'}
      sx={{ cursor: 'pointer', background: 'rgba(255, 255, 255, 0.15)' }}
      borderRadius={'12px'}
      padding={'6px 12px'}
      component={'div'}
      onClick={action}
      gap={'15px'}
      alignItems={'center'}
    >
      <TextCus fontSize={fontSize.s} fontWeight={'400'} color={mainColor.textMain}>
        {title}
      </TextCus>
      {Icons.RightIcon()}
    </Box>
  );
};

export default ButtonNavigate;
