/** @format */

import { Box } from '@mui/material';
import { bgPlan } from 'assets';
import { Header } from 'pages/plan/components';
import { Header as HeaderTransaction } from 'pages/transaction/components';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { mainColor } from 'theme';
import { APP_ROOT } from 'utils/routes';

interface PaymentLayoutProps {
  children: React.ReactNode;
}

export const PaymentLayout = ({ children }: PaymentLayoutProps) => {
  const location = useLocation();

  return (
    <Box
      height={'auto'}
      width={'100%'}
      display='flex'
      flexDirection='column'
      component={'div'}
      justifyContent={'space-between'}
      alignItems={'center'}
      bgcolor={mainColor.primary}
      paddingX={'25px'}
      paddingY={'15px'}
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundImage: `url(${bgPlan})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
      }}
    >
      {location.pathname === APP_ROOT.TRANSACTION ? <HeaderTransaction /> : <Header />}
      {children}
    </Box>
  );
};
