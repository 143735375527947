/** @format */

import React from 'react';
import { Box } from '@mui/material';
import { iconDiamond } from 'assets';
import { TextCus } from 'components/TextCus';
import { useUser } from 'hooks';
import { fontSize, mainColor } from 'theme';

const BoxCredit = () => {
  const { user } = useUser();
  return (
    <Box display={'flex'} alignItems={'center'} gap={'10px'}>
      <img width={20} src={iconDiamond} alt='kc' />
      <TextCus color={mainColor.textMain} fontSize={fontSize.sm} fontWeight={'bold'}>
        {(user?.credits || 0) + ''}
      </TextCus>
    </Box>
  );
};

export default BoxCredit;
