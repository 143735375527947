/** @format */

import React from 'react';
import { Box, Modal } from '@mui/material';
import { fontSize, mainColor } from 'theme/default';
import { useNavigate } from 'react-router-dom';
import { ButtonCus, TextCus } from 'components';
import { warning } from 'assets';

interface IModalFail {
  open: boolean;
  onClose: () => void;
}

const ModalFail = ({ open, onClose }: IModalFail) => {
  const navigate = useNavigate();
  return (
    <Modal
      keepMounted
      open={open}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 9999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      aria-labelledby='keep-mounted-modal-title'
      aria-describedby='keep-mounted-modal-description'
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        padding={'20px 10px'}
        flexDirection={'column'}
        height={'300px'}
        sx={{
          width: {
            xs: '90%',
            sm: '400px',
          },
        }}
        borderRadius={'10px'}
        bgcolor={mainColor.textMain}
      >
        <TextCus fontWeight={'600'} fontSize={fontSize.md}>
          Payment Unsuccessful
        </TextCus>

        <img src={warning} alt='Warning' />
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <TextCus fontWeight={'500'} fontSize={fontSize.xs} textAlign={'center'}>
            Sorry for any inconvenience caused.
          </TextCus>
          <TextCus fontSize={fontSize.xs} color={mainColor.warning} textAlign={'center'}>
            Error message: Transaction has been cancel.
          </TextCus>
        </Box>
        <ButtonCus
          onClick={() => {
            onClose();
            navigate('/pricing-plan');
          }}
          title=' Back to Plan'
        />
      </Box>
    </Modal>
  );
};

export default ModalFail;
