/** @format */

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { TextCus } from 'components/TextCus';
import { fontSize, mainColor } from 'theme';

dayjs.extend(duration);

// Function to calculate time difference
const calculateTimeLeft = (targetDate: dayjs.Dayjs) => {
  const now = dayjs();

  const difference = targetDate.diff(now);

  let timeLeft = {
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
  };

  if (difference > 0) {
    const monthsLeft = targetDate.diff(now, 'months');
    const daysLeft = targetDate.diff(now, 'days') % 30; // Approximate days calculation
    const hoursLeft = targetDate.diff(now, 'hours') % 24;
    const minutesLeft = targetDate.diff(now, 'minutes') % 60;

    timeLeft = {
      months: monthsLeft,
      days: daysLeft,
      hours: hoursLeft,
      minutes: minutesLeft,
    };
  }

  return timeLeft;
};

const CountdownTimer: React.FC<{ targetDate: Date }> = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(dayjs(targetDate)));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(dayjs(targetDate)));
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div style={containerStyle}>
      {/* Time values */}
      <div>
        <div style={timeSectionStyle}>{timeLeft.months}</div>
        <TextCus style={timeSectionTextStyle}>Months</TextCus>
      </div>
      <div style={colonStyle}>:</div>
      <div>
        <div style={timeSectionStyle}>{timeLeft.days}</div>
        <TextCus style={timeSectionTextStyle}>Days</TextCus>
      </div>
      <div style={colonStyle}>:</div>
      <div>
        <div style={timeSectionStyle}>{timeLeft.hours}</div>
        <TextCus style={timeSectionTextStyle}>Hours</TextCus>
      </div>
      <div style={colonStyle}>:</div>
      <div>
        <div style={timeSectionStyle}>{timeLeft.minutes}</div>
        <TextCus style={timeSectionTextStyle}>Mins</TextCus>
      </div>
    </div>
  );
};

// Basic styles
const containerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: `${fontSize.xxl}`,
};

const timeSectionStyle: React.CSSProperties = {
  margin: '0 10px',
  fontWeight: 'bold',
  color: `${mainColor.textMain}`,
};

const timeSectionTextStyle: React.CSSProperties = {
  margin: '0 10px',
  fontSize: `${fontSize.s}`,
  color: `${mainColor.textMain}`,
};

const colonStyle: React.CSSProperties = {
  fontWeight: 'bold',
  fontSize: `${fontSize.xxl}`,
  margin: '0 16px',
  color: `${mainColor.textMain}`,
  fontFamily: 'Montserrat',
};

export default CountdownTimer;
