/** @format */

import React from 'react';
import { Box } from '@mui/material';
import { fontSize, mainColor } from 'theme/default';
import { TextCus } from 'components';

interface IItemMenu {
  isMgTop?: boolean;
  title: string;
  icon: React.ReactNode;
  onAction: () => void;
}

const ItemMenu = ({ onAction, icon, title, isMgTop = false }: IItemMenu) => {
  return (
    <Box
      marginTop={isMgTop ? '10px' : '0px'}
      component={'div'}
      onClick={onAction}
      paddingY={'10px'}
      sx={{
        cursor: 'pointer',
        transition: 'all 0.25s ease-in-out',
        ':hover': {
          background: '#1b1b1bb0',
        },
      }}
      borderRadius={'10px'}
      display={'flex'}
      paddingLeft={'10px'}
      alignItems={'center'}
      gap={'20px'}
    >
      {icon}
      <TextCus fontSize={fontSize.sm} color={mainColor.textMain}>
        {title}
      </TextCus>
    </Box>
  );
};

export default ItemMenu;
