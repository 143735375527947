/** @format */

import { ENotification, IMaintain } from 'types';
import { END_POINT } from 'utils';
import axiosClient from './axios';

const getRequest = (url: string) => axiosClient.get(url);

export const notificationService = {
  //Get Maintain status
  getMaintain: async (): Promise<IMaintain> => {
    const url = `${END_POINT.NOTIFICATIONS.GET_NOTIFICATIONS}?type=${ENotification.MAINTAIN}`;
    const response = await getRequest(url);
    return response.data as IMaintain;
  },
};
