/** @format */

import { FC } from 'react';
import { MainLayout, AuthLayout, PaymentLayout, NotFoundLayout } from 'components';
import HomePage from 'pages/main/MainPage';
import { TextToVideoPage } from 'pages/textToVideo';
import { ImageToVideoPage } from 'pages/imageToVideo';
import { ChangingBackground } from 'pages/changingBackground';
import { Login } from 'pages/auth/login';
import { Register } from 'pages/auth/register';
import { APP_ROOT, PUBLIC_URL } from 'utils/routes';
import { VerifyOtp } from 'pages/auth/verifyOtp';
import { ForgotPass } from 'pages/auth/forgotPass';
import { ResetPass } from 'pages/auth/resetPass';
import { Plan } from 'pages/plan';
import { PlanInfoPayment } from 'pages/plan/inforPayment';
import { Transaction } from 'pages/transaction';
import { NotFound } from 'pages/notFound';
import { PlanStandard } from 'pages/planStandard';

type TComponent = FC<{ children: string | JSX.Element | JSX.Element[] | JSX.Element }>;

export interface RouterType {
  path: string;
  component: FC;
  layout: TComponent;
}

const publicRoutes: RouterType[] = [
  {
    path: PUBLIC_URL.LOGIN,
    component: Login,
    layout: AuthLayout as TComponent,
  },
  {
    path: PUBLIC_URL.SIGNUP,
    component: Register,
    layout: AuthLayout as TComponent,
  },
  {
    path: PUBLIC_URL.VERIFY_OTP,
    component: VerifyOtp,
    layout: AuthLayout as TComponent,
  },
  {
    path: PUBLIC_URL.FORGOT_PASSWORD,
    component: ForgotPass,
    layout: AuthLayout as TComponent,
  },
  {
    path: PUBLIC_URL.RESET_PASSWORD,
    component: ResetPass,
    layout: AuthLayout as TComponent,
  },
  {
    path: PUBLIC_URL.NOT_FOUND,
    component: NotFound,
    layout: NotFoundLayout as TComponent,
  },
];

const privateRoutes: RouterType[] = [
  {
    path: PUBLIC_URL.HOME,
    component: HomePage,
    layout: MainLayout as TComponent,
  },
  {
    path: APP_ROOT.TEXT_2_VIDEO,
    component: TextToVideoPage,
    layout: MainLayout as TComponent,
  },
  {
    path: APP_ROOT.IMAGE_2_VIDEO,
    component: ImageToVideoPage,
    layout: MainLayout as TComponent,
  },
  {
    path: APP_ROOT.CHANGE_BACKGROUND,
    component: ChangingBackground,
    layout: MainLayout as TComponent,
  },
  {
    path: APP_ROOT.PLAN,
    component: Plan,
    layout: PaymentLayout as TComponent,
  },
  {
    path: APP_ROOT.PLAN_STANDARD,
    component: PlanStandard,
    layout: PaymentLayout as TComponent,
  },
  {
    path: APP_ROOT.INFO_PAYMENT,
    component: PlanInfoPayment,
    layout: PaymentLayout as TComponent,
  },
  {
    path: APP_ROOT.TRANSACTION,
    component: Transaction,
    layout: PaymentLayout as TComponent,
  },
];

export { privateRoutes, publicRoutes };
