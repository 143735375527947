/** @format */

import { EModelAuth } from 'types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type State = {
  openModalGlobal: boolean;
  loadingGlobal: boolean;
  loadingImage2Video: boolean;
  loadingText2Video: boolean;
  idTrackingImage: string;
  idTrackingText: string;
  openModalCreateCharacter: boolean;
  openModalAuth: EModelAuth;
  loadingChangingB: boolean;
  idTrackingChangingB: string;
  loadingGenerateB: boolean;
  idTrackingGenerateB: string;
  currentBGround: any;
  promptInputGlobal: string;
};

type Actions = {
  setOpenModalGlobal: (v: boolean) => void;
  setLoadingGlobal: (v: boolean) => void;
  setOpenModalCreateCharacter: (v: boolean) => void;
  setOpenModalAuth: (v: EModelAuth) => void;
  setLoadingImage2Video: (v: boolean) => void;
  setloadingText2Video: (v: boolean) => void;
  setIdTrackingImage: (v: string) => void;
  setIdTrackingText: (v: string) => void;
  setLoadingChangingB: (v: boolean) => void;
  setIdTrackingChangingB: (v: string) => void;
  setLoadingGenerateB: (v: boolean) => void;
  setIdTrackingGenerateB: (v: string) => void;
  setCurrentBGround: (v: any) => void;
  setPromtInputGlobal: (v: string) => void;
};

const initialState: State = {
  openModalGlobal: false,
  loadingGlobal: false,
  openModalCreateCharacter: false,
  openModalAuth: EModelAuth.DEFAULT,
  loadingImage2Video: false,
  loadingText2Video: false,
  idTrackingImage: '',
  idTrackingText: '',
  loadingChangingB: false,
  idTrackingChangingB: '',
  loadingGenerateB: false,
  idTrackingGenerateB: '',
  currentBGround: {},
  promptInputGlobal: '',
};

export const useLayout = create<State & Actions>()(
  devtools(
    set => ({
      ...initialState,
      setOpenModalGlobal: v => set({ openModalGlobal: v }),
      setLoadingGlobal: v => set({ loadingGlobal: v }),
      setOpenModalCreateCharacter: v => set({ openModalCreateCharacter: v }),
      setOpenModalAuth: v => set({ openModalAuth: v }),
      setLoadingImage2Video: v => set({ loadingImage2Video: v }),
      setloadingText2Video: v => set({ loadingText2Video: v }),
      setIdTrackingImage: v => set({ idTrackingImage: v }),
      setIdTrackingText: v => set({ idTrackingText: v }),
      setLoadingChangingB: v => set({ loadingChangingB: v }),
      setIdTrackingChangingB: v => set({ idTrackingChangingB: v }),
      setLoadingGenerateB: v => set({ loadingGenerateB: v }),
      setIdTrackingGenerateB: v => set({ idTrackingGenerateB: v }),
      setCurrentBGround: v => set({ currentBGround: v }),
      setPromtInputGlobal: v => set({ promptInputGlobal: v }),
    }),
    {
      name: 'LayoutStore',
    },
  ),
);
