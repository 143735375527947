/** @format */

import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { BoxCredit, TextCus } from 'components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { fontSize, mainColor } from 'theme';

const Header = () => {
  const navigate = useNavigate();
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      width={'100%'}
      m={'0 auto'}
    >
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={'10px'}>
        <IconButton
          onClick={() => navigate(-1)}
          sx={{
            width: '64px',
            height: '48px',
            bgcolor: 'rgba(27, 27, 27, 0.7)',
            borderRadius: '12px',
          }}
        >
          <ArrowBack sx={{ color: 'white' }} />
        </IconButton>
        <TextCus color={mainColor.textMain} fontWeight={'600'} fontSize={fontSize.sm}>
          PRICING
        </TextCus>
      </Box>
      <BoxCredit />
    </Box>
  );
};

export default Header;
