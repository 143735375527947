/** @format */

import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { generateService } from 'services';
import { TParams } from 'types';
import {
  IChangeBackgroundParams,
  IChangingBackgroundParams,
  IImages2VideoParams,
} from 'types/generate';
import { QueryKeys } from 'utils';
import SnackbarUtils from 'utils/SnackbarUtils';

export const useGenerate = () => {
  /////* Get List recent by user*////
  const useGetRecent = (params: TParams) => {
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch, isLoading, isError } =
      useInfiniteQuery({
        queryKey: [QueryKeys.GET_RECENT, params.page, params.limit, params.type],
        queryFn: ({ pageParam = params.page || 1 }) =>
          generateService.getRecent({ ...params, page: pageParam }),
        getNextPageParam: lastPage => {
          const params = lastPage.pagination;
          if (params) {
            return params.page < params.totalPage ? params.page + 1 : undefined;
          }
          return undefined;
        },

        initialPageParam: params.page || 1,
      });

    const recents = data?.pages.flatMap(page => page.data) ?? [];

    return {
      recents,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      refetch,
      isLoading,
      isError,
    };
  };

  /////* tracking req by user*////
  const taskTrackingMutation = useMutation({
    mutationFn: (id: string) => generateService.taskTracking(id),
    onSuccess: async (data: any) => {
      if (data?.statusCode === 200) {
        console.log('Data', data?.data);
        return;
      }
      SnackbarUtils.warning(data?.data || 'Tracking video failed');
    },
    onError: () => {
      SnackbarUtils.warning('Tracking failed!');
    },
  });

  /////*Generate Text 2 Video by user*////
  const text2VideoMutation = useMutation({
    mutationFn: (prompt: string) => generateService.text2Video(prompt),
    onSuccess: async (data: any) => {
      console.log('Data', data?.data);
      if (data?.statusCode === 200) {
        console.log('answer', data?.data);
        SnackbarUtils.success(data?.message || 'Generate text 2 video success');
        return;
      }
      SnackbarUtils.warning(data?.data || 'Generate text 2 video failed');
    },
    onError: () => {
      SnackbarUtils.warning('Delete failed!');
    },
  });

  /////*Generate Images 2 Video by user*////
  const images2VideoMutation = useMutation({
    mutationFn: (form: IImages2VideoParams) => generateService.images2Video(form),
    onSuccess: async (data: any) => {
      console.log('Data', data?.data);
      if (data?.statusCode === 200) {
        console.log('answer', data?.data);
        SnackbarUtils.success(data?.message || 'Generate images to video success');
        return;
      }
      SnackbarUtils.warning(data?.data || 'Generate images to video failed');
    },
    onError: () => {
      SnackbarUtils.warning('Generate images failed!');
    },
  });

  /////* Get List prompt by user*////
  const useGetPrompt = (params: TParams) => {
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch, isLoading, isError } =
      useInfiniteQuery({
        queryKey: [QueryKeys.GET_PROMPT, params.page, params.type],
        queryFn: ({ pageParam = params.page || 1 }) =>
          generateService.getPromtp({ ...params, page: pageParam }),
        getNextPageParam: lastPage => {
          const params = lastPage.pagination;
          if (params) {
            return params.page < params.totalPage ? params.page + 1 : undefined;
          }
          return undefined;
        },

        initialPageParam: params.page || 1,
      });
    // console.log('data recent =========', data?.pages);
    const prompts = data?.pages.flatMap(page => page) ?? [];

    return {
      prompts,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      refetch,
      isLoading,
      isError,
    };
  };

  /////* Get List Community*////
  const useGetCommunity = (params: TParams) => {
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch, isLoading, isError } =
      useInfiniteQuery({
        queryKey: [QueryKeys.GET_COMMUNITY, params.page, params.type],
        queryFn: ({ pageParam = params.page || 1 }) =>
          generateService.getCommunity({ ...params, page: pageParam }),
        getNextPageParam: lastPage => {
          const params = lastPage.pagination;
          if (params) {
            return params.page < params.totalPage ? params.page + 1 : undefined;
          }
          return undefined;
        },

        initialPageParam: params.page || 1,
      });
    // console.log('data recent =========', data?.pages);
    const communities = data?.pages.flatMap(page => page) ?? [];

    return {
      communities,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      refetch,
      isLoadingComunity: isLoading,
      isError,
    };
  };

  /////*count views Video *////
  const countViews = useMutation({
    mutationFn: (videoId: string) => generateService.countViews(videoId),
  });

  /////* tracking req AI *////
  const trackingAIMutation = useMutation({
    mutationFn: (id: string) => generateService.trackingAi(id),
    onSuccess: async (data: any) => {
      if (data?.statusCode === 200) {
        console.log('Data', data?.data);
        return;
      }
      // SnackbarUtils.warning(data?.data || 'Tracking video failed');
    },
    // onError: () => {
    //   SnackbarUtils.warning('Tracking failed!');
    // },
  });

  /////*Background Change*////
  const ChangingBackgroundMutation = useMutation({
    mutationFn: (form: IChangingBackgroundParams) => generateService.changingBackground(form),
    onSuccess: async (data: any) => {
      console.log('Data', data?.data);
      if (data?.statusCode === 200) {
        console.log('answer', data?.data);
        SnackbarUtils.success('Background Change successfully');
        return;
      }
      SnackbarUtils.warning(data?.data || 'Generate images to video failed');
    },
    onError: () => {
      SnackbarUtils.warning('Generate images failed!');
    },
  });

  /////*Generate Background*////
  const generateBackgroundMutation = useMutation({
    mutationFn: (prompt: string) => generateService.generateBackground(prompt),
    onSuccess: async (data: any) => {
      if (data?.statusCode === 200) {
        SnackbarUtils.success('Generate Background success');
        return;
      }
      SnackbarUtils.warning(data?.data || 'Generate Background failed');
    },
    onError: () => {
      SnackbarUtils.warning('Generate Background failed!');
    },
  });

  /////*count views Video *////
  const deleteProject = useMutation({
    mutationFn: (id: string) => generateService.deleteProject(id),
    onSuccess: async (data: any) => {
      console.log('🚀 ~ onSuccess: ~ data:', data);
      if (data?.statusCode === 200) {
        SnackbarUtils.success('Delete project successfully');
        return;
      }
      SnackbarUtils.warning(data?.data || 'Delete failed');
    },
  });

  /////*Background Change*////
  const ChangeBackgroundMutation = useMutation({
    mutationFn: (form: IChangeBackgroundParams) => generateService.changeBackground(form),
    onSuccess: async (data: any) => {
      console.log('Data', data?.data);
      if (data?.statusCode === 200) {
        console.log('answer', data?.data);
        SnackbarUtils.success('Background Change successfully');
        return;
      }
      SnackbarUtils.warning(data?.data || 'Background Change failed');
    },
    onError: () => {
      SnackbarUtils.warning('Background Change failed!');
    },
  });

  return {
    useGetRecent,
    text2VideoMutation,
    taskTrackingMutation,
    images2VideoMutation,
    useGetPrompt,
    useGetCommunity,
    countViews,
    ChangingBackgroundMutation,
    trackingAIMutation,
    generateBackgroundMutation,
    deleteProject,
    ChangeBackgroundMutation,
  };
};
