/** @format */

import React from 'react';

import { TextCus } from 'components/TextCus';
import { fontSize, mainColor } from 'theme/default';

import { Box, Button, Modal } from '@mui/material';
import { useLayout } from 'hooks';

const ModalGlobal = () => {
  const { openModalGlobal, setOpenModalGlobal } = useLayout();
  return (
    <Modal
      keepMounted
      open={openModalGlobal}
      sx={{
        backgroundColor: '#00000087',
        zIndex: 9999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClose={setOpenModalGlobal}
      aria-labelledby='keep-mounted-modal-title'
      aria-describedby='keep-mounted-modal-description'
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'end'}
        border={'1px solid #fff'}
        borderRadius={'10px'}
        flexDirection={'column'}
        width={'360px'}
        sx={{ outline: 'none' }}
        bgcolor={mainColor.textMain}
      >
        <TextCus
          sx={{
            borderBottom: '1px solid #ccc',
            width: '100%',
            height: '50px',
            padding: '0 10px',
            lineHeight: '50px',
            fontSize: fontSize.sm,
            fontWeight: '700px',
            color: 'black',
          }}
          id='keep-mounted-modal-title'
        >
          You are opening StyleGen on another tab
        </TextCus>
        <TextCus
          id='keep-mounted-modal-description'
          sx={{
            width: '100%',
            height: '50px',
            padding: '0 10px',
            lineHeight: '50px',
            fontSize: fontSize.xs,
            fontWeight: '700px',
            color: 'black',
          }}
        >
          Click Activate to use on this tab
        </TextCus>
        <Button
          onClick={() => window.location.reload()}
          sx={{ padding: '10px 20px', margin: '10px', fontWeight: '700' }}
        >
          Activate
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalGlobal;
