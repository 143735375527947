/** @format */

import { IKeyAuth, IThemeColor } from 'types';
import { KEY_CONTEXT } from 'utils';

export function useKey() {
  const TvcSSO = window?.['TvcSSO'];

  const setKeyLang = value => {
    localStorage.setItem(KEY_CONTEXT.LANGUAGE, value);
  };

  const setKeySC = value => {
    localStorage.setItem(KEY_CONTEXT.SC_CONNECTION_TOKEN, value);
  };

  const setKeyTheme = value => {
    localStorage.setItem(KEY_CONTEXT.THEME_MODE, value);
  };
  const setKeyUser = user => {
    localStorage.setItem(KEY_CONTEXT.USER, JSON.stringify(user));
  };

  const setKeySite = ({ authToken, refreshToken, updateParent = false }: IKeyAuth) => {
    if (updateParent) {
      TvcSSO.setValue(KEY_CONTEXT.REFRESH_TOKEN, refreshToken);
      TvcSSO.setValue(KEY_CONTEXT.AUTH_TOKEN, authToken);
    }
    localStorage.setItem(KEY_CONTEXT.AUTH_TOKEN, authToken);
    localStorage.setItem(KEY_CONTEXT.REFRESH_TOKEN, refreshToken);
  };

  const removeKeySite = (updateParent = false) => {
    if (updateParent) {
      TvcSSO.setValue(KEY_CONTEXT.REFRESH_TOKEN, '');
      TvcSSO.setValue(KEY_CONTEXT.AUTH_TOKEN, '');
    }
    localStorage.removeItem(KEY_CONTEXT.AUTH_TOKEN);
    localStorage.removeItem(KEY_CONTEXT.REFRESH_TOKEN);
  };

  // custom get value
  const getKey = (key: string) => {
    return key === KEY_CONTEXT.THEME_MODE
      ? localStorage.getItem(key) || 'main'
      : localStorage.getItem(key);
  };

  // custom remove value
  const removeKey = (key: string) => {
    return localStorage.removeItem(key);
  };

  // custom set key
  const setKey = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  const themeMode = (getKey(KEY_CONTEXT.THEME_MODE) || 'main') as IThemeColor;

  return {
    themeMode,
    setKeyLang,
    setKeyTheme,
    setKeyUser,
    setKeySite,
    getKey,
    removeKey,
    removeKeySite,
    setKeySC,
    setKey,
  };
}
