/** @format */

import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { BoxPlan } from './components';
import { ButtonToggleCus, TextCus } from 'components';
import { DATA_BUTTON, QueryKeys } from 'utils';
import { useQuery } from '@tanstack/react-query';
import { fontSize, mainColor } from 'theme';
import { useUser } from 'hooks';
import { ResponsePlan } from 'types/payment';
import { paymentService } from 'services';

const PlanStandard = () => {
  const [typePlan, setTypePlan] = useState(DATA_BUTTON[0]);
  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.GET_PLAN, typePlan.value],
    queryFn: () => paymentService.listPlan(typePlan.value),
  });
  const { user } = useUser();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      flex={1}
      width={'100%'}
      paddingBottom={'100px'}
      sx={{
        gap: {
          xs: '50px',
          xl: '100px',
        },
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'20px'}
      >
        <TextCus fontWeight={'700'} fontSize={fontSize.xl} color={mainColor.textMain}>
          Choose your plan
        </TextCus>
        <TextCus fontWeight={'600'} fontSize={fontSize.s} color={'#6F6C90'}>
          Lorem ipsum dolor sit amet consectetur adipiscing elit dolor posuere vel venenatis eu sit
          massa volutpat.
        </TextCus>
        <Box display={'flex'} alignItems={'center'} gap={'2px'} justifyContent={'center'}>
          <ButtonToggleCus typePlan={typePlan} setTypePlan={setTypePlan} />
          <TextCus fontWeight={'bold'} color={mainColor.secondary} fontSize={fontSize.xs}>
            (Save 15%)
          </TextCus>
        </Box>
      </Box>
      {isLoading ? (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'340px'}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          marginTop={'30px'}
          justifyContent={'center'}
          maxWidth={'90%'}
          display={'flex'}
          alignItems={'start'}
          justifyItems={'center'}
          flexWrap={'wrap'}
          gap={'20px'}
          margin={'0 auto'}
        >
          {data.map((v: ResponsePlan) => {
            const isFree = v.packageName === 'FREE';
            const isEnterprise = v.packageName === 'ENTERPRISE';
            const isCurrentPlan = user?.plan === v.packageName;
            return (
              <BoxPlan
                isFree={isFree}
                item={v}
                key={v?._id}
                typePlan={typePlan}
                isEnterprise={isEnterprise}
                isCurrentPlan={isCurrentPlan}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default PlanStandard;
