/** @format */

import { Box } from '@mui/material';
import { TextCus } from 'components';
import * as React from 'react';
import { fontSize, mainColor } from 'theme';

interface TabHome {
  isTitle?: string,
  value?: string
  title?: string
}

// const data = [{ title: 'Create Video' }, { title: 'Change background' }]

export const TabHome: React.FC<TabHome> = ({ isTitle, title }) => {
  // const handleClick = (path: string) => {
  //   navigate(path)
  // }

  return (
    <Box
      mb={'50px'}
      width={'auto'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyItems={'center'}
      paddingX={'10px'}
      sx={{
        '&:hover': { cursor: 'pointer' }
      }
      }
    >
      <TextCus
        color={isTitle === title ? mainColor.textMain : mainColor.textGray}
        fontSize={`${fontSize.sm}`}
        paddingX={1}
      >
        {title}
      </TextCus>
      <div
        style={{
          background: `${isTitle === title ? mainColor.borderColor2 : ""}`,
          width: "90%",
          height: '2px',
          marginTop: "3px",
          transition: "all 0.3s ease-in-out",
        }}></div>
    </Box >
  );
}


