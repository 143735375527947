/** @format */

import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import { ButtonNavigate } from '../components';
import { APP_ROOT, PUBLIC_URL } from 'utils/routes';
import { useNavigate } from 'react-router-dom';
import { ButtonCus, InputOutLine, TextCus } from 'components';
import { fontSize, mainColor } from 'theme';
import { useTimer, useUser } from 'hooks';
import { Controller, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { EnumTypeSendMailOtp, ErrorOtpForm, ErrorOtpMessage, ForgotForm, OTPForm } from 'types';
import { dynamicSchema } from 'utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { authService, userService } from 'services';
import SnackbarUtils from 'utils/SnackbarUtils';

const ForgotPass = () => {
  const navigate = useNavigate();
  const [isOtpStep, setIsOtpStep] = useState(false);
  const refEmail = useRef({ email: '' }).current;
  const { setNewPassViaUid } = useUser();
  const { seconds, isActive, startTimer } = useTimer(60);

  const {
    control,
    handleSubmit,
    // reset,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<ForgotForm | OTPForm>({
    resolver: yupResolver(dynamicSchema(isOtpStep)) as unknown as Resolver<ForgotForm | OTPForm>,
    defaultValues: {
      email: '',
      otp: '',
    },
  });
  console.log("🚀 ~ ForgotPass ~ errors:", errors)

  const sendOtpMutation = useMutation({
    mutationFn: (formData: ForgotForm) =>
      userService.getOtpViaMail(formData.email, EnumTypeSendMailOtp.FOR_GOT_PASS_WORD),
    onSuccess: (data: any) => {
      if (data?.statusCode === 200) {
        SnackbarUtils.success(`Otp has been sent to email successfully`);
        setIsOtpStep(true);
        startTimer();
        return;
      }
      SnackbarUtils.warning(data?.message || 'Failed to send otp');
    },
    onError: () => {
      SnackbarUtils.warning('Change failed!');
    },
  });

  const verifyOtpMutation = useMutation({
    mutationFn: (formData: OTPForm) => authService.verifyOtp(formData.otp),
    onSuccess: (data: any) => {
      if (data?.statusCode === 200) {
        setNewPassViaUid(data?.data?.uid);
        navigate(PUBLIC_URL.RESET_PASSWORD);
        setIsOtpStep(false);
        return;
      }
      SnackbarUtils.warning(data?.message || 'Failed to send otp');
    },
    onError: () => {
      SnackbarUtils.warning('Change failed!');
    },
  });

  const onSubmit: SubmitHandler<ForgotForm | OTPForm> = data => {
    console.log("🚀 ~ ForgotPass ~ data:", data, errors)
    if (!isOtpStep) {
      refEmail.email = data.email;
      sendOtpMutation.mutate(data);
      setValue('email', data.email);
      setValue('otp', '');
      // reset();
    } else {
      verifyOtpMutation.mutate(data as OTPForm);
    }
  };
  return (
    <Box
      bgcolor={'black'}
      width={'100%'}
      height={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      padding={'20px'}
    >
      {/* Header */}
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
        <ButtonNavigate title='Skip sign in' action={() => navigate(APP_ROOT.DASHBOARD)} />
      </Box>
      {/* Content */}
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Box mt={3} width={'90%'} px={'15px'}>
          <TextCus fontSize={'20px'} mb={4} fontWeight={'700'} color={mainColor.textMain}>
            Forgot password
          </TextCus>
        </Box>
        <Box
          mt={2}
          flex={0.5}
          width={'90%'}
          px={'20px'}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!isOtpStep && <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputOutLine
                label="Enter your email"
                {...field}
                value={field.value}
                setValue={field.onChange}
                error={!!errors.email}
                errorMessage={errors?.email?.message}
                fullWidth
                sx={{
                  mt: 5,
                  mb: 2.5,
                  color: ` !important`,
                  display: !isOtpStep ? 'block' : 'none'
                }}
              />
            )}
          />}

          {isOtpStep && <Controller
            name="otp"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputOutLine
                label="Enter OTP"
                {...field}
                value={field.value}
                setValue={field.onChange}
                error={!!(errors as ErrorOtpForm)?.otp}
                errorMessage={(errors as ErrorOtpMessage)?.otp?.message}
                fullWidth
                sx={{
                  mt: 5,
                  mb: 2.5,
                  color: `${mainColor.textMain} !important`,
                  display: isOtpStep ? 'block' : 'none'
                }}
              />
            )}
          />}

          <ButtonCus
            sx={{
              mt: 5,
              backgroundColor: isValid ? mainColor.secondary : mainColor.secondary,
            }}
            fullWidth
            type='submit'
            isLoading={sendOtpMutation.isPending || verifyOtpMutation.isPending}
            disabled={isActive ? !isActive : !isValid}
          >
            <TextCus color={mainColor.textMain} fontSize={fontSize.sm} fontWeight={'600'}>
              Verify
            </TextCus>
          </ButtonCus>
        </Box>
        {isOtpStep && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <TextCus color={mainColor.textMain}>Didn’t get any email?</TextCus>
            <Box
              display={'flex'}
              ml={1}
              gap={'3px'}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                console.log("🚀 ~ ForgotPass ~ isActive:", isActive, getValues('email'))

                if (!isActive) {
                  sendOtpMutation.mutate({
                    email: getValues('email'),
                  });
                  setValue('otp', '')
                }
              }}
            >
              <TextCus useI18n variant='body1' color={mainColor.secondary}>
                Resend
              </TextCus>
              {isActive ? (
                <TextCus useI18n variant='body1' color={mainColor.textMain}>
                  {` (${seconds}s)`}
                </TextCus>
              ) : (
                ''
              )}
            </Box>
          </Box>
        )}
      </Box>
      {/* Footer */}
      <Box></Box>
    </Box>
  );
};

export default ForgotPass;
