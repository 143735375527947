/** @format */

import { Box } from '@mui/material';
import React, { FC, useState } from 'react';
import { CardRecent } from './CardRecent';
import { CardLoading } from './CardLoading';
import { ModalVideo } from 'components/ModalGlobal';
import { useWindowDimensions } from 'hooks';
import ModalImage from 'components/ModalGlobal/ModalImage';
import { CardRecentImage } from './CardRecentImage';

interface TypeRecent {
  dataRecents?: any,
  isLoading?: boolean
  refetch: any
}
const RecentCreations: FC<TypeRecent> = ({ dataRecents, isLoading = false, refetch }) => {

  const [stateVideoModal, setStateVideoModal] = useState<any>(null)
  const [stateImageModal, setStateImageModal] = useState<any>(null)

  const [openVideo, setOpenVideo] = React.useState(false);
  const [openImage, setOpenImage] = React.useState(false);

  const widthGlobal = useWindowDimensions()?.width

  return (

    <Box
      minHeight={'550px'}
      width={'100%'}
      display={'flex'}
      flexWrap={'wrap'}
      justifyContent={`${widthGlobal === 426 ? 'center' : 'start'}`}
      gap={"10px"}
    >
      {isLoading && <CardLoading />}
      {dataRecents?.map((item: any, index: any) => {
        return (item?.video ? (<CardRecent
          key={index}
          item={item}
          title={item?.prompt}
          url={`${item?.video}`}
          createdAt={item?.createdAt}
          setStateVideoModal={setStateVideoModal}
          setOpenVideo={setOpenVideo}
          refetch={refetch}
        />)
          : (<CardRecentImage
            key={index}
            item={item}
            title={item?.prompt}
            url={`${item?.image}`}
            createdAt={item?.createdAt}
            setStateVideoModal={setStateImageModal}
            setOpenVideo={setOpenImage}
            refetch={refetch}
          />)
        )
      }

      )}
      <ModalVideo
        open={openVideo}
        onClose={() => setOpenVideo(false)}
        url={stateVideoModal?.video}
        desc={stateVideoModal?.prompt}
        createdAt={stateVideoModal?.createdAt}
      />

      <ModalImage
        open={openImage}
        onClose={() => setOpenImage(false)}
        url={stateImageModal?.image}
        desc={stateImageModal?.prompt}
        createdAt={stateImageModal?.createdAt}
      />

    </Box>

  );
};

export default RecentCreations;

