/** @format */

import React, { useEffect, useState } from 'react';
import { fontSize, mainColor } from 'theme/default';
import { Box, Modal } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextCus } from 'components/TextCus';
import { iconText2Video, iconImage2Video, iconChangingBG } from 'assets';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { APP_ROOT } from 'utils/routes';

interface IModalProfile {
  open: boolean;
  onClose: () => void;
}
const dataMenu = [
  { icon: iconChangingBG, label: 'Background Change', link: APP_ROOT.CHANGE_BACKGROUND },
  { icon: iconText2Video, label: 'Text to Video', link: APP_ROOT.TEXT_2_VIDEO },
  { icon: iconImage2Video, label: 'Image to Video', link: APP_ROOT.IMAGE_2_VIDEO },
]

const ModalMenuSelect = ({ open, onClose }: IModalProfile) => {
  // const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState('/');

  const handleClick = (path: string) => {
    navigate(path)

  }
  useEffect(() => {
    setActive(location.pathname)
  }, [location.pathname])

  return (
    <Modal
      keepMounted
      open={open}
      sx={{
        zIndex: 9999,
        display: 'flex',
        alignItems: '',
        justifyContent: 'start',
        width: '290px',
        left: 135,
        height: "335px"
      }}
      onClick={onClose}
      onClose={onClose}
      aria-labelledby='keep-mounted-modal-title'
      aria-describedby='keep-mounted-modal-description'
    >
      <Box
        sx={{
          width: "247px",
          margin: '0 auto',
          mt: 11,
          // border: `3px solid ${mainColor.secondary}`,
          backgroundColor: mainColor.primary,
          borderRadius: '10px',
          color: mainColor.textMain,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",

        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >

          {dataMenu.map((item, index) => (
            <Box
              mt={2}
              display={'flex'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              key={index}
              style={{ cursor: 'pointer' }}
              padding={'13px'}
              color={`${mainColor.textMain}`}
              borderRadius={'8px'}
              bgcolor={` ${active === item?.link ? mainColor.borderColor1 : ""}`}
              width={'85%'}
              onClick={() => { handleClick(item?.link) }}
              sx={{ '&:hover': { background: "#333333" } }}
            >
              <Box
                sx={{

                  background: active === item?.link ? "linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)" : "#333333",
                  borderRadius: '9px',
                  width: '31px',
                  height: '31px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&:hover': { background: "linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)" }
                }}
              >
                <LazyLoadImage src={item?.icon} alt='logout' />
              </Box>
              <TextCus
                fontSize={`${fontSize.xs}`}
                pl={'10px'}
              >
                {item?.label}
              </TextCus>
            </Box>
          ))}
        </Box>
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          flexDirection={'column'}
          mb="25px"
          sx={{ cursor: 'pointer' }}
        >


        </Box>


      </Box>
    </Modal >
  );
};

export default ModalMenuSelect;
