/** @format */

import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { ButtonNavigate } from '../components';
import { useNavigate } from 'react-router-dom';
import { APP_ROOT, PUBLIC_URL } from 'utils/routes';
import { ButtonCus, InputOutLine, TextCus } from 'components';
import { mainColor } from 'theme';
import { useTimer } from 'hooks';
import { Controller, useForm, Resolver, SubmitHandler } from 'react-hook-form';
import { EnumTypeSendMailOtp, ErrorOtpForm, ErrorOtpMessage, ForgotForm, OTPForm } from 'types';
import { yupResolver } from '@hookform/resolvers/yup';
import { dynamicSchema } from 'utils';
import { useMutation } from '@tanstack/react-query';
import { authService, userService } from 'services';
import SnackbarUtils from 'utils/SnackbarUtils';

const VerifyOtp = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ForgotForm | OTPForm>({
    resolver: yupResolver(dynamicSchema(true)) as unknown as Resolver<ForgotForm | OTPForm>,
    defaultValues: {
      otp: '',
    },
  });

  const { seconds, isActive, startTimer } = useTimer(60);

  const verifyOtpMutation = useMutation({
    mutationFn: (formData: OTPForm) => authService.verifyEmail(formData.otp),
    onSuccess: (data: any) => {
      if (data?.statusCode === 200) {
        SnackbarUtils.success(data?.message);
        navigate(PUBLIC_URL.LOGIN);
        return;
      }
      SnackbarUtils.warning(data?.message || 'Failed to send otp');
    },
    onError: () => {
      SnackbarUtils.warning('Change failed!');
    },
  });

  const sendOtpMutation = useMutation({
    mutationFn: (formData: ForgotForm) =>
      userService.getOtpViaMail(formData.email, EnumTypeSendMailOtp.REGISTER),
    onSuccess: (data: any) => {
      if (data?.statusCode === 200) {
        SnackbarUtils.success('Enter OTP is sent to your email');
        startTimer();
        return;
      }
      SnackbarUtils.warning(data?.message || 'Failed to send otp');
    },
    onError: () => {
      SnackbarUtils.warning('Change failed!');
    },
  });

  const onSubmit: SubmitHandler<ForgotForm | OTPForm> = data => {
    verifyOtpMutation.mutate(data as OTPForm);
  };

  useEffect(() => {
    startTimer();
  }, []);

  return (
    <Box
      bgcolor={'black'}
      width={'100%'}
      height={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      padding={'20px'}
    >
      {/* Header */}
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
        <ButtonNavigate title='Skip sign in' action={() => navigate(APP_ROOT.DASHBOARD)} />
      </Box>
      {/* Content */}
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Box
          flex={0.5}
          width={'90%'}
          px={'15px'}
          mt={2}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextCus fontSize={'20px'} mb={3} fontWeight={'700'} color={mainColor.textMain}>
            Please verify your email
          </TextCus>
          <Controller
            name='otp'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <InputOutLine
                label='Enter OTP'
                {...field}
                value={`${field.value}`}
                setValue={field.onChange}
                error={!!(errors as ErrorOtpForm)?.otp}
                errorMessage={(errors as ErrorOtpMessage)?.otp?.message}
                fullWidth
                sx={{
                  mt: 5,
                  mb: 2.5,
                }}
              />
            )}
          />
          <Box mt={2}>
            <Box mt={10} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <ButtonCus
                title='Verify'
                LabelProps={{ variant: 'bodyBold' }}
                useI18n
                sx={{ width: '100%' }}
                variant={'text'}
                type='submit'
                // isLoading={verifyOtpMutation.isPending}
                isLoading={sendOtpMutation.isPending || verifyOtpMutation.isPending}
                disabled={!isValid || !isActive}
              />
            </Box>
          </Box>
          <Box
            mt={'20px'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <TextCus color={mainColor.textMain}>Didn’t get any email?</TextCus>
            <Box
              display={'flex'}
              ml={1}
              gap={'3px'}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                if (!isActive) {
                  sendOtpMutation.mutate({
                    email: localStorage.getItem('email') + '',
                  });
                  startTimer();
                }
              }}
            >
              <TextCus useI18n variant='body1' color={mainColor.secondary}>
                Resend
              </TextCus>
              {isActive ? (
                <TextCus useI18n variant='body1' color={mainColor.textMain}>
                  {` (${seconds}s)`}
                </TextCus>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Footer */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      />
    </Box>
  );
};

export default VerifyOtp;
