/** @format */

import { Box } from '@mui/material';
import { ButtonCus, TextCus } from 'components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { fontSize, mainColor } from 'theme';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      textAlign={'center'}
      height={'100vh'}
      color={`${mainColor.textMain}`}
    >
      <TextCus
        sx={{
          fontSize: `${fontSize['6xl']}`,
          fontWeight: 'bold',
        }}
      >
        404
      </TextCus>
      <TextCus
        sx={{
          fontSize: `${fontSize.xxl}`,
          fontWeight: '700',
          marginBottom: '8px',
        }}
      >
        This page could not be found
      </TextCus>
      <TextCus
        style={{
          fontSize: `${fontSize.md}`,
          marginBottom: '24px',
        }}
      >
        Sorry, we couldn’t find what you’re searching for. Please try again.
      </TextCus>
      <ButtonCus title='Back to home' onClick={() => navigate('/')} />
    </Box>
  );
};

export default NotFound;
