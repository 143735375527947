/** @format */

import React, { useState } from 'react';
import { Box } from '@mui/material';
import { fontSize, mainColor } from 'theme';
import { ButtonCus, ModalSubmitInfo, TextCus } from 'components';
import { useNavigate } from 'react-router-dom';
import { APP_ROOT } from 'utils/routes';
// import { APP_ROOT } from 'utils/routes';
interface EnumBoxPlan {
  item: any
}

const BoxPlan = ({ item }: EnumBoxPlan) => {
  const [isModalSubMit, setIsModalSubMit] = useState<boolean>(false)
  const navigate = useNavigate();
  // const [showModel, setShowModel] = useState(false);

  // console.log('showModel', showModel);
  return (
    <Box
      maxWidth={'362px'}
      minWidth={'362px'}
      height={'100%'}
      bgcolor={mainColor.textMain}
      display={'flex'}
      flexDirection={'column'}
      padding={'15px'}
      sx={{ border: '1px solid #B2B2B2' }}
      justifyContent={'space-between'}
      borderRadius={'12px'}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
          <TextCus color={mainColor.primary} fontSize={fontSize.md} fontWeight={'700'}>
            {item.title.toUpperCase()}
          </TextCus>
          <ButtonCus
            title={item?.labelBtn}
            onClick={() => {
              if (item?.title !== 'standard') return setIsModalSubMit(true);
              navigate(APP_ROOT.PLAN_STANDARD);
            }}
            sx={{
              mt: '15px',
              height: '41px',
              width: '100%',
              lineHeight: '40px',
              fontWeight: 600,
              fontSize: '18px !important',
            }}
          />
          <TextCus
            mt={'25px'}
            color={mainColor.primary}
            sx={{ fontSize: { xs: '16px' } }}
            fontWeight={700}
          >
            What’s included
          </TextCus>

          <Box
            mt={'25px'}
            display={'flex'}
            flexDirection={'column'}
          >
            {item?.description.map((v: any, index) => (
              <Box
                py={'10px'}
                display={'flex'}
                alignItems={'center'}

                key={index}>
                {v?.icon}
                <TextCus
                  ml={'10px'}
                  fontSize={fontSize.sm}
                  fontWeight={400}
                >
                  {v?.title}
                </TextCus>
                <TextCus
                  ml={'10px'}
                  fontSize={fontSize.sm}
                  fontWeight={700}
                >
                  {v?.content}
                </TextCus>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      {isModalSubMit && <ModalSubmitInfo open={isModalSubMit} onClose={() => setIsModalSubMit(false)} />}
    </Box>
  )

};

export default BoxPlan;
