/** @format */

import React, { useEffect } from 'react';

import { LoadingPage } from 'components';
import { useDetectMultiTab, useDevice, useLoginOneDevice, useNotification, useUser, useWindowDimensions } from 'hooks';
import i18next from 'i18next';
import { MainLayout, Maintenance } from 'components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { privateRoutes, publicRoutes, RouterType } from './routes';
import ModalCheckMobile from 'components/ModalGlobal/ModalCheckMobile';

function App() {
  const { useMaintain } = useNotification();
  const { maintain, isLoading } = useMaintain();
  const { deviceId } = useDevice();
  const { languages } = useUser();

  useDetectMultiTab();
  useLoginOneDevice(deviceId);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (languages.value) {
      i18next.changeLanguage(languages.value);
    }
  }, [languages.value]);

  if (isLoading) return <LoadingPage />;

  if (maintain?.isMaintain === true)
    return (
      <React.Suspense fallback={<LoadingPage />}>
        <Maintenance endTime={maintain.endTime} />
      </React.Suspense>
    );

  return (
    <Router basename='/'>
      <React.Suspense fallback={<LoadingPage />}>
        {width <= 768 && <ModalCheckMobile />}
        <Routes>
          {publicRoutes.map((route: RouterType, index) => {
            const Layout = route.layout;
            const Component = route.component;
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <Layout>
                    <Component />
                  </Layout>
                }
              />
            );
          })}

          {privateRoutes.map((route: RouterType, index) => {
            const Layout = route.layout || MainLayout;
            const Component = route.component;
            return (
              <Route
                key={`${index}-${route.path}`}
                path={route.path}
                element={
                  <Layout>
                    <Component />
                  </Layout>
                }
              />
            );
          })}

        </Routes>
      </React.Suspense>
    </Router>
  );
}

export default App;
