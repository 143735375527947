/** @format */

import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { mainColor } from 'theme';

const RecentSkeleton = () => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      gap={"10px"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flexWrap={"wrap"}>
      {[...Array(4)].map((_, index) => {
        return (
          <Box
            display={"flex"}
            minHeight={"510px"}
            width="calc((100% - 30px) / 4)"
            gap={"10px"}
            flexDirection={"column"}
            key={index}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                backgroundColor: `${mainColor.hoverIcon}`,
                borderRadius: "12px",
                height: "200px",
                width: "100%",
              }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                backgroundColor: `${mainColor.hoverIcon}`,
                borderRadius: "12px",
                height: "20px",
                width: "200px",
              }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                backgroundColor: `${mainColor.hoverIcon}`,
                borderRadius: "12px",
                height: "20px",
                width: "100px",
              }}
            />

          </Box>
        );
      })}
    </Box>
  );
};

export default RecentSkeleton;

