/** @format */

import React, { useEffect, useRef, useState } from 'react';
import { ButtonCus, InputCus, TextCus } from 'components';
import { fontSize, mainColor } from 'theme';
import { Box, CircularProgress } from '@mui/material';
import styles from './styles';
import { BoxLayout } from 'components/Layouts';
import { Icons } from 'assets';
import { RecentCreations } from 'components/RecentCreations';
import { TabHome } from 'pages/main/TabHome';
import { EnumInputVariant, KEY_CONTEXT, inputToolSchema } from 'utils';
import { useGenerate } from 'hooks/useGenerate';
import { ETaskTracking, TParams } from 'types';
import RecentEmty from 'components/RecentCreations/RecentEmty';
import { useKey, useLayout, useProfile } from 'hooks';
import ModalProcessFail from 'components/ModalGlobal/ModalProcessFail';
import ModalNotBalance from 'components/ModalGlobal/ModalNotBalance';
import { IGenerateType } from 'types/generate';
import ModalLoading from 'components/ModalGlobal/ModalLoading';
import RecentSkeleton from 'components/RecentCreations/RecentSkeleton';

const dataTab = [{ title: 'Recent', type: "TEXT2VIDEO" }, { title: 'History', type: "ALL" }]
const formInterface = { prompt: "" }

function TextToVideo() {

  /*STATE*/
  const [formData, setFormData] = useState(formInterface);
  const [errors, setErrors] = useState({});
  // const [isLoading, setIsLoading] = useState(false);
  // const [idTracking, setIdTracking] = useState('');
  const [isTitle, setIsTitle] = useState('Recent')
  const [typeParam, setTypeParam] = useState<string>(IGenerateType.TEXT2VIDEO)
  const [valueInput, setValueInput] = useState<any>(null)
  const [paramsGenerate, setParamsGenerate] = useState<TParams>({
    page: 1,
    limit: 10,
    type: typeParam,
  });
  const [openModalFail, setOpenModalFail] = useState(false);
  const [openModalNotCredit, setOpenModalNotCredit] = useState(false);
  const [isLoadingGlobal, setisLoadingGlobal] = useState(false);
  /*HOOKS*/
  const { useGetRecent, text2VideoMutation, taskTrackingMutation, useGetPrompt } = useGenerate();
  const { recents, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useGetRecent(paramsGenerate)
  const { prompts } = useGetPrompt({
    page: 1,
    limit: 6,
  })
  const { idTrackingText, setIdTrackingText, loadingText2Video,
    setloadingText2Video, promptInputGlobal, setPromtInputGlobal } = useLayout();
  const { getProfile } = useProfile();
  const { setKey, getKey, removeKey } = useKey();

  /*CHECK VALUE PROMP GLOBAL*/
  useEffect(() => {
    if (promptInputGlobal) {
      setValueInput(promptInputGlobal)
      handleSubmit()
    }
  }, [promptInputGlobal])

  /*CHECK ID TRACKING*/
  const idTracking = getKey(KEY_CONTEXT.ID_TRACKING_TEXT)
  useEffect(() => {
    if (idTracking && recents[0]?.trackId !== idTracking) {
      setIdTrackingText(idTracking)
      setloadingText2Video(true)
    }
  }, []);

  const intervalRef = useRef<any>(null)

  useEffect(() => {
    setParamsGenerate({
      ...paramsGenerate,
      type: typeParam,
    });
  }, [typeParam]);


  /*FUNTION TAB*/
  const handleClickTab = (title: string, type: string) => {
    setIsTitle(title)
    setTypeParam(type)
  }
  const handleClickPrompt = (prompt: string) => {
    setValueInput(prompt)
    setFormData({ prompt: prompt })
  }

  /*FUNTION HANDLE SUBMIT*/
  const handleSubmit = async () => {
    try {
      const inputData = { prompt: promptInputGlobal ? promptInputGlobal : formData.prompt }
      console.log("🚀 ~ handleSubmit ~ inputData:", inputData)
      await inputToolSchema.validate(inputData, { abortEarly: false })
      setisLoadingGlobal(true)
      text2VideoMutation.mutate(inputData.prompt, {
        onSuccess: (data: any) => {
          if (data?.statusCode === 400) {
            if (data?.message === "BALANCE_NOT_ENOUGH") {
              setOpenModalNotCredit(true);
            } else {
              setOpenModalFail(true);
            }
          } else {
            setIdTrackingText(data?.data?.id)
            setKey(KEY_CONTEXT.ID_TRACKING_TEXT, data?.data?.id)
            setloadingText2Video(true)
            setisLoadingGlobal(false)
            getProfile()
            setFormData(formInterface)
            setValueInput(null)
            setPromtInputGlobal('')
          }
        }
      })
      handleEvenScroll()
      return
    } catch (error: any) {
      const validationErrors = {};
      if (error?.inner) {
        error?.inner.forEach((err: any) => {
          validationErrors[err?.path] = err.message;
        });
      }
      setErrors(validationErrors);
    }
  }

  /*HANDLE TRACKING*/
  useEffect(() => {
    if (intervalRef.current) {
      return () => {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
    if (idTrackingText && loadingText2Video) {
      intervalRef.current = setInterval(() => {
        taskTrackingMutation.mutate(idTrackingText, {
          onSuccess: (data) => {
            const state = data?.data?.state
            if (state === ETaskTracking.COMPLETED || state === ETaskTracking.FAILED) {
              console.log('Task==================clear',)
              setloadingText2Video(false)
              setIdTrackingText('')
              removeKey(KEY_CONTEXT.ID_TRACKING_TEXT)
              refetch()
              return () => {
                clearInterval(intervalRef.current)
                intervalRef.current = null
              }
            }
          }
        })
      }, 10000)
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null
      }
    }
  }, [idTrackingText, loadingText2Video])

  /*HANDLE Event Scroll*/
  const handleEvenScroll = () => {
    setTimeout(() => {
      const targetElement = document.getElementById("tab-id");
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 1000)
  };

  return (
    <>
      <Box
        // paddingBottom={'15%'}
        sx={styles.boxContainer}
        marginTop={'150px'}
        flexDirection={'column'}
        justifyContent={'center'}
        width={'100%'}
        alignItems={'center'}
      >
        <BoxLayout>
          <Box
            display={'flex'}
            component='form'
            justifyContent={'end'}
            alignItems={'end'}
          // onSubmit={(e) => e.preventDefault()}
          // onClick={() => { setValueInput(null) }}
          >
            <InputCus
              type="input"
              name="prompt"
              inputVariant={EnumInputVariant.textarea}
              placeholder="Text to video"
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
              value={valueInput}
              setValueInput={setValueInput}
            />
            <ButtonCus
              disabled={loadingText2Video}
              onClick={handleSubmit}
              sx={{
                padding: "0px !important",
                marginBottom: `5px`,
                borderRadius: '12px',
                width: '157px',
                height: '44px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: "linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)"
              }}

            >
              {Icons.StarBtn()}
              <TextCus
                color={mainColor.textMain}
                fontSize={`${fontSize.sm}`}
                paddingX={1}
              >
                Generate
              </TextCus>
            </ButtonCus>
          </Box>
        </BoxLayout>

        <Box
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'center'}
          width={'100%'}
        >
          {prompts.map((item, index) => (
            <Box
              onClick={() => { handleClickPrompt(String(item?.prompt)) }}
              key={index}
              marginX={'7px'}
              marginY={'14px'}
              display={'flex'}
              justifyContent={'center'}
              alignContent={'center'}
              alignItems={'center'}
              textAlign={'left'}
              sx={{
                width: '389px',
                height: '74px',
                borderRadius: '12px',
                backgroundColor: `${mainColor.textD2}`,
                '&:hover': { background: `${mainColor.textD1} `, cursor: 'pointer', border: '1px solid white' },
              }} >
              <TextCus
                width={'100%'}
                className='multiline-ellipsis'
                px={'10px'}
                color={mainColor.textMain}
                fontSize={`${fontSize.xs}`}
              >
                {item?.prompt}
              </TextCus>
            </Box>
          ))}
        </Box>


        <Box
          mt={'70px'}
          display={'flex'}
          width={'100%'}
          minWidth={'510px'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          |<Box
            width={'100%'}
            display={'flex'}
            justifyContent={'start'}
          >
            <TextCus
              color={mainColor.textMain}
              fontSize={`${fontSize.xl}`}
              ml={'15px'}
              textAlign={'left'}
            >
              Your Videos
            </TextCus>
          </Box>

          <Box
            display={'flex'}
            width={'100%'}
            mt={'20px'}
          >
            {dataTab.map((item, index) => (
              <Box id="tab-id" key={index} onClick={() => { handleClickTab(item?.title, item?.type) }}>
                <TabHome title={item?.title} isTitle={isTitle} />
              </Box>
            ))}
          </Box>
          {isLoading
            ? (<RecentSkeleton />)
            : ((recents.length > 0 || loadingText2Video)
              ? (<RecentCreations refetch={recents} dataRecents={recents} isLoading={loadingText2Video} />)
              : (<RecentEmty />))
          }
          {hasNextPage && (
            <Box
              marginBottom={'70px'}
              component={"div"}
              onClick={() => fetchNextPage()}
              sx={{
                cursor: 'pointer',
                borderRadius: '12px',
                '&:hover': { backgroundColor: `${mainColor.bgButtonHover}` }
              }}
              bgcolor={mainColor.hoverIcon}
              p={'8px'}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"15px"}
            >
              <TextCus color={mainColor.textMain} fontSize={fontSize.s}>{"See more"}</TextCus>
              {isFetchingNextPage ? (
                <CircularProgress size={15} />
              ) : (
                <Icons.ArrowSelect />
              )}
            </Box>
          )}
        </Box>
        {openModalFail && (
          <ModalProcessFail
            open={openModalFail}
            onClose={() => setOpenModalFail(false)}
          />
        )}
        {openModalNotCredit && (
          <ModalNotBalance
            open={openModalNotCredit}
            onClose={() => setOpenModalNotCredit(false)}
          />
        )}
        {isLoadingGlobal && <ModalLoading open={true} />}
      </Box>
    </>
  );
}

export default TextToVideo;
