/** @format */
import React, { forwardRef, memo, useMemo } from 'react';
import { Button as ButtonMui, CircularProgress } from '@mui/material';
import { Icons } from 'assets';
import { useKey } from 'hooks';
import { IButton, ISXTheme, IThemeColor } from 'types';
import { KEY_CONTEXT } from 'utils';
import { fontSize, mainColor } from 'theme/default';
import styled from '@emotion/styled';
import { TextCus } from 'components/TextCus';
import theme from 'theme/themes';
import createStyle from './styles';

const Button = forwardRef<HTMLButtonElement, IButton>(function Button(
  {
    title,
    useI18n,
    variantTitle = 'body1',
    LabelProps,
    variant = 'outlined',
    variantStyle = 'default',
    iconProps: icon,
    children,
    sx,
    disabled = false,
    startIcon,
    isLoading = false,
    ...props
  },
  ref,
) {
  // styles
  const { getKey } = useKey();
  const mode = getKey(KEY_CONTEXT.THEME_MODE) as IThemeColor;
  const styles = createStyle();
  const btnStyle = useMemo(
    () => styles[variant][variantStyle] as ISXTheme,
    [variant, variantStyle],
  );
  const stylesOverride = useMemo(() => {
    const baseStyles = [styles.base, btnStyle];
    if (Array.isArray(sx)) {
      return [...baseStyles, ...sx];
    }
    return [...baseStyles, sx];
  }, [sx, btnStyle]) as ISXTheme;

  const iconColor = useMemo(
    () =>
      icon?.color || variant !== 'contained'
        ? disabled
          ? 'rgba(0, 0, 0, 0.26)'
          : theme.palette.primary[mode]
        : 'white',
    [icon, variant],
  );

  const GradientButton = styled(ButtonMui)({
    background: disabled ? '#ccc' : mainColor.bgLinear,
    borderRadius: 12,
    border: 0,
    color: mainColor.primary,
    height: 44,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(105, 135, 255, .3)',
    fontSize: '14px',
    fontFamily: 'Montserrat !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      background: mainColor.bgLinear,
      color: mainColor.primary,
    },
  });

  // ICON default
  const IconButton = useMemo(
    () => ({
      google: <Icons.GoogleIcon color={iconColor} size={icon?.size || 13} {...icon} />,
      upload: <Icons.Upload color={iconColor} size={icon?.size || 13} {...icon} />,
      close: <Icons.Close color={iconColor} size={icon?.size || 12} {...icon} />,
      cancel: <Icons.Close color={iconColor} size={icon?.size || 12} {...icon} />,
    }),
    [icon],
  );

  return (
    <GradientButton
      ref={ref}
      sx={stylesOverride}
      startIcon={startIcon || (icon?.variant && IconButton?.[icon?.variant])}
      disabled={disabled}
      {...props}
    >
      {title ? (
        <TextCus
          color={mainColor.textMain}
          useI18n={useI18n}
          fontSize={fontSize.xs}
          fontWeight={'600'}
          variant={variantTitle}
          {...LabelProps}
        >
          {title}
        </TextCus>
      ) : (
        children
      )}
      {isLoading && <CircularProgress size={'20px'} sx={{ ml: '10px', color: 'white' }} />}
    </GradientButton>
  );
});

export default memo(Button);
