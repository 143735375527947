/** @format */

import { useQuery } from '@tanstack/react-query';
import { BoxCredit, TextCus } from 'components';
import React, { useState } from 'react';
import { paymentService } from 'services';
import { fontSize, mainColor } from 'theme';
import { TParams } from 'types';
import { QueryKeys } from 'utils';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Chip,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';
import { Icons } from 'assets';

const Transaction = () => {
  const [params, setParams] = useState<TParams>({
    page: 1,
    limit: 5,
  });

  const { data, isLoading } = useQuery({
    queryKey: [QueryKeys.TRANSACTION, params.page, params.typeModel],
    queryFn: () => paymentService.getTransactions({ ...params }),
  });

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      paddingBottom={'100px'}
      flex={1}
      width={'100%'}
      maxWidth={'1558px'}
      sx={{
        gap: {
          xs: '50px',
          xl: '100px',
        },
      }}
    >
      <Box
        width={'100%'}
        maxWidth={'668px'}
        border={'1px solid #6DDBFE'}
        px={'20px'}
        borderRadius={'12px'}
        height={'60px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <TextCus fontWeight={'600'} fontSize={'16px'} color={mainColor.textMain}>
          Your Credits
        </TextCus>
        <BoxCredit />
      </Box>
      <Paper sx={{ width: '90%', backgroundColor: 'rgba(0, 0, 0, 0.3) !important' }}>
        {isLoading ? (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100px'}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer sx={{ position: 'relative' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    'No',
                    'Invoice ID',
                    'Items',
                    'Date',
                    'Amount ($)',
                    'Payment Method',
                    'Status',
                  ].map((item, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        color: '#ffffff !important',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {data && data?.length > 0 ? (
                <TableBody>
                  {data?.map((row, index) => (
                    <TableRow key={index} sx={{ borderColor: '#ccc !important' }}>
                      <TableCell sx={{ color: 'white !important' }}>{index + 1}</TableCell>
                      <TableCell sx={{ color: 'white !important' }}>{row.invoice}</TableCell>
                      <TableCell sx={{ color: 'white !important' }}>{row.plan}</TableCell>
                      <TableCell sx={{ color: 'white !important' }}>
                        {dayjs(row.startDate).format('HH:mm, YYYY/MM/DD')}
                      </TableCell>
                      <TableCell sx={{ color: 'white !important' }}>{row.amount}</TableCell>
                      <TableCell sx={{ color: 'white !important' }}>{row.paymentMethod}</TableCell>
                      <TableCell>
                        <Chip label={'Success'} color={'success'} sx={{ borderRadius: '10px' }} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100px',
                    textAlign: 'center',
                  }}
                >
                  <TextCus
                    fontSize={fontSize.sm}
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      bottom: '10%',
                      transform: 'translateX(-50%)',
                    }}
                  >
                    No transactions
                  </TextCus>
                </Box>
              )}
            </Table>
          </TableContainer>
        )}

        <Box display='flex' justifyContent='space-between' alignItems='center' p={2}>
          <Button
            sx={{ gap: '10px', fontSize: '12px', color: mainColor.textMain }}
            disabled={params.page === 1}
            onClick={() => setParams({ ...params, page: params.page - 1 })}
          >
            {Icons.Prev()} Previous
          </Button>
          <Button
            sx={{ gap: '10px', fontSize: '12px', color: mainColor.textMain }}
            disabled={params.page >= (data?.pagination?.totalPage || 1)}
            onClick={() => setParams({ ...params, page: params.page - 1 })}
          >
            Next {Icons.Next()}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Transaction;
