/** @format */

export default {
  boxContainer: { display: 'flex' },
  boxMain: {
    backgroundColor: 'white',
    flex: 1,
    height: '100vh',
    // overflow: 'hidden',
  },
  content: { mt: '26px', mb: 4, position: 'relative' },
};
