/** @format */

import { Box } from '@mui/material';
import { TextCus } from 'components/TextCus';
// import { TextCus } from 'components/TextCus';
import * as React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { fontSize, mainColor } from 'theme';
import { LoadingGif } from 'assets';
import { useWindowDimensions } from 'hooks';


export const CardLoading = () => {
  const [amount, setAmount] = React.useState(5)
  const widthGlobal = useWindowDimensions()?.width
  React.useEffect(() => {
    if (widthGlobal >= 1440) {
      setAmount(5)
    }
    if (widthGlobal <= 1440) {
      setAmount(4)
    }
    if (widthGlobal <= 1024) {
      setAmount(3)
    }
    if (widthGlobal <= 768) {
      setAmount(2)
    }
    if (widthGlobal <= 426) {
      setAmount(1)
    }
  }, [widthGlobal])
  return (
    <Box
      // width={'291px'}
      width={`calc((100% - 45px) / ${amount})`}
      height={'218px'}
      bgcolor={mainColor.bgVideoLoading}
      mt={'10px'}
      borderRadius={'12px'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        transition: "all 0.10s ease-in-out",
      }}
    >
      <LazyLoadImage src={LoadingGif} alt='icon' width={'60px'} />
      <TextCus
        paddingY={'10px'}
        color={mainColor.textMain}
        fontSize={`${fontSize.xs}`}
        fontWeight={'bold'}
      >
        Generating your video
      </TextCus>
    </Box >

  );
}


