/** @format */

import { useEffect } from 'react';

import { useSocket } from 'contexts';
import { EModelAuth, TypeStatusDevice } from 'types';
import { userService } from 'services';

import { useAlert } from './useAlert';
import { useKey } from './useKey';
import { useUser } from './useUser';
import { useLayout } from './useLayout';

export const useLoginOneDevice = (idDevice: string) => {
  const { socket } = useSocket();
  const { warn } = useAlert();
  const { removeKey } = useKey();
  const { setIsLogged, isLogged } = useUser();
  const { setOpenModalAuth } = useLayout()

  const logout = async () => {
    setOpenModalAuth(EModelAuth.LOGIN)
    await userService.logout();
    removeKey('deviceId');
    setIsLogged(false);
  };

  useEffect(() => {
    if (socket) {
      socket.on(`eventLogin-${idDevice}`, data => {
        if (data?.status === TypeStatusDevice.OFFLINE && data.deviceId === idDevice && isLogged) {
          warn('Your account is logged in elsewhere');
          logout();
        }
      });
      return () => {
        socket.off(`eventLogin-${idDevice}`);
      };
    }
  }, [socket, isLogged]);
};
