import React, { ReactElement, useId } from "react";
import { styled } from "@mui/system";
import { FormControl, IconButton, Theme, SxProps, Box } from "@mui/material";
import { defaultColorTheme, mainColor } from "theme/default";
import { TextCus } from "components";

const CustomFormControl = styled(FormControl)<{
  warning?: boolean;
  error?: boolean;
}>(({ warning, error }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: error
        ? defaultColorTheme.warning
        : warning
          ? defaultColorTheme.warning
          : defaultColorTheme.textMain, // Outline color based on error or warning
    },
    "&:hover fieldset": {
      borderColor: error
        ? defaultColorTheme.warning
        : warning
          ? defaultColorTheme.warning
          : defaultColorTheme.textMain, // Outline color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: error
        ? defaultColorTheme.warning
        : warning
          ? defaultColorTheme.warning
          : defaultColorTheme.textMain, // Outline color when focused
    },
  },
  "& .MuiInputLabel-root": {
    color: error
      ? defaultColorTheme.warning
      : warning
        ? defaultColorTheme.warning
        : defaultColorTheme.textMain,
    "&.Mui-focused": {
      color: error
        ? defaultColorTheme.warning
        : warning
          ? defaultColorTheme.warning
          : defaultColorTheme.textMain,
    },
  },
}));

interface CustomFormControlInputProps {
  warning?: boolean;
  error?: boolean;
  label: string;
  value: string;
  errorMessage?: string;
  setValue?: (value: string) => void;
  type?: string;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  icon?: ReactElement;
  onClickIcon?: () => void;
  placeholder?: string;
}

const CustomFormControlInput: React.FC<CustomFormControlInputProps> = ({
  warning,
  error,
  label,
  value,
  setValue,
  errorMessage,
  type = "text",
  fullWidth,
  icon,
  onClickIcon,
}) => {
  const id = useId();

  return (
    <CustomFormControl
      variant="outlined"
      warning={warning}
      error={error}
      fullWidth={fullWidth}>
      <TextCus
        // color={"#131313"}
        color={`${mainColor.textMain}`} fontWeight={"600"} fontSize={"13px"} mb={1} >
        {label}
      </TextCus>
      <Box width={"100%"} position={"relative"}>
        <input
          autoComplete="off"
          id={id}
          type={type}
          value={value}
          onChange={(e) => setValue?.(e.target.value)}
          placeholder={label}
          style={{
            backgroundColor: "transparent",
            outline: "none",
            height: "50px",
            padding: "0 20px",
            borderRadius: "12px",
            border: "1px solid #ccc",
            color: "white",
            fontSize: "13px",
            width: "100%",
            // caretColor: 'blue'
          }}
        />
        {icon && (
          <Box position={"absolute"} right="5px" bottom={"6px"}>
            <IconButton onClick={onClickIcon} sx={{ padding: 1 }}>
              {icon}
            </IconButton>
          </Box>
        )}
      </Box>
      {errorMessage && (
        <TextCus
          marginTop={"5px"}
          useI18n
          color={
            error
              ? defaultColorTheme.warning
              : warning
                ? defaultColorTheme.warning
                : defaultColorTheme.textMain
          }>
          {errorMessage}
        </TextCus>
      )}
    </CustomFormControl>
  );
};

export default CustomFormControlInput;
