/** @format */

import { TParams, TResponse } from 'types';
import axiosInstance from './axios';
import {
  IChangeBackgroundParams,
  IChangingBackgroundParams,
  IImages2VideoParams,
  IRecentType,
} from 'types/generate';
import { END_POINT } from 'utils';
import axiosClient from './axios';

export const generateService = {
  getRecent: async (params: TParams): Promise<TResponse<any[]>> => {
    const { page, limit, ...restParams } = params;
    const queryParams = new URLSearchParams(restParams as Record<string, string>).toString();
    const url = `${END_POINT.GENERATE.GET_RENCENT}?page=${page}&limit=${limit}&${queryParams}`;
    const data: any = await axiosInstance.get(url);
    return data;
  },

  getPromtp: async (params: TParams): Promise<TResponse<IRecentType[]>> => {
    const { page, limit, ...restParams } = params;
    const queryParams = new URLSearchParams(restParams as Record<string, string>).toString();
    const url = `${END_POINT.GENERATE.GET_PROMPT}?page=${page}&limit=${limit}&${queryParams}`;
    const data = await axiosInstance.get(url);
    return data?.data;
  },

  getCommunity: async (params: TParams): Promise<TResponse<IRecentType[]>> => {
    const { page, limit, ...restParams } = params;
    const queryParams = new URLSearchParams(restParams as Record<string, string>).toString();
    const url = `${END_POINT.GENERATE.GET_COMMUNITY}?page=${page}&limit=${limit}&${queryParams}`;
    const data = await axiosInstance.get(url);
    return data?.data;
  },

  taskTracking: async (id: string) => {
    return await postRequest(END_POINT.GENERATE.TASK_TRACKING, {
      id,
    });
  },

  trackingAi: async (id: string) => {
    return await postRequest(END_POINT.GENERATE.TRACKING_AI, {
      id,
    });
  },

  text2Video: async (prompt: string) => {
    return await postRequest(END_POINT.GENERATE.TEXT_2_VIDEO, {
      prompt,
    });
  },

  images2Video: async (params: IImages2VideoParams) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return await axiosInstance.post(END_POINT.GENERATE.IMAGES_2_VIDEO, params, config);
  },

  changingBackground: async (params: IChangingBackgroundParams) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return await axiosInstance.post(END_POINT.GENERATE.CHANGING_BACKGROUND, params, config);
  },

  countViews: async (videoId: string) => {
    return await postRequest(END_POINT.GENERATE.COUNT_VIEWS, {
      videoId,
    });
  },

  generateBackground: async (prompt: string) => {
    return await postRequest(END_POINT.GENERATE.GENERATE_BACKGROUND, {
      prompt,
    });
  },

  deleteProject: async (id: string) => {
    return await postRequest(END_POINT.GENERATE.DELETE_PROJECT, {
      id,
    });
  },

  changeBackground: async (params: IChangeBackgroundParams) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return await axiosInstance.post(END_POINT.GENERATE.CHANGE_BACKGROUND, params, config);
  },
};

const postRequest = (url: string, param: any) => axiosClient.post(url, param);
