/** @format */

import React, { useEffect, useState } from 'react';
import { fontSize, mainColor } from 'theme/default';
import { Box, Fade, Modal } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextCus } from 'components/TextCus';
import { iconText2Video, iconImage2Video, iconChangingBG, iconHome, Icons } from 'assets';
import { APP_ROOT } from 'utils/routes';
import { useAuthentication } from 'hooks';

interface IModalProfile {
  open: boolean;
  onClose: () => void;
}
const dataMenu = [
  { icon: iconHome, label: 'Home', link: '/' },
  { icon: iconChangingBG, label: 'Background Change', link: APP_ROOT.CHANGE_BACKGROUND },
  { icon: iconText2Video, label: 'Text to Video', link: APP_ROOT.TEXT_2_VIDEO },
  { icon: iconImage2Video, label: 'Image to Video', link: APP_ROOT.IMAGE_2_VIDEO },
];

const dataMenuBottom = [
  { icon: Icons.IconPricing(), label: 'Pricing', link: APP_ROOT.PLAN },
  { icon: Icons.IconnTransaction(), label: 'Transactions', link: APP_ROOT.TRANSACTION },
  { icon: Icons.IconnLogOut(), label: 'Log out', link: "LOG_OUT" },
];

const ModalMenuRight = ({ open, onClose }: IModalProfile) => {
  // const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState('/');

  /*HOOK*/
  const { logoutMutation } = useAuthentication();

  const handleClick = (path: string) => {
    if (path === 'LOG_OUT') {
      logoutMutation.mutate();
      return
    }
    navigate(path);
  };
  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  return (
    <Modal
      keepMounted
      open={open}
      sx={{
        zIndex: 9999,
        display: 'flex',
        alignItems: '',
        justifyContent: 'start',
        width: '290px',
        opacity: !open ? 0 : 1,
        left: !open ? -100 : 0,
        height: '90%',
        transition: 'all 0.25s ease-in-out',
      }}
      onClick={onClose}
      onClose={onClose}
      aria-labelledby='keep-mounted-modal-title'
      aria-describedby='keep-mounted-modal-description'
    >
      <Fade in={open} timeout={500}>
        <Box
          sx={{
            width: '238px',
            margin: '0 auto',
            mt: 13,
            backgroundColor: mainColor.primary,
            borderRadius: '10px',
            color: mainColor.textMain,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {dataMenu.map((item, index) => (
              <Box
                mt={2}
                display={'flex'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                key={index}
                style={{ cursor: 'pointer' }}
                padding={'13px'}
                color={`${mainColor.textMain}`}
                borderRadius={'8px'}
                bgcolor={` ${active === item?.link ? mainColor.borderColor1 : ''}`}
                width={'90%'}
                onClick={() => {
                  handleClick(item?.link);
                }}
                sx={{ '&:hover': { background: '#333333' } }}
              >
                <Box
                  sx={{
                    background:
                      active === item?.link
                        ? 'linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)'
                        : '#333333',
                    borderRadius: '9px',
                    width: '31px',
                    height: '31px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': {
                      background:
                        'linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)',
                    },
                  }}
                >
                  <img src={item?.icon} alt='logout' />
                </Box>
                <TextCus fontSize={`${fontSize.xs}`} pl={'10px'}>
                  {item?.label}
                </TextCus>
              </Box>
            ))}
          </Box>


          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            flexDirection={'column'}
            mb='25px'
            sx={{ cursor: 'pointer' }}
          >
            <Box
              display='flex'
              flexDirection='column'
              width={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {dataMenuBottom.map((item, index) => (
                <Box
                  mt={2}
                  display={'flex'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                  key={index}
                  style={{ cursor: 'pointer' }}
                  padding={'13px'}
                  color={`${mainColor.textMain}`}
                  borderRadius={'8px'}
                  bgcolor={` ${active === item?.link ? mainColor.borderColor1 : ''}`}
                  width={'90%'}
                  onClick={() => {
                    handleClick(item?.link);
                  }}
                  sx={{ '&:hover': { background: '#333333' } }}
                >
                  <Box
                    sx={{
                      background:
                        active === item?.link
                          ? 'linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)'
                          : '#333333',
                      borderRadius: '9px',
                      width: '31px',
                      height: '31px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      '&:hover': {
                        background:
                          'linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)',
                      },
                    }}
                  >
                    {item?.icon}
                  </Box>
                  <TextCus fontSize={`${fontSize.xs}`} pl={'10px'}>
                    {item?.label}
                  </TextCus>
                </Box>
              ))}
            </Box>

            <div className='divider'></div>
            <Box paddingX={'15px'}>
              <TextCus fontSize={`${fontSize.xs}`}>
                <a style={{ textDecoration: 'none', color: `${mainColor.textD1}` }}
                  className='hover-textMain'
                  href='https://vipix.io/assets/PRIVACY-POLICY-VIPIX.pdf'
                  target='_bland'>
                  Privacy Policy
                </a>
              </TextCus>

              <TextCus fontSize={`${fontSize.xs}`} mt={1}>
                <a style={{ textDecoration: 'none', color: `${mainColor.textD1}` }}
                  className='hover-textMain'
                  href='https://vipix.io/assets/TERMS-AND-CONDITIONS-VIPIX.pdf'
                  target='_bland'>
                  Terms and Conditions
                </a>
              </TextCus>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalMenuRight;
