/** @format */

import { useMutation, useQuery } from '@tanstack/react-query';
import { userService } from 'services/userService';
import { QueryKeys } from 'utils';
import { useEffect } from 'react';
import { useUser } from './useUser';
import SnackbarUtils from 'utils/SnackbarUtils';
import { UpdateProfileForm } from 'types';

export const useProfile = () => {
  const { setUser, user } = useUser();

  const { data, refetch } = useQuery({
    queryKey: [QueryKeys.PROFILE],
    queryFn: () => userService.getProfile(),
    enabled: !user,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (form: UpdateProfileForm) => userService.updateProfile(form),
    onSuccess: async (data: any) => {
      if (data?.statusCode === 200) {
        SnackbarUtils.success('Update success');
        const rs = await userService.getProfile();
        if (rs?.data) {
          setUser(rs?.data);
          window.location.reload();
        }
        return;
      }
      SnackbarUtils.warning(data?.message || 'Update failed');
    },
    onError: () => {
      SnackbarUtils.warning('Update failed!');
    },
  });

  useEffect(() => {
    if (data) {
      setUser(data?.data);
    }
  }, [data]);

  return {
    getProfile: refetch,
    updateProfile: mutate,
    isPending,
  };
};
