/** @format */

import React, { useEffect, useState } from 'react';
import { TextCus } from 'components';
import { fontSize, mainColor } from 'theme';
import { Box, CircularProgress } from '@mui/material';
import styles from './styles';
import { dataCardVideo } from 'utils';
import { TabHome } from './TabHome';
import { CardHomeMedia } from './CardHomeMedia';
import { RecentCreations } from 'components/RecentCreations';
import { Community } from 'components/Comuniity';
import { useGenerate } from 'hooks/useGenerate';
import { TParams } from 'types';
import { IGenerateType } from 'types/generate';
import RecentEmty from 'components/RecentCreations/RecentEmty';
import { Icons } from 'assets';
import { useUser } from 'hooks';
import RecentSkeleton from 'components/RecentCreations/RecentSkeleton';

const dataTab = [
  { title: 'All', type: IGenerateType.ALL },
  { title: 'Text to video', type: IGenerateType.TEXT2VIDEO },
  { title: 'Image to video', type: IGenerateType.IMAGE2VIDEO },
];

function MainPage() {
  /*STATE*/
  const [titleCommunity, setTitleCommunity] = useState('All');
  const [typeParam, setTypeParam] = useState(IGenerateType.ALL);
  const [paramsCommunity, setParamsCommunity] = useState<TParams>({
    page: 1,
    limit: 10,
    type: typeParam,
  });

  /*HOOKS*/
  const { useGetRecent, useGetCommunity } = useGenerate();
  const { recents, refetch, isLoading } = useGetRecent({
    page: 1,
    limit: 10,
  });
  const { communities, hasNextPage, fetchNextPage, isFetchingNextPage, isLoadingComunity } = useGetCommunity(paramsCommunity);

  useEffect(() => {
    setParamsCommunity({
      ...paramsCommunity,
      type: typeParam,
    });
  }, [typeParam]);

  const { isLogged } = useUser();

  /*FUNTION Tab Community*/
  const handleClickTabCommunity = (title: string, type: any) => {
    setTitleCommunity(title);
    setTypeParam(type);
  };
  return (
    <>
      <Box
        // paddingBottom={'10%'}
        sx={styles.boxContainer}
        marginTop={'150px'}
        flexDirection={'column'}
        justifyContent={'center'}
        width={'100%'}
        alignItems={'center'}
        display={'flex'}
      >
        <TextCus color={mainColor.textMain} fontSize={`${fontSize.xxl}`} fontWeight={700}>
          High-Quality Videos, High Conversion Rates
        </TextCus>

        <Box
          mt={'120px'}
          display={'flex'}
          width={'100%'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <Box width={'100%'} display={'flex'} justifyContent={'start'}>
            {/* <TextCus
              color={mainColor.textMain}
              fontSize={`${fontSize.xl}`}
              pb={'20px'}
              ml={'15px'}
              textAlign={'left'}
            >
              Your Impressive Videos Start From
            </TextCus> */}
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            flexWrap={'wrap'}
            justifyContent={'start'}
            gap={'10px'}
          >
            {dataCardVideo.map((item, index) => (
              <CardHomeMedia
                key={index}
                title={item?.title}
                srcImg={item?.srcImg}
                srcVideo={item?.srcVideo}
                to={item?.to}
              />
            ))}
          </Box>
        </Box>
        {isLogged && (
          <Box
            mt={'170px'}
            display={'flex'}
            width={'100%'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            |
            <Box width={'100%'} display={'flex'} justifyContent={'start'} mb={'30'}>
              <TextCus
                color={mainColor.textMain}
                fontSize={`${fontSize.xl}`}
                ml={'15px'}
                textAlign={'left'}
                mb={'20px'}
              >
                Recent Creations
              </TextCus>
            </Box>
            {isLoading
              ? (<RecentSkeleton />)
              : (recents.length > 0 ? <RecentCreations refetch={refetch} dataRecents={recents} /> : <RecentEmty />)}

          </Box>
        )}

        <Box
          mt={'170px'}
          mb={'100px'}
          display={'flex'}
          width={'100%'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          |
          <Box width={'100%'} display={'flex'} justifyContent={'start'} >
            <TextCus
              color={mainColor.textMain}
              fontSize={`${fontSize.xl}`}
              ml={'15px'}
              textAlign={'left'}
            >
              Community
            </TextCus>
          </Box>
          <Box display={'flex'} width={'100%'} mt={'20px'} >
            {dataTab.map((item, index) => (
              <Box
                key={index}
                onClick={() => {
                  handleClickTabCommunity(item?.title, item?.type);
                }}
              >
                <TabHome title={item?.title} isTitle={titleCommunity} />
              </Box>
            ))}
          </Box>
          {isLoadingComunity
            ? (<RecentSkeleton />)
            : (communities ? <Community dataCommunity={communities} /> : <RecentEmty />)
          }

          {hasNextPage && (
            <Box
              marginBottom={'70px'}
              component={"div"}
              onClick={() => fetchNextPage()}
              sx={{
                cursor: 'pointer',
                borderRadius: '12px',
                '&:hover': { backgroundColor: `${mainColor.bgButtonHover}` }
              }}
              bgcolor={mainColor.hoverIcon}
              p={'8px'}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"15px"}
            >
              <TextCus color={mainColor.textMain} fontSize={fontSize.s}>{"See more"}</TextCus>
              {isFetchingNextPage ? (
                <CircularProgress size={15} />
              ) : (
                <Icons.ArrowSelect />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default MainPage;
