/** @format */

import React, { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';
import { defaultColorTheme } from 'theme/default';
import { ISXTheme, IText } from 'types';
import { areEqual } from 'utils';

import { Typography } from '@mui/material';

const TextCus: FC<IText> = ({
  useI18n = true,
  children,
  colorTheme,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation();

  if (!children) return null;
  return (
    <Typography
      sx={
        [
          props?.onClick ? { cursor: 'pointer' } : {},
          props?.sx,
          colorTheme && { color: defaultColorTheme[colorTheme] },
          {
            color: disabled ? '#ffffff' : '',
          },
        ] as ISXTheme
      }
      {...props}
    >
      <>{useI18n && typeof children === 'string' ? t(children) : children}</>
    </Typography>
  );
};

export default memo(TextCus, areEqual);
