/** @format */

import { useMutation } from '@tanstack/react-query';
import { authService, userService } from 'services';
import { FormLogin, FormLoginSocial, FormRegister } from 'types';
import { useKey } from './useKey';
import { useUser } from './useUser';
import {} from './useLayout';
import { useDevice } from './useDevice';
import SnackbarUtils from 'utils/SnackbarUtils';
import { useNavigate } from 'react-router-dom';
import { APP_ROOT, PUBLIC_URL } from 'utils/routes';

export const useAuthentication = (cb?: () => void) => {
  const { setKeySite, removeKeySite } = useKey();
  const { setUser, setIsLogged } = useUser();
  // const { setOpenModalAuth } = useLayout();
  const { deviceId, ipAddress, platForm } = useDevice();
  const navigate = useNavigate();

  const loginMutation = useMutation({
    mutationFn: (formData: FormLogin) =>
      authService.login({ ...formData, deviceId, ipAddress, platForm }),
    onSuccess: async (data: any) => {
      if (data?.statusCode === 200) {
        const { accessToken, refreshToken: refreshToken } = data.data;
        setKeySite?.({ authToken: accessToken, refreshToken: refreshToken });
        const rs = await userService.getProfile();
        if (rs?.data) {
          setUser(rs.data);
          setIsLogged(true);
          navigate(APP_ROOT.DASHBOARD);
        }
        return;
      }
      SnackbarUtils.warning(data?.message || 'Login failed!');
    },
    onError: error => {
      SnackbarUtils.warning(error?.message || 'Login failed!');
    },
  });

  const loginSocialMutation = useMutation({
    mutationFn: (formData: FormLoginSocial) => authService.loginGoogle(formData),
    onSuccess: async (data: any) => {
      if (data?.statusCode === 200) {
        console.log('login gg data', data.data);
        const { accessToken, refreshToken: refreshToken } = data.data;
        console.log('🚀 ~ onSuccess: ~ refreshToken:', refreshToken);
        console.log('🚀 ~ onSuccess: ~ accessToken:', accessToken);
        setKeySite?.({ authToken: accessToken, refreshToken: refreshToken });
        const rs = await userService.getProfile();
        if (rs?.data) {
          setUser(rs.data);
          setIsLogged(true);
          navigate(APP_ROOT.DASHBOARD);

          // setOpenModalAuth(EModelAuth.DEFAULT);
          // setUser(rs.data?.data);
          // setIsLogged(true);
        }
        return;
      }
      SnackbarUtils.warning(data?.message || 'Login failed');
    },
    onError: () => {
      SnackbarUtils.warning('Login failed!');
    },
  });

  const registerMutation = useMutation({
    mutationFn: (formData: FormRegister) => authService.register(formData),
    onSuccess: async (data: any) => {
      if (data?.statusCode === 200) {
        cb?.();
        SnackbarUtils.success('Enter OTP is sent to your email!');
        navigate(PUBLIC_URL.VERIFY_OTP);
        return;
      }
      SnackbarUtils.warning(data?.message || 'Register failed!');
    },
    onError: error => {
      SnackbarUtils.warning(error?.message || 'Register failed!');
    },
  });

  const resetStore = () => {
    setIsLogged(false);
    removeKeySite();
    localStorage.clear();
    window.location.reload();
    return;
  };

  const logoutMutation = useMutation({
    mutationFn: () => userService.logout(),
    onSuccess: async (data: any) => {
      console.log('Data logout', data);
      if (data?.statusCode === 200) {
        resetStore();
      }
    },
    onError: error => {
      console.log('🚀 ~ SignIn ~ error:', error);
      SnackbarUtils.warning('Login failed!');
    },
  });

  return { loginMutation, loginSocialMutation, registerMutation, logoutMutation };
};
