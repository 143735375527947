import React from "react";
import { Box, Fade, Modal } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LoadingGif } from 'assets';


interface IModalFail {
	open: boolean;
	// onClose: () => void;
}

const ModalLoading = ({ open }: IModalFail) => {
	// console.log("🚀 ~ ModalLoading ~ onClose:", onClose)
	return (
		<Modal
			keepMounted
			open={open}
			sx={{
				backgroundColor: "rgba(0, 0, 0, 0.6)",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box>
					<LazyLoadImage src={LoadingGif} alt='icon' width={'80px'} />
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalLoading;
