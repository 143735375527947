/** @format */

import React, { useEffect, useState } from 'react';
import { Header } from 'components';
import { mainColor } from 'theme';
import { Box } from '@mui/material';
import { bgGL, bgFeat } from 'assets';
import { HeaderSub } from 'components/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { PUBLIC_URL } from 'utils/routes';
import { useUser } from 'hooks';

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const location = useLocation();
  const navigation = useNavigate()
  const [active, setActive] = useState('/');
  const { isLogged } = useUser();

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (!isLogged && location?.pathname !== PUBLIC_URL.HOME) {
      navigation(PUBLIC_URL.LOGIN)
    }
  }, [location.pathname, isLogged]);

  return (
    <Box
      height={'auto'}
      width={'100%'}
      display='flex'
      flexDirection='column'
      component={'div'}
      justifyContent={'center'}
      alignItems={'center'}
      bgcolor={mainColor.primary}
      sx={{
        backgroundImage: `url(${location.pathname !== "/" ? bgFeat : bgGL})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Box padding={3} width={'100%'} position={'fixed'} top={'-3px'} zIndex={9999}>
        {active !== `/` ? <HeaderSub /> : <Header />}
      </Box>
      <Box
        paddingX={'10%'}
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        component={'main'}
      >
        <Box maxWidth='90%' width={'90%'}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
