import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { TextCus } from "components";
import { Icons } from "assets";
import { fontSize, mainColor } from "theme";
import { CloseOutlined } from "@mui/icons-material";

interface BoxUploadProps {
	onFileSelect?: (file: File) => void;
	onClear?: () => void;
	initialImage?: string;
	isLoading?: boolean;
	title?: string;
	error?: boolean
	subTitle?: string;
	openBrowse?: any;
	isDisabled?: any
}

const BoxUpload = ({ initialImage, onFileSelect, onClear, isLoading, title, subTitle, error, openBrowse, isDisabled }: BoxUploadProps) => {
	const [previewUrl, setPreviewUrl] = useState<string | any>('');
	const fileInputRef = useRef<HTMLInputElement | any>('');
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);
	// const [openModal, setOpenModal] = useState(false);

	useEffect(() => {
		if (initialImage) {
			typeof initialImage === "string" && setPreviewUrl(initialImage);
		}
	}, [initialImage]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (previewUrl) {
			setIsPreviewVisible(false);
			timer = setTimeout(() => {
				setIsPreviewVisible(true);
			}, 300);
		}

		return () => clearTimeout(timer);
	}, [previewUrl]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			// setOpenModal(false);
			onFileSelect?.(file);
			const url = URL.createObjectURL(file);
			setPreviewUrl(url);
			return () => {
				URL.revokeObjectURL(url);
			};
		}
	};
	const handleClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
			fileInputRef.current.click();
		}
	};
	useEffect(() => {
		if (openBrowse) {
			handleClick()
		}
	}, [openBrowse]);

	const handleClear = () => {
		setPreviewUrl('');
		onClear?.();
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	};
	useEffect(() => {
		if (previewUrl) {
			setIsPreviewVisible(true);
		} else {
			setIsPreviewVisible(false);
		}
	}, [previewUrl])

	useEffect(() => {
		if (isLoading) {
			setPreviewUrl('');
			onClear?.();
		}
	}, [isLoading]);

	return (
		<Box
			disabled={isDisabled}
			component={'button'}
			position={'relative'}
			sx={{
				".layout3": { display: 'none' },
				'&:hover': {
					".layout3": {
						display: 'hidden',
					},
					".active-rotate": {
						transform: `rotate(12deg)`,
						scale: 1.2,
						boxShadow: ` 0 4px 20px rgba(135,207,254,0.7)`,
					}
				},
				transition: "all 0.4s ease-in-out",
				background: `${!isDisabled ? 'transparent' : mainColor.hoverIcon}`,
				borderRadius: "12px",
				border: ` 0px solid !important`,
			}}
		>
			<Box
				// position={'relative'}
				className="dash_linear active-rotate"
				width={'152px'}
				height={'204px'}
				display={"flex"}
				flexDirection={"row"}
				justifyContent={"center"}
				alignItems={"center"}
				borderRadius={"12px"}
				sx={{
					border: ` 1px solid #fff`,
					transition: "all 0.4s ease-in-out",
				}}
			>
				<input
					width={'100%'}
					height={'100%'}
					type="file"
					ref={fileInputRef}
					style={{ display: "none", position: "relative" }}
					accept=".png, .jpg, .jpeg, .webp"
					onChange={handleFileChange}
				/>
				{previewUrl ? (
					<Box
						display={'flex'}
						position={'relative'}
						alignItems={'center'}
						width={'100%'}
						height={'100%'}
					>
						<Box onClick={handleClear}
							position={'absolute'}
							right={'0px'}
							top={'0px'}
							sx={{ zIndex: 99999999999999 }}
						>
							<IconButton >
								<CloseOutlined color='primary' />
							</IconButton>
						</Box>

						<img
							src={previewUrl}
							alt="Preview"
							style={{
								width: '148px',
								height: '198px',
								objectFit: "cover",
								opacity: isPreviewVisible ? 1 : 0,
								transition: "opacity 0.5s ease-in-out",
								borderRadius: "10px",
								margin: "0 auto",
							}}
						/>
					</Box>

				) : (
					<Box
						onClick={handleClick}
						sx={{ cursor: 'pointer' }}
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
						flexDirection={'column'}
					>
						{Icons.UploadImage()}
						<TextCus
							fontSize={fontSize.xs}
							fontWeight={!error ? '400' : '600'}
							color={!error ? mainColor.textGray : mainColor.error}
							pt={'10px'}
							sx={{ textAlign: 'center' }}>
							{!isDisabled ? title : ""}
						</TextCus>
						<TextCus
							fontSize={fontSize.xs}
							fontWeight={!error ? '400' : '600'}
							color={!error ? mainColor.textGray : mainColor.error}
							sx={{ textAlign: 'center' }}
						>
							{!isDisabled ? subTitle : ""}
						</TextCus>
					</Box>
				)}

			</Box>
			<Box
				position={'absolute'}
				bottom={'-50px'}
				borderRadius={'12px'}
				width={'118px'}
				height={'52px'}
				bgcolor={mainColor.textMain}
				display={'flex'}
				className="layout3"
				justifyContent={'center'}
				flexDirection={'row'}
				alignItems={'center'}
			>
				<Box
					onClick={handleClick}
					width={'102px'}
					height={'36px'}
					display={'flex'}
					alignItems={'center'}
					justifyContent={'space-around'}
					mx={'5px'}
					sx={{
						'&:hover': {
							background: mainColor.bgButtonHover,
							borderRadius: '12px',
							cursor: 'pointer'
						},
					}}
				>
					{Icons.IconUpload()}
					<TextCus
						fontSize={fontSize.sm}
						fontWeight={'500'}
						color={mainColor.textGray}
					>
						Upload
					</TextCus>
				</Box>

			</Box>
		</Box>
	);
};

export default BoxUpload;


