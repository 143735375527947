/** @format */

import { ButtonCus, ModalSubmitInfo, TextCus } from 'components';
import React, { useState } from 'react';
import { Box } from '@mui/material';
import { fontSize, mainColor } from 'theme';
import { Icons } from 'assets';
import { useNavigate } from 'react-router-dom';
import { APP_ROOT } from 'utils/routes';
import { EPricingPlan, ResponsePlan } from 'types/payment';

const BoxPlan = ({
  isFree,
  item,
  typePlan,
  isEnterprise,
  isCurrentPlan,
}: {
  isFree: boolean;
  item: ResponsePlan;
  typePlan: {
    value: number;
    name: EPricingPlan;
  };
  isEnterprise: boolean;
  isCurrentPlan: boolean;
}) => {
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);

  console.log('showModel', showModel);
  return (
    <Box
      maxWidth={'302px'}
      minWidth={'302px'}
      height={'100%'}
      bgcolor={mainColor.textMain}
      display={'flex'}
      flexDirection={'column'}
      padding={'15px'}
      sx={{ border: '1px solid #B2B2B2' }}
      justifyContent={'space-between'}
      borderRadius={'12px'}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
          <TextCus color={mainColor.primary} fontSize={fontSize.md} fontWeight={'700'}>
            {item.packageName}
          </TextCus>
          <TextCus color={'#6F6C90'} fontWeight={'400'} fontSize={'12px'}>
            {item.subTitle}
          </TextCus>
        </Box>
        {isEnterprise ? (
          <TextCus
            color={mainColor.primary}
            sx={{
              fontSize: {
                xs: '18px',
                xl: '30px',
              },
            }}
            fontWeight={'700'}
          >
            Custom Pricing
          </TextCus>
        ) : (
          <Box display={'flex'} alignItems={'flex-end'}>
            <TextCus
              color={mainColor.primary}
              sx={{
                fontSize: {
                  xs: '18px',
                  xl: '30px',
                },
              }}
              fontWeight={'700'}
            >
              {'$' +
                Math.round(item.priceSale * typePlan.value * (typePlan.value === 1 ? 1 : 0.85))}
            </TextCus>
            <TextCus color={'#6F6C90'} fontWeight={'600'} fontSize={'14px'}>
              {typePlan.value === 1 ? '/month' : '/year'}
            </TextCus>
          </Box>
        )}

        <Box>
          <TextCus
            color={mainColor.primary}
            sx={{
              fontSize: {
                xs: '14px',
              },
            }}
            fontWeight={'600'}
          >
            What’s included
          </TextCus>
          <Box component={'ul'} sx={{ listStyleType: 'none' }} paddingLeft={'15px'}>
            {item.description.map((item, index) => {
              return (
                <Box
                  component={'li'}
                  display={'flex'}
                  mt={'5px'}
                  alignItems={'center'}
                  gap={'15px'}
                  color={'#002F74'}
                  fontSize={'12px'}
                  fontWeight={'400'}
                  key={index}
                >
                  {Icons.Tick()}
                  {item}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <ButtonCus
        title={
          isFree
            ? 'Get Started'
            : isEnterprise
            ? 'Contact Us'
            : isCurrentPlan
            ? 'Current Plan'
            : 'Upgrade Plan'
        }
        onClick={() => {
          if (isEnterprise) return setShowModel(true);
          navigate(
            isFree
              ? APP_ROOT.DASHBOARD
              : `${APP_ROOT.INFO_PAYMENT}?id=${item._id}&quantity=${typePlan.value}`,
          );
        }}
        sx={{
          mt: '15px',
          height: '41px',
          lineHeight: '40px',
          fontWeight: 600,
          fontSize: fontSize.sm,
        }}
      />

      {isEnterprise && <ModalSubmitInfo open={showModel} onClose={() => setShowModel(false)} />}
    </Box>
  );
};

export default BoxPlan;
