/** @format */

import { Box } from '@mui/material';
// import { ButtonCus } from 'components/ButtonCus';
import { TextCus } from 'components/TextCus';
import React from 'react';
// import { mainColor } from 'theme';
import styles from './styles';
import { InputCus } from 'components/InputCus';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormRegister } from 'types';
import { registerSchema } from 'utils';
import { useAuthentication } from 'hooks';
import { LoadingButton } from '@mui/lab';

const Register = () => {
  // const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormRegister>({
    resolver: yupResolver(registerSchema),
    mode: 'onChange',
  });
  const { registerMutation } = useAuthentication(() => reset());

  // const navigateSignIn = useCallback(() => {
  //   setOpenModalAuth(EModelAuth.LOGIN);
  // }, []);

  const handleClick = (data: FormRegister) => {
    // console.log('data', data);
    registerMutation.mutate(data);
  };

  return (
    <Box sx={styles.boxMain} width={'100%'}>
      <Box mt={2} flex={0.5} component='form' onSubmit={handleSubmit(handleClick)}>
        <Box mt={1}>
          <TextCus mt={1} component={'h4'} variant='h6'>
            Register with email
          </TextCus>
        </Box>

        <Box mt={1}>
          <Controller
            name='userName'
            control={control}
            render={({ field }) => (
              <InputCus
                type='text'
                {...field}
                label={
                  <TextCus useI18n fontSize={14}>
                    Username
                  </TextCus>
                }
                sx={styles.input}
                fullWidth
                // variant='standard'
                error={!!errors.userName}
              />
            )}
          />
        </Box>

        <Box mt={1}>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <InputCus
                type='email'
                {...field}
                label={
                  <TextCus useI18n fontSize={14}>
                    Email
                  </TextCus>
                }
                fullWidth
                sx={styles.input}
                autoFocus
                // variant='standard'
                error={!!errors.email}
              />
            )}
          />
        </Box>

        <Controller
          name='password'
          control={control}
          render={({ field }) => (
            <InputCus
              {...field}
              type={'password'}
              label={
                <TextCus useI18n fontSize={14}>
                  Password
                </TextCus>
              }
              sx={styles.input}
              fullWidth
              // variant='standard'
              error={!!errors.password}
            />
          )}
        />

        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          color='inherit'
          loading={registerMutation.isPending}
          // disabled={registerMutation.isPending}
          sx={{ mt: 3, ...styles.min230 }}
          onClick={() => {
            console.log('kkk');
          }}
        >
          <TextCus variant='body2' sx={{ color: 'text.secondary' }}>
            register
          </TextCus>
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default Register;
