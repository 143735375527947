/** @format */

import { EPricingPlan } from 'types/payment';
import {
  Icons,
  homeFrame1,
  homeFrame2,
  homeFrame3,
  homeFrame31,
  sunlight,
  street_light,
  street_city,
  day_light,
  warm_day_light,
  luxury_store,
  show_room_light,
  beach,
  clear_img_1,
  clear_img_2,
  clear_img_3,
} from 'assets';
import { APP_ROOT } from './routes';

export const drawerWidth = 240;

export const dataClearImg = [
  {
    id: '1q',
    title: 'Clean Background',
    subTitle: '(White is preferred)',
    srcImg: clear_img_1,
  },
  {
    id: '2e',
    title: 'Input image is the same dimension as the output',
    subTitle: '',
    srcImg: clear_img_2,
  },
  {
    id: '1r',
    title: 'Subject input is the same size as subject output',
    subTitle: '',
    srcImg: clear_img_3,
  },
];

export const dataLighting = [
  {
    id: '132',
    title: 'Showroom light',
    srcImg: show_room_light,
  },
  {
    id: '987',
    title: 'Sunlight',
    srcImg: sunlight,
  },
  {
    id: '342',
    title: 'Street light',
    srcImg: street_light,
  },
  {
    id: '34a',
    title: 'Street city',
    srcImg: street_city,
  },
  {
    id: '3f2',
    title: 'Beach',
    srcImg: beach,
  },
  {
    id: '394',
    title: 'Day light',
    srcImg: day_light,
  },
  {
    id: 'yui',
    title: 'Warm day light',
    srcImg: warm_day_light,
  },
  {
    id: '19w',
    title: 'Luxury store',
    srcImg: luxury_store,
  },
];

export const arrChangeBG = [
  { name: 'Resolution', value: 'SHA' },
  { name: 'Lution', value: 'SH1' },
  { name: 'Nam', value: 'SH2' },
  { name: 'Toan', value: 'S3H' },
];

export const dimension = {
  width: window.innerWidth,
  height: window.innerHeight,
};

export const KEY_CONTEXT = {
  AUTH_TOKEN: 'authToken',
  REFRESH_TOKEN: 'refreshToken',
  LANGUAGE: 'language',
  THEME_MODE: 'themeMode',
  USER: 'user',
  SC_CONNECTION_TOKEN: 'connectionToken',
  MODAL_REQUIRE: 'modalRequire',
  ID_TRACKING_IMAGE: 'idTrackingImage',
  ID_TRACKING_TEXT: 'idTrackingText',
  ID_TRACKING_CHANGING_B: 'idTrackingChangingB',
  ID_TRACKING_GENERATE_B: 'idTrackingGenerateB',
  IS_VALUES_CHECKED: 'isValidationChecked',
};

export const EmpLangSeclect = [
  {
    key: 'EN',
    title: 'English',
    value: 'EN',
    useI18n: true,
  },
  {
    key: 'VN',
    title: 'Vietnamese',
    value: 'VN',
    useI18n: true,
  },
];

export const defaultPagination = {
  total: 0,
  page: 0,
  pageSize: 25,
};

export enum EnumActionApproval {
  comment = 'comment',
  approve = 'approve',
  return = 'return',
  reject = 'reject',
  publish = 'publish',
  archive = 'archive',
  invite = 'invite',
  delegate = 'delegate',
  attach = 'attach',
  print = 'print',
  docversion = 'docversion',
  history = 'history',
  showmore = 'showmore',
  lookup = 'lookup',
}

export enum EnumBtnBottomModal {
  cancel = 'cancel',
  approve = 'approve',
  return = 'return',
  reject = 'reject',
  send = 'send',
  save = 'save',
  save_and_new = 'save_and_new',
  ok = 'ok',
  close = 'close',
  /* Advance Search  */
  reset = 'reset',
  save_as_profile = 'save_as_profile',
  search = 'search',
  downloadtemp = 'downloadtemp',
  add = 'add',
  update = 'update',
  insert_update = 'insert_update',
}

export enum EnumInputVariant {
  base = 'base',
  tool = 'tool',
  browser = 'browser',
  number = 'number',
  select = 'select',
  textarea = 'textarea',
}

export enum EnumValueDataType {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  Object = 'Object',
  Array = 'Array',
  Null = 'Null',
  Undefined = 'Undefined',
  Function = 'Function',
}

export enum EnumRangeApply {
  A = 'A',
  S = 'S',
}

export enum EnumYesNoBPMN {
  Y = 'Yes',
  N = 'No',
}

export enum EnumYesNo {
  Y = 'Y',
  N = 'N',
}

export enum EnumActionFilterBars {
  import = 'import',
  export = 'export',
  refresh = 'refresh',
}

export enum EnumPrompt {
  DEFAULT_PROMPT = 'DEFAULT_PROMPT',
}

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80 - 16,
};

export const NAV = {
  WIDTH: 280,
};

export const QueryKeys = {
  PROFILE: 'profile',
  GET_RECENT: 'get_recent',
  GET_PROMPT: 'get_prompt',
  GET_COMMUNITY: 'get_community',
  GET_TRACKING: 'get_tracking',
  GET_PLAN: 'getPlan',
  GET_DETAIL_PLAN: 'get_detail_plan',
  TRANSACTION: 'transaction',
  MAINTAIN: 'maintain',
};
export const DATA_BUTTON = [
  { value: 1, name: EPricingPlan.MONTHLY },
  { value: 12, name: EPricingPlan.ANNUAL },
];

export const initialOptionsPaypal = {
  clientId: `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
  currency: 'USD',
  intent: 'capture',
};

export const dataCardVideo = [
  {
    title: 'Text to Video',
    srcImg: homeFrame1,
    srcVideo: 'https://vipix.io/assets/video/typeC/typeC-Media9.mp4',
    to: APP_ROOT.TEXT_2_VIDEO,
  },
  {
    title: 'Image to Video',
    srcImg: homeFrame2,
    srcVideo: 'https://vipix.io/assets/video/typeC/typeC-Perfume-Nature.mp4',
    to: APP_ROOT.IMAGE_2_VIDEO,
  },
  {
    title: 'Background Change',
    srcImg: homeFrame3,
    srcVideo: homeFrame31,
    to: APP_ROOT.CHANGE_BACKGROUND,
  },
];

export const dataPlans = [
  {
    id: '13ssss2456sadasdas',
    title: 'premium',
    labelBtn: 'Contact Us',
    description: [
      {
        title: 'Product & Background Motion',
        content: '',
        icon: Icons.IconChecked(),
      },
      {
        title: 'Camera Angles & Animation :',
        content: 'Complex',
        icon: Icons.IconChecked(),
      },
      {
        title: 'Resolution :',
        content: '2K - 4k',
        icon: Icons.IconChecked(),
      },
      {
        title: 'Effect :',
        content: 'Various as Request',
        icon: Icons.IconChecked(),
      },
      {
        title: '3D Model :',
        content: 'Required',
        icon: Icons.IconChecked(),
      },
      {
        title: 'Image :',
        content: 'No need',
        icon: Icons.IconUnChecked(),
      },
    ],
  },
  {
    id: '1asfasfga32456sadasdas',
    title: 'plus',
    labelBtn: 'Contact Us',
    description: [
      {
        title: 'Product & Background Motion',
        content: '',
        icon: Icons.IconChecked(),
      },
      {
        title: 'Camera Angles & Animation :',
        content: 'Medium',
        icon: Icons.IconChecked(),
      },
      {
        title: 'Resolution :',
        content: 'Full HD - 2K',
        icon: Icons.IconChecked(),
      },
      {
        title: 'Effect :',
        content: 'Various as Request',
        icon: Icons.IconChecked(),
      },
      {
        title: '3D Model :',
        content: 'Required',
        icon: Icons.IconChecked(),
      },
      {
        title: 'Image :',
        content: 'No need',
        icon: Icons.IconUnChecked(),
      },
    ],
  },
  {
    id: '73sfasfga32456sadasdas',
    title: 'standard',
    labelBtn: 'Upgrade',
    description: [
      {
        title: 'Only Backgroud',
        content: '',
        icon: Icons.IconChecked(),
      },
      {
        title: 'Camera Angles & Animation :',
        content: 'Simple',
        icon: Icons.IconChecked(),
      },
      {
        title: 'Resolution :',
        content: 'Up to Full HD',
        icon: Icons.IconChecked(),
      },
      {
        title: 'Effect :',
        content: 'Preset',
        icon: Icons.IconChecked(),
      },
      {
        title: '3D Model :',
        content: 'No need',
        icon: Icons.IconUnChecked(),
      },
      {
        title: 'Image :',
        content: 'Required',
        icon: Icons.IconChecked(),
      },
    ],
  },
];
