/** @format */

import { IUser } from 'types';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface UserState {
  user: IUser | null;
  setUser: (user: IUser) => void;
  setLanguages: (lang: { value: string; name: string }) => void;

  clearUser: () => void;
  languages: {
    value: string;
    name: string;
  };
  uid: string;
  setNewPassViaUid: (u: string) => void;
  isLogged: boolean;
  setIsLogged: (is: boolean) => void;
  emailRegister?: string;
  setEmailRegister: (email: string) => void;
  resetUserStored: () => void;
}

const initialState = {
  user: null,
  languages: {
    value: 'en',
    name: 'English',
  },
  uid: '',
  isLogged: false,
  emailRegister: '',
};

export const useUser = create<UserState>()(
  persist(
    set => ({
      user: null,
      uid: '',
      languages: {
        value: 'en',
        name: 'English',
      },
      setUser: user => {
        console.log('setUser', user);
        set({ user });
      },
      clearUser: () => set({ user: null }),
      setLanguages: (language: { value: string; name: string }) =>
        set({
          languages: {
            value: language?.value,
            name: language?.name,
          },
        }),
      setNewPassViaUid: u =>
        set({
          uid: u,
        }),
      isLogged: false,
      setIsLogged: (isLogged: boolean) => set({ isLogged: isLogged }),
      emailRegister: '',
      setEmailRegister: email => set({ emailRegister: email }),
      resetUserStored: () => set(initialState),
    }),
    {
      name: 'user-storage',
      storage: createJSONStorage(() => localStorage),
      partialize: state => ({
        user: state.user,
        languages: state.languages,
        isLogged: state.isLogged,
      }),
    },
  ),
);
