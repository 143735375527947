/** @format */

import React, { useEffect, useRef, useState } from 'react';
import { ButtonCus, InputCus, TextCus } from 'components';
import { fontSize, mainColor } from 'theme';
import { Box, CircularProgress } from '@mui/material';
import styles from './styles';
import { BoxLayout } from 'components/Layouts';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { iconGenerate } from 'assets';
import { TabHome } from 'pages/main/TabHome';
import { EnumInputVariant, KEY_CONTEXT } from 'utils';
import { useGenerate } from 'hooks/useGenerate';
import { ETaskTracking, TParams } from 'types';
import RecentEmty from 'components/RecentCreations/RecentEmty';
import { IChangeBackgroundParams, IGenerateType } from 'types/generate';
import { Icons } from 'assets';
import { useKey, useLayout, useProfile } from 'hooks';
import BoxUpload from './BoxUpload';
import { RecentCreations } from 'components/RecentCreations';

import ModalProcessFail from 'components/ModalGlobal/ModalProcessFail';
import ModalNotBalance from 'components/ModalGlobal/ModalNotBalance';
import ModalLoading from 'components/ModalGlobal/ModalLoading';
import RecentSkeleton from 'components/RecentCreations/RecentSkeleton';
import ModalSelectLighting from 'components/ModalGlobal/ModalSelectLighting';
import ModalPhotoRequirement from 'components/ModalGlobal/ModalPhotoRequirement';
// import BoxCreateUpdate from './BoxCreateUpdate';

const dataTab = [{ title: 'Recent', type: IGenerateType.CHANGE_BACKGROUND }, { title: 'History', type: "ALL" }]
const formInterface = { prompt: "" }

function ChangingBackground() {
  /*STATE*/
  const [formData, setFormData] = useState(formInterface);
  const [errors, setErrors] = useState({});
  const [errorImg, setErrorImg] = useState(false);
  const [errorImgBg, setErrorImgBg] = useState(false);
  const [validateImage, setValidateImage] = useState('Subject photo');
  const [validateImageBg, setValidateImageBg] = useState('Background photo');
  const [isTitle, setIsTitle] = useState('Recent')
  const [typeParam, setTypeParam] = useState<string>(IGenerateType.CHANGE_BACKGROUND)
  const [imageStart, setImageStart] = useState<any>(null)
  const [imageEnd, setImageEnd] = useState<any>(null)
  const [valueInput, setValueInput] = useState<any>(null)
  const [paramsGenerate, setParamsGenerate] = useState<TParams>({
    page: 1,
    limit: 10,
    type: typeParam,
  });
  const [openModalFail, setOpenModalFail] = useState(false);
  const [openModalNotCredit, setOpenModalNotCredit] = useState(false);
  const [isLoadingGlobal, setisLoadingGlobal] = useState(false);
  const [openBrowse, setOpenBrowse] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [valueLighting, setValueLighting] = useState<any>('')
  const [isDisabledPromt, setIsDisabledPromt] = useState<any>(false)
  const [isDisabledImgBground, setIsDisabledImgBground] = useState<any>(false)
  const [openModalPhotoRequirement, setOpenModalPhotoRequirement] = useState(false);

  ModalPhotoRequirement
  /*HOOKS*/
  const { useGetRecent, ChangeBackgroundMutation, trackingAIMutation, useGetPrompt, } = useGenerate();
  const { recents, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useGetRecent(paramsGenerate)
  const { prompts } = useGetPrompt({
    page: 1,
    limit: 6,
  })
  const { idTrackingChangingB, setIdTrackingChangingB, loadingChangingB, setLoadingChangingB,
    currentBGround, setCurrentBGround, setPromtInputGlobal } = useLayout();
  const intervalRef = useRef<any>('')
  const { getProfile } = useProfile();
  const { setKey, getKey, removeKey } = useKey();

  /*CHECK ID TRACKING*/
  const idTracking = getKey(KEY_CONTEXT.ID_TRACKING_CHANGING_B)
  const isChecked = getKey(KEY_CONTEXT.IS_VALUES_CHECKED)
  useEffect(() => {
    if (idTracking && recents[0]?.trackId !== idTracking) {
      setIdTrackingChangingB(idTracking)
      setLoadingChangingB(true)
    }
    if (isChecked === 'true') {
      setOpenBrowse(true)
    }
  }, []);

  useEffect(() => {
    setParamsGenerate({
      ...paramsGenerate,
      type: typeParam,
    });
  }, [typeParam]);

  useEffect(() => {
    if (imageEnd) {
      setIsDisabledPromt(true)
    } else { setIsDisabledPromt(false) }

    if (formData?.prompt) {
      setIsDisabledImgBground(true)
    } else { setIsDisabledImgBground(false) }
  }, [imageEnd, formData?.prompt]);


  /*FUNTION TAB*/
  const handleClickTab = (title: string, type: string) => {
    setIsTitle(title)
    setTypeParam(type)
  }
  /*FUNTION Click Prompt*/
  const handleClickPrompt = (prompt: string) => {
    setValueInput(prompt)
    setFormData({ prompt: prompt })
    setTimeout(() => {
      setOpenBrowse(false)
      setPromtInputGlobal('')
    }, 3000)
  }
  /*FUNTION HANDLE SUBMIT*/
  const handleSubmit = async () => {
    try {
      if (!imageStart) {
        setValidateImage('*Please select image')
        setErrorImg(true)
        return
      } else {
        setValidateImage('Subject photo')
        setErrorImg(false)
      }
      // validate Image End
      if (!imageEnd) {
        if (!isDisabledImgBground) {
          setValidateImageBg('*Please select background image')
          setErrorImgBg(true)
          return
        }
      } else {
        setValidateImageBg('Background photo')
        setErrorImgBg(false)
      }
      const formDataChangingBackground = new FormData();
      formDataChangingBackground.append('fileObject', imageStart as File)
      formDataChangingBackground.append('fileBackground', imageEnd as File)
      formDataChangingBackground.append('backgroundId', currentBGround?.id ? currentBGround?.id : '');
      formDataChangingBackground.append('promptCreateBG', formData?.prompt ? formData?.prompt : '');
      formDataChangingBackground.append('promptLighting', valueLighting ? valueLighting : '');

      setisLoadingGlobal(true)
      ChangeBackgroundMutation.mutate(formDataChangingBackground as IChangeBackgroundParams, {
        onSuccess: (data: any) => {
          if (data?.statusCode === 400) {
            if (data?.message === "BALANCE_NOT_ENOUGH") {
              setOpenModalNotCredit(true);
            } else {
              setOpenModalFail(true);
            }
          } else {
            setIdTrackingChangingB(data?.data?.id)
            setKey(KEY_CONTEXT.ID_TRACKING_CHANGING_B, data?.data?.id)
            setLoadingChangingB(true)
            setisLoadingGlobal(false)
            getProfile();
          }
        }
      })
      setFormData(formInterface)
      setValueInput('')
      setIsDisabledPromt(false)
      setIsDisabledImgBground(false)
      setValidateImage('Subject photo')
      setValidateImageBg('Background photo')
      setCurrentBGround({})
      handleEvenScroll()
      return

    } catch (error: any) {
      const validationErrors = {};
      if (error?.inner) {
        error?.inner.forEach((err: any) => {
          validationErrors[err?.path] = err.message;
        });
      }
      setErrors(validationErrors);
    }
  }
  /*HANDLE TRACKING*/
  useEffect(() => {
    if (intervalRef.current) {
      return () => {
        clearInterval(intervalRef.current);
        intervalRef.current = null
      }
    }
    if (idTrackingChangingB && loadingChangingB) {
      intervalRef.current = setInterval(() => {
        trackingAIMutation.mutate(idTrackingChangingB, {
          onSuccess: (data) => {
            const state = data?.data?.state
            if (state === ETaskTracking.COMPLETED || state === ETaskTracking.FAILED) {
              console.log('Task==================clear',)
              setLoadingChangingB(false)
              setIdTrackingChangingB('')
              removeKey(KEY_CONTEXT.ID_TRACKING_CHANGING_B)
              refetch()
              return () => { clearInterval(intervalRef.current) }
            }
          }
        })
      }, 7000)
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null
      }
    }
  }, [idTrackingChangingB, loadingChangingB])

  /*HANDLE Event Scroll*/
  const handleEvenScroll = () => {
    setTimeout(() => {
      const targetElement = document.getElementById("tab-id");
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 1000)
  };
  // =========== OPEN MODAL INFO =============
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box
        sx={styles.boxContainer}
        marginTop={'120px'}
        flexDirection={'column'}
        justifyContent={'center'}
        width={'100%'}
        alignItems={'center'}
      >
        <Box
          marginY={'50px'}
          p={'25px'}
          // width={'70%'}
          height={'auto'}
          display='flex'
          flexDirection='row'
          alignItems={'center'}
          component={'div'}
          bgcolor={mainColor.primary}
          sx={{
            border: `2px dashed ${mainColor.borderColor3}`,
            borderRadius: '12px',
            boxShadow: ` 0 4px 20px rgba(135,207,254,0.7)`,
          }}
        >
          <Box position={'relative'} display={'flex'}>
            {!openBrowse && (<Box
              // display={'none'}
              position={'absolute'}
              onClick={() => { setOpenModalPhotoRequirement(true) }}
              width={'152px'}
              height={'204px'}
              sx={{ zIndex: 999, top: '0px', left: '5px', cursor: 'pointer' }}
            >
            </Box>)}

            <BoxUpload
              openBrowse={openBrowse}
              onFileSelect={(file: File) => {
                setImageStart(file);
              }}
              onClear={() => setImageStart(null)}
              isLoading={loadingChangingB}
              title={validateImage}
              error={errorImg}
              subTitle={`(Obligatory)`}
            />

          </Box>


          <TextCus
            color={mainColor.textMain}
            fontSize={`${fontSize.xl}`}
            px={'45px'}
          >
            +
          </TextCus>

          <BoxUpload
            onFileSelect={(file: File) => {
              setImageEnd(file);
            }}
            onClear={() => setImageEnd(null)}
            isLoading={loadingChangingB}
            title={validateImageBg}
            subTitle={`(Optional)`}
            error={errorImgBg}
            isDisabled={isDisabledImgBground}
          />
        </Box>

        <BoxLayout>
          <Box>
            <InputCus
              name="prompt"
              inputVariant={EnumInputVariant.textarea}
              placeholder="Prompt Your Background Photo (Optional)"
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
              value={valueInput}
              setValueInput={setValueInput}
              isDisabled={isDisabledPromt}
            />

          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            marginY={'15px'}>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              bgcolor={`${mainColor.textD1}`}
              p={'5px'}
              borderRadius={'10px'}
              sx={{ cursor: 'pointer', border: ` 0px solid !important` }}
              component={'button'}
              onClick={handleClick}
            >
              <Box
                px={'10px'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}>
                {Icons.IconLighting()}
              </Box>

              <TextCus
                color={mainColor.textMain}
                fontSize={`${fontSize.sm}`}
                paddingX={1}
              >
                Lighting Config (Optional)
              </TextCus>

              <Box
                px={'10px'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}>
                {anchorEl ? Icons.IconArowDowLighting() : Icons.IconArowUpLighting()}
              </Box>

            </Box>
            <ButtonCus
              disabled={loadingChangingB}
              onClick={handleSubmit}
              sx={{
                padding: "0px !important",
                marginBottom: `5px`,
                borderRadius: '12px',
                width: '157px',
                height: '44px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: "linear-gradient(96deg, rgba(245,178,245,1) 0%, rgba(0,151,245,1) 100%)"
              }}
            >
              {Icons.StarBtn()}
              <TextCus
                color={mainColor.textMain}
                fontSize={`${fontSize.sm}`}
                paddingX={1}
              >
                Generate
              </TextCus>
            </ButtonCus>
          </Box>
        </BoxLayout>

        <Box
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'center'}
          width={'100%'}
          minHeight={`${prompts?.length ? '200px' : '100px'}`}
        >
          {prompts?.length && prompts.map((item, index) => (
            <Box
              onClick={() => { handleClickPrompt(String(item?.prompt)) }}
              key={index}
              marginX={'7px'}
              marginY={'14px'}
              display={'flex'}
              justifyContent={'center'}
              alignContent={'center'}
              alignItems={'center'}
              textAlign={'left'}
              sx={{
                width: '389px',
                height: '74px',
                borderRadius: '12px',
                backgroundColor: `${mainColor.textD2}`,
                '&:hover': { background: `${mainColor.textD1} `, cursor: 'pointer', border: '1px solid white' },
              }} >
              <TextCus
                width={'100%'}
                className='multiline-ellipsis'
                px={'10px'}
                color={mainColor.textMain}
                fontSize={`${fontSize.xs}`}
              >
                {item?.prompt}
              </TextCus>
            </Box>
          ))}
        </Box>

        <Box
          mt={'70px'}
          display={'flex'}
          width={'100%'}
          minHeight={'510px'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'start'}
          >
            <TextCus
              color={mainColor.textMain}
              fontSize={`${fontSize.xl}`}
              ml={'15px'}
              textAlign={'left'}
            >
              Your Gallery Images
            </TextCus>
          </Box>

          <Box
            display={'flex'}
            width={'100%'}
            mt={'20px'}
          >
            {dataTab.map((item, index) => (
              <Box id="tab-id" key={index} onClick={() => { handleClickTab(item?.title, item?.type) }}>
                <TabHome title={item?.title} isTitle={isTitle} />
              </Box>
            ))}
          </Box>

          {isLoading ? (<RecentSkeleton />)
            : ((recents.length > 0 || loadingChangingB)
              ? (<RecentCreations dataRecents={recents} isLoading={loadingChangingB} refetch={refetch} />)
              : (<RecentEmty />))
          }
          {hasNextPage && (
            <Box
              marginBottom={'70px'}
              component={"div"}
              onClick={() => fetchNextPage()}
              sx={{
                cursor: 'pointer',
                borderRadius: '12px',
                '&:hover': { backgroundColor: `${mainColor.bgButtonHover}` }
              }}
              bgcolor={mainColor.hoverIcon}
              mt={'25px'}
              p={'8px'}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"15px"}
            >
              <TextCus color={mainColor.textMain} fontSize={fontSize.s}>{"See more"}</TextCus>
              {isFetchingNextPage ? (
                <CircularProgress size={15} />
              ) : (
                <Icons.ArrowSelect />
              )}
            </Box>
          )}
        </Box>
        <ModalSelectLighting
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          valueLighting={valueLighting}
          setValueLighting={setValueLighting}
        />
        {openModalPhotoRequirement &&
          (<ModalPhotoRequirement
            open={openModalPhotoRequirement}
            onClose={() => setOpenModalPhotoRequirement(false)}
            setOpenBrowse={setOpenBrowse}
          />)
        }

        {openModalFail && (
          <ModalProcessFail
            open={openModalFail}
            onClose={() => setOpenModalFail(false)}
          />
        )}
        {openModalNotCredit && (
          <ModalNotBalance
            open={openModalNotCredit}
            onClose={() => setOpenModalNotCredit(false)}
          />
        )}
        {isLoadingGlobal && <ModalLoading open={true} />}

      </Box >
    </>
  );
}

export default ChangingBackground;

{/* <Box px={'10px'} mb={'5px'}>
              <SelectCus
                width="148px"
                height="44px !important"
                onChange={handleChange}
                options={arrChangeBG}
                value={valueSelect}
              />
            </Box> */}